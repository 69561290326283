import { useModalForm } from "@refinedev/antd";
import { CanAccess, useNavigation, useTranslate } from "@refinedev/core";
import { Form, Input, Modal, Typography } from "antd";
import React from "react";
import CategoryAutoselect from "../../../components/shop/CategoryAutoselect";
import { useUser } from "../../../contexts/ContextProvider";
import { usePipelineContext } from "../../../contexts/PipelineProvider";
type Props = {
  disableRedirect?: boolean;
  onSuccess?: (data: any) => void;
  changeView?: () => void;
};
export default function CreateProductItem({
  disableRedirect,
  changeView,
}: Props) {
  const { canAccess } = usePipelineContext();

  const [, setProductCategories] = React.useState<number[]>([]);
  const translate = useTranslate();
  const { currentTeam } = useUser();

  const { list } = useNavigation();

  const { modalProps, formProps, close, open } = useModalForm<any>({
    resource: "product_items",
    action: "create",
    redirect: disableRedirect ? false : "show",
    defaultVisible: true,
    successNotification: false,

    warnWhenUnsavedChanges: !disableRedirect,
    invalidates: ["all"],
  });

  const handleFinish = async (values: any) => {
    try {
      formProps?.onFinish?.({
        ...values,
        type: "item",
        account: currentTeam?.account_id,
      });
    } catch (error) {
      //
    }
  };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <Modal
        {...modalProps}
        open={open}
        centered
        title={translate("product_items.create.add_product_item")}
        width={760}
        onCancel={() => {
          if (!disableRedirect) {
            list("product_items", "replace");
          } else {
            close();
            changeView?.();
          }
        }}
        okText={translate("product_items.buttons.add_product")}
      >
        <Form
          {...formProps}
          onFinish={handleFinish}
          layout="vertical"
          initialValues={{ type: "item" }}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter the title" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label={translate("subscription_products.fields.description")}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          {canAccess?.personal_modules?.shop?.ecommerce && (
            <CategoryAutoselect
              multiple
              name="product_categories"
              onSelect={(items) => setProductCategories(items)}
            />
          )}

          <Typography.Text type={"secondary"}>
            {translate("product_items.create.info")}
          </Typography.Text>
        </Form>
      </Modal>
    </CanAccess>
  );
}
