export const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
export const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
// export function isValidIBAN(iban) {
//   iban = iban.replace(/\s+/g, "").toUpperCase();

//   if (iban.length < 15 || iban.length > 34) return false;

//   const rearranged = iban.slice(4) + iban.slice(0, 4);

//   const converted = rearranged
//     .split("")
//     .map((char) => (isNaN(char) ? char.charCodeAt(0) - 55 : char))
//     .join("");

//   let remainder = "";
//   for (let i = 0; i < converted.length; i += 7) {
//     remainder = String(
//       parseInt(remainder + converted.slice(i, i + 7), 10) % 97
//     );
//   }

//   return remainder === "1";
// }

const ibanFormats = {
  AL: { length: 28, regex: /^[A-Z]{2}[0-9]{2}[0-9A-Z]{24}$/ }, // Albania
  AD: { length: 24, regex: /^[A-Z]{2}[0-9]{2}[0-9]{4}[0-9]{4}[0-9A-Z]{12}$/ }, // Andorra
  AT: { length: 20, regex: /^[A-Z]{2}[0-9]{2}[0-9]{16}$/ }, // Austria
  BE: { length: 16, regex: /^[A-Z]{2}[0-9]{2}[0-9]{12}$/ }, // Belgium
  BG: { length: 22, regex: /^[A-Z]{2}[0-9]{2}[A-Z]{4}[0-9]{4}[0-9A-Z]{10}$/ }, // Bulgaria
  CH: { length: 21, regex: /^[A-Z]{2}[0-9]{2}[0-9]{5}[0-9A-Z]{12}$/ }, // Switzerland
  DE: { length: 22, regex: /^[A-Z]{2}[0-9]{2}[0-9]{18}$/ }, // Germany
  DK: { length: 18, regex: /^[A-Z]{2}[0-9]{2}[0-9]{14}$/ }, // Denmark
  ES: { length: 24, regex: /^[A-Z]{2}[0-9]{2}[0-9]{20}$/ }, // Spain
  FI: { length: 18, regex: /^[A-Z]{2}[0-9]{2}[0-9]{14}$/ }, // Finland
  FR: { length: 27, regex: /^[A-Z]{2}[0-9]{2}[0-9]{10}[0-9A-Z]{11}[0-9]{2}$/ }, // France
  GB: { length: 22, regex: /^[A-Z]{2}[0-9]{2}[A-Z]{4}[0-9]{14}$/ }, // United Kingdom
  GR: { length: 27, regex: /^[A-Z]{2}[0-9]{2}[0-9]{3}[0-9]{4}[0-9A-Z]{16}$/ }, // Greece
  IE: { length: 22, regex: /^[A-Z]{2}[0-9]{2}[A-Z]{4}[0-9]{14}$/ }, // Ireland
  IT: { length: 27, regex: /^[A-Z]{2}[0-9]{2}[A-Z]{1}[0-9]{10}[0-9A-Z]{12}$/ }, // Italy
  LU: { length: 20, regex: /^[A-Z]{2}[0-9]{2}[0-9]{3}[0-9A-Z]{13}$/ }, // Luxembourg
  NL: { length: 18, regex: /^[A-Z]{2}[0-9]{2}[A-Z]{4}[0-9]{10}$/ }, // Netherlands
  NO: { length: 15, regex: /^[A-Z]{2}[0-9]{2}[0-9]{11}$/ }, // Norway
  PL: { length: 28, regex: /^[A-Z]{2}[0-9]{2}[0-9]{24}$/ }, // Poland
  PT: { length: 25, regex: /^[A-Z]{2}[0-9]{2}[0-9]{21}$/ }, // Portugal
  RO: { length: 24, regex: /^[A-Z]{2}[0-9]{2}[A-Z]{4}[0-9A-Z]{16}$/ }, // Romania
  SE: { length: 24, regex: /^[A-Z]{2}[0-9]{2}[0-9]{20}$/ }, // Sweden
  SI: { length: 19, regex: /^[A-Z]{2}[0-9]{2}[0-9]{15}$/ }, // Slovenia
  SK: { length: 24, regex: /^[A-Z]{2}[0-9]{2}[0-9]{20}$/ }, // Slovakia
  US: { length: 34, regex: /^[A-Z]{2}[0-9]{2}[0-9A-Z]{30}$/ }, // USA (example, not standard)
  // Add more countries as needed
};

export function isValidIBAN(iban) {
  iban = iban.replace(/\s+/g, "").toUpperCase();

  const countryCode = iban.slice(0, 2);
  const countryRule = ibanFormats[countryCode];

  // Validate country-specific length and structure
  if (
    !countryRule ||
    iban.length !== countryRule.length ||
    !countryRule.regex.test(iban)
  ) {
    return false;
  }

  // Rearrange for checksum validation
  const rearranged = iban.slice(4) + iban.slice(0, 4);
  const converted = rearranged
    .split("")
    .map((char) => (isNaN(char) ? char.charCodeAt(0) - 55 : char))
    .join("");

  // Validate checksum
  let remainder = "";
  for (let i = 0; i < converted.length; i += 7) {
    remainder = String(
      parseInt(remainder + converted.slice(i, i + 7), 10) % 97
    );
  }

  return remainder === "1";
}

const isoCountryCodes = [
  "AL",
  "AD",
  "AT",
  "BE",
  "BG",
  "CH",
  "DE",
  "DK",
  "ES",
  "FI",
  "FR",
  "GB",
  "GR",
  "IE",
  "IT",
  "LU",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
  "US", // Add more country codes as needed
];

export function isValidBIC(bic) {
  bic = bic.toUpperCase();
  const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

  const countryCode = bic.slice(4, 6);
  return (
    bicRegex.test(bic) &&
    (bic.length === 8 || bic.length === 11) &&
    isoCountryCodes.includes(countryCode)
  );
}
