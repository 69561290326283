import { useTranslate } from "@refinedev/core";
import { Space, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utility/formatNumber";

interface LineItem {
  discount: number;
  id: string;
  name: string;
  quantity: number;
  price_per_item: number;
  tax_rate: number;
  description?: string;
}

interface LineItemDisplayProps {
  lineItems: LineItem[];
}

const LineItemDisplay: React.FC<LineItemDisplayProps> = ({ lineItems }) => {
  const { i18n } = useTranslation();
  const translate = useTranslate();

  const columns = [
    {
      title: translate("settings.invoices.position"),
      key: "pos",
      width: 50,
      render: (_: any, __: LineItem, index: number) => index + 1,
    },
    {
      title: translate("settings.invoices.product"),
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (_: any, record: LineItem) => {
        return (
          <Space direction="vertical">
            <strong>{record.name}</strong>
            {record.description && (
              <div
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  fontSize: "0.9em",
                  color: "#778794",
                }}
              >
                {record.description}
              </div>
            )}
          </Space>
        );
      },
    },
    {
      title: translate("settings.invoices.quantity"),
      dataIndex: "quantity",
      key: "quantity",
      width: 80,
    },
    // {
    //   title: translate("settings.invoices.unit"),
    //   dataIndex: "unit",
    //   key: "unit",
    //   width: 80,
    //   render: (value: string) => {
    //     switch (value) {
    //       case "piece":
    //         return "Stück";
    //       case "kg":
    //         return "kg";
    //       case "hour":
    //         return "Stunde";
    //       default:
    //         return value || "-";
    //     }
    //   },
    // },
    {
      title: translate("settings.invoices.price"),
      dataIndex: "price_per_item",
      key: "price_per_item",
      width: 100,
      render: (value: number) =>
        `${Number((value ?? 0).toFixed(2)).toLocaleString(
          i18n.language ?? "de",
          {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }
        )} €`,
    },
    {
      title: translate("settings.invoices.tax"),
      dataIndex: "tax_rate",
      key: "tax_rate",
      width: 80,
      render: (value: number) => `${value}%`,
    },
    {
      title: translate("settings.invoices.discount"),
      dataIndex: "discount",
      key: "discount",
      width: 80,
      render: (value: string) => value || "-",
    },
    {
      title: translate("settings.invoices.total"),
      key: "total",
      align: "right" as const,
      width: 100,
      render: (_: any, record: LineItem) => {
        const discountFraction = record?.discount / 100;

        const totalAmount =
          record?.quantity * record?.price_per_item * (1 - discountFraction);
        return (
          <div style={{ paddingRight: "4.75px" }}>
            {formatNumber(totalAmount?.toFixed(2))} €
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ overflowX: "auto" }}>
      <Table
        dataSource={lineItems}
        columns={columns}
        pagination={false}
        rowKey="id"
        scroll={{ x: "100%" }}
      />
    </div>
  );
};

export default LineItemDisplay;
