import { Typography } from "antd";
import React from "react";

const { Text } = Typography;

interface ContactDisplayProps {
  contact?: string;
  label?: string;
}

const ContactDisplay: React.FC<ContactDisplayProps> = ({ contact, label }) => {
  return (
    <>
      {contact ? (
        <Text>{contact}</Text>
      ) : (
        <Text type="secondary">No contact selected</Text>
      )}
    </>
  );
};

export default ContactDisplay;
