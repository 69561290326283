import {
  FileTextOutlined,
  GlobalOutlined,
  HomeOutlined,
  InboxOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import { EmailField, UrlField, useForm } from "@refinedev/antd";
import {
  useDelete,
  useInvalidate,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Rate,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  TimePicker,
} from "antd";
import { MenuProps } from "antd/lib";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../contexts/ContextProvider";
import { transformUrl } from "../../utility/transform";
import CustomFormBuilderPage from "../custom-fields/CustomFormBuilderPage";
import { SingleElementFormV2 } from "../single-element-form/single-element-form-v2";
import { Text } from "../text";
import AddressModal from "./modals/AddressModal";
import EmailModal from "./modals/EmailModal";
import PhoneNumberModal from "./modals/PhoneNumberModal";
import URLModal from "./modals/URLModal";

const phone_regex = /^[\d\s+/-]*$/;

type Props = {
  id: any;
  firstname?: string;
  lastname?: string;
  email?: string;
  type?: string;
  name_prefix?: string;
  company_name?: string;
  gender?: string;
  job_title?: string;
  url?: string;
  phone_numbers?: any;
  addresses?: any;
  tags?: any;
  salutation?: string;
  email_invoices?: string;
  override_salutation?: string;
  createdAt?: string;
  queryResult?: any;
  refetch?: () => any;
  custom_fields?: any;
  schema?: any;
};

const BasicContact: React.FC<Props> = ({
  id,
  email,
  job_title,
  url,
  phone_numbers,
  email_invoices,
  addresses,
  custom_fields,
  refetch,
  schema,
}) => {
  const { currentTeam } = useUser();
  const translate = useTranslate();
  const [modalField, setModalField] = useState<string | null>(null);
  const [activeForm, setActiveForm] = useState<string | undefined>();
  const { mutateAsync: updateSingle } = useUpdate();
  const { mutateAsync: deleteOne } = useDelete();
  const invalidate = useInvalidate();
  const [modalVisible, setModalVisible] = useState(false);
  const { formProps, form } = useForm({ queryOptions: { enabled: false } });

  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);
  const [isPhoneNumberModalVisible, setIsPhoneNumberModalVisible] =
    useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isURLModalVisible, setIsURLModalVisible] = useState(false);

  const items: MenuProps["items"] = [
    {
      label: translate("contacts.add_phone_number"),
      key: "add_phone_number",
    },
    {
      label: translate("contacts.add_address"),
      key: "add_address",
    },
  ];

  if (!email) {
    items.push({
      label: `${translate("contacts.email.label")}`,
      key: "add_email",
    });
  }

  if (!url) {
    items.push({
      label: `${translate("contacts.url.label")}`,
      key: "add_url",
    });
  }
  if (!email_invoices) {
    items.push({
      label: translate("contacts.email_invoices"),
      key: "add_email_invoices",
    });
  }

  const customFieldKeys = Object.keys(custom_fields || {});
  (schema || []).forEach((fieldDef: any) => {
    const fieldName = fieldDef.field?.name;
    if (fieldName && !customFieldKeys.includes(fieldName)) {
      items.push({
        label: `${fieldDef.field.label || fieldName}`,
        key: `add_custom_${fieldName}`,
      });
    }
  });

  const handleMenuClick = (e: any) => {
    const { key } = e;
    switch (key) {
      case "add_address":
        setIsAddressModalVisible(true);
        break;
      case "add_phone_number":
        setIsPhoneNumberModalVisible(true);
        break;
      case "add_email":
        setIsEmailModalVisible(true);
        break;
      case "add_url":
        setIsURLModalVisible(true);
        break;
      case "add_email_invoices":
        setModalField(key);
        setModalVisible(true);
        break;
      default:
        if (key.startsWith("add_custom_")) {
          const customFieldName = key.replace("add_custom_", "");
          setModalField(customFieldName);
          setModalVisible(true);
        }
        break;
    }
  };
  const renderModalContent = () => {
    switch (modalField) {
      case "add_email_invoices":
        return (
          <Form
            {...formProps}
            onFinish={async (values: any) => {
              updateSingle({
                resource: "contacts",
                id: id,
                values: {
                  email_invoices: values.email_invoices,
                },
              });
              setModalVisible(false);
              setModalField(null);
              setActiveForm(undefined);
              formProps.form?.resetFields();
            }}
            layout="vertical"
          >
            <Form.Item
              name="email_invoices"
              label={translate("contacts.alternate_invoice_email")}
              rules={[{ required: true, type: "email" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {translate("buttons.save")}
              </Button>
            </Form.Item>
          </Form>
        );
      default:
        if (modalField) {
          const fieldDef = (schema || []).find(
            (field: any) => field.field?.name === modalField
          );
          if (fieldDef) {
            return (
              <Form
                onFinish={async (values) => {
                  const newCustomFields = { ...custom_fields, ...values };
                  await updateSingle({
                    resource: "contacts",
                    id: id,
                    values: { custom_fields: newCustomFields },
                  });
                  setModalVisible(false);
                  setModalField(null);
                  setActiveForm(undefined);
                  refetch?.();
                }}
                layout="vertical"
              >
                {renderCustomFieldFormItem(fieldDef)}
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {translate("buttons.save")}
                  </Button>
                </Form.Item>
              </Form>
            );
          }
        }
        return null;
    }
  };
  const renderCustomFieldFormItem = (fieldDef: any) => {
    const fieldName = fieldDef.field.name;
    const fieldLabel = fieldDef.field.label;
    const fieldRequired = fieldDef.field.required;
    const requiredMessage = fieldDef.field.required_message;

    switch (fieldDef.type) {
      case "input":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Input placeholder={fieldLabel} />
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Input.TextArea
              placeholder={fieldLabel}
              rows={fieldDef.field.rows || 4}
            />
          </Form.Item>
        );
      case "datepicker":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <DatePicker format={translate("global.date_format")} />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Select
              mode={fieldDef.field.multiple ? "multiple" : undefined}
              options={fieldDef.field.options.map((option: string) => ({
                label: option,
                value: option,
              }))}
            />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Checkbox.Group
              options={fieldDef.field.options.map((option: string) => ({
                label: option,
                value: option,
              }))}
            />
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Radio.Group
              options={fieldDef.field.options.map((option: string) => ({
                label: option,
                value: option,
              }))}
            />
          </Form.Item>
        );
      case "rating":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <Rate
              tooltips={fieldDef.field.tooltips}
              allowHalf={fieldDef.field.allowHalf}
              allowClear={fieldDef.field.allowClear}
            />
          </Form.Item>
        );
      case "timepicker":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <TimePicker />
          </Form.Item>
        );
      case "currency":
        return (
          <Form.Item
            name={fieldName}
            label={fieldLabel}
            rules={[
              {
                required: fieldRequired,
                message: requiredMessage,
              },
            ]}
          >
            <InputNumber
              precision={fieldDef.field.decimal_places}
              addonAfter={
                fieldDef.field.show_symbol
                  ? fieldDef.field.currency_symbol
                  : fieldDef.field.currency_code
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        );

      default:
        return null;
    }
  };

  if (currentTeam?.account_id === undefined) {
    return <Spin />;
  }
  return (
    <>
      {/* Modals */}
      <Modal
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setModalField(null);
        }}
        centered
        footer={null}
        destroyOnClose={true}
        title={
          modalField?.startsWith("add_")
            ? translate(`contacts.${modalField}`)
            : `${
                (schema || [])?.find(
                  (field: any) => field?.field?.name === modalField
                )?.field?.label
              }`
        }
      >
        {modalField && renderModalContent()}
      </Modal>
      <AddressModal
        visible={isAddressModalVisible}
        onClose={() => setIsAddressModalVisible(false)}
        onSuccess={() => {
          refetch?.();
        }}
        contactId={id}
      />
      <PhoneNumberModal
        visible={isPhoneNumberModalVisible}
        onClose={() => setIsPhoneNumberModalVisible(false)}
        onSuccess={() => {
          refetch?.();
        }}
        contactId={id}
      />
      <EmailModal
        visible={isEmailModalVisible}
        onClose={() => setIsEmailModalVisible(false)}
        onSuccess={() => {
          refetch?.();
        }}
        contactId={id}
      />
      <URLModal
        visible={isURLModalVisible}
        onClose={() => setIsURLModalVisible(false)}
        onSuccess={() => {
          refetch?.();
        }}
        contactId={id}
      />

      {/* Phone Numbers */}
      {phone_numbers?.map((phoneNumber: any, index: number) => (
        <SingleElementFormV2
          key={phoneNumber.id}
          state={
            activeForm && activeForm === `phone_numbers_${index}`
              ? "form"
              : phoneNumber
              ? "view"
              : "empty"
          }
          itemProps={{
            name: "phone_numbers",
            label: "",
          }}
          icon={<PhoneOutlined height={24} width={24} />}
          view={
            <Space direction="vertical">
              <Flex key={index}>
                <Text>
                  <Space size={"middle"}>
                    <Link to={`tel:${phoneNumber.number}`}>
                      {phoneNumber.number}
                    </Link>
                    {phoneNumber.label && <Tag>{phoneNumber.label}</Tag>}
                  </Space>
                </Text>
              </Flex>
            </Space>
          }
          onClick={() => setActiveForm(`phone_numbers_${index}`)}
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(undefined)}
          onDelete={() =>
            deleteOne({
              resource: "phone_numbers",
              id: phoneNumber.id,
              invalidates: ["resourceAll"],
            })
              .then(refetch?.())
              .finally(() => {
                setActiveForm(undefined);
              })
          }
          useFormProps={{
            onMutationSuccess() {
              refetch?.();
            },
            resource: "phone_numbers",
            mutationMode: "pessimistic",
            invalidates: ["resourceAll"],
          }}
          formProps={{
            async onFinish(values) {
              await updateSingle({
                resource: "phone_numbers",
                id: phoneNumber?.id,
                values: {
                  label: values?.label,
                  number: values?.number,
                },

                invalidates: ["resourceAll"],
                mutationMode: "pessimistic",
              });

              setActiveForm(undefined);
              refetch?.();
              invalidate({
                resource: "contacts",
                invalidates: ["resourceAll"],
              });
            },
          }}
          extra={
            <Row gutter={8}>
              <Col xs={24} sm={16}>
                <Form.Item
                  name={["number"]}
                  initialValue={phoneNumber?.number}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: translate(
                        "contacts.phone_number_required",
                        "Phone number is required"
                      ),
                    },
                    {
                      validator: (_, value) => {
                        if (!value || phone_regex.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            translate(
                              "contacts.phone_number_error",
                              "Invalid phone number format"
                            )
                          )
                        );
                      },
                    },
                  ]}
                >
                  <Input placeholder={translate("contacts.number")} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  name={["label"]}
                  initialValue={phoneNumber?.label}
                  noStyle
                >
                  <Input placeholder={translate("contacts.label")} />
                </Form.Item>
              </Col>
            </Row>
          }
        />
      ))}

      {/* Email */}
      {email && (
        <SingleElementFormV2
          state={
            activeForm && activeForm === "email"
              ? "form"
              : email
              ? "view"
              : "empty"
          }
          itemProps={{
            name: "email",
            label: "",
            initialValue: email,
          }}
          useFormProps={{
            resource: "contacts",
            id: id,
          }}
          icon={<MailOutlined style={{ fontSize: "18px" }} />}
          view={
            <Space size={"middle"}>
              <EmailField value={email} />
            </Space>
          }
          onClick={() => setActiveForm("email")}
          onUpdate={() => {
            setActiveForm(undefined);
          }}
          onCancel={() => setActiveForm(undefined)}
          onDelete={() =>
            updateSingle({
              resource: "contacts",
              id: id,
              values: { email: null },
            })
          }
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Form.Item
            name="email"
            initialValue={email}
            rules={[
              {
                required: true,
                message: translate("contacts.email_required"),
              },
              {
                type: "email",
                message: translate("contacts.invalid_email"),
              },
            ]}
            style={{ flex: 1, margin: 0 }}
          >
            <Input
              placeholder={translate("contacts.email.label")}
              style={{
                width: "100%",
                fontSize: "14px",
                borderRadius: "4px",
              }}
            />
          </Form.Item>
        </SingleElementFormV2>
      )}

      {/* URL */}
      {url && (
        <SingleElementFormV2
          state={
            activeForm && activeForm === "url" ? "form" : url ? "view" : "empty"
          }
          itemProps={{
            name: "url",
            label: "",
            initialValue: url,
          }}
          useFormProps={{
            resource: "contacts",
            id: id,
          }}
          view={
            <Space size={"middle"}>
              <UrlField target="blank" value={transformUrl(url ?? "")} />
            </Space>
          }
          icon={<GlobalOutlined />}
          onClick={() => setActiveForm("url")}
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(undefined)}
          onDelete={() =>
            updateSingle({
              resource: "contacts",
              id: id,
              values: { url: null },
            })
          }
        >
          <Form.Item
            name="url"
            initialValue={url}
            rules={[
              {
                type: "url",
                message: translate("contacts.invalid_url"),
              },
            ]}
            style={{ flex: 1, margin: 0 }}
          >
            <Input placeholder={translate("contacts.url.placeholder")} />
          </Form.Item>
        </SingleElementFormV2>
      )}
      {email_invoices && (
        <SingleElementFormV2
          state={
            activeForm && activeForm === "email_invoices"
              ? "form"
              : email_invoices
              ? "view"
              : "empty"
          }
          itemProps={{
            name: "email_invoices",
            label: "",
            initialValue: email_invoices,
          }}
          useFormProps={{
            resource: "contacts",
            id: id,
          }}
          view={
            <Space size={"middle"}>
              <EmailField value={email_invoices} />
            </Space>
          }
          icon={<FileTextOutlined />}
          onClick={() => setActiveForm("email_invoices")}
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(undefined)}
          onDelete={() =>
            updateSingle({
              resource: "contacts",
              id: id,
              values: { email_invoices: null },
            })
          }
        >
          <Input placeholder={translate("contacts.url.placeholder")} />
        </SingleElementFormV2>
      )}
      {/* Addresses */}
      {addresses?.map((address: any, index: number) => (
        <SingleElementFormV2
          key={address.id}
          state={
            activeForm && activeForm === `addresses_${index}`
              ? "form"
              : addresses
              ? "view"
              : "empty"
          }
          itemProps={{
            name: "addresses",
            label: "",
          }}
          icon={
            address.type === "PO_Box_Address" ? (
              <InboxOutlined />
            ) : address.type === "Packing_Station" ? (
              <ShopOutlined />
            ) : (
              <HomeOutlined />
            )
          }
          iconStyle={{ alignSelf: "start", marginTop: 1 }}
          view={
            <Space size={"middle"} align="start">
              <Text>
                {address.type === "PO_Box_Address" ? (
                  <>
                    <Row>{address.street}</Row>
                  </>
                ) : address.type === "Packing_Station" ? (
                  <>
                    {address.address_supplement_1 && (
                      <Row>
                        {translate("contacts.post_number")}:{" "}
                        {address.address_supplement_1}
                      </Row>
                    )}
                    <Row>
                      {translate("contacts.packstation")}: {address.street}
                    </Row>
                  </>
                ) : (
                  <>
                    {/* Home Address */}
                    {address.address_supplement_1 && (
                      <Row>{address.address_supplement_1}</Row>
                    )}
                    <Row>{address.street}</Row>
                  </>
                )}
                <Row>
                  {address.zip} {address.city}
                </Row>
                <Row>
                  {translate(
                    `countries.${(address?.country ?? "").toUpperCase()}`
                  )}
                </Row>
              </Text>
              {address.label && <Tag>{address.label}</Tag>}
            </Space>
          }
          useFormProps={{
            onMutationSuccess() {
              refetch?.();
            },
            mutationMode: "pessimistic",
            invalidates: ["resourceAll"],
          }}
          formProps={{
            async onFinish(values) {
              updateSingle({
                resource: "addresses",
                id: address.id,
                values: {
                  type: values?.type,
                  label: values?.label,
                  street: values?.street,
                  address_supplement_1: values?.address_supplement_1,
                  zip: values?.zip,
                  city: values?.city,
                  email_invoices: values?.email_invoices,
                  country: values?.country,
                },
                invalidates: ["resourceAll"],
                mutationMode: "pessimistic",
              });
              setActiveForm(undefined);
              refetch?.();
              invalidate({
                resource: "contacts",
                invalidates: ["resourceAll"],
              });
            },
          }}
          onClick={() => setActiveForm(`addresses_${index}`)}
          onUpdate={() => setActiveForm(undefined)}
          onCancel={() => setActiveForm(undefined)}
          onDelete={() => {
            deleteOne({
              resource: "addresses",
              id: address.id,
            });
            refetch?.();
          }}
          extra={
            <>
              {/* Address Fields */}
              <Row gutter={6}>
                {/* Label */}
                <Col xs={24}>
                  <Form.Item name={"label"} initialValue={address?.label}>
                    <Input placeholder={translate("contacts.label")} />
                  </Form.Item>
                </Col>

                {/* Conditional Fields Based on Type */}
                {form.getFieldValue("type") === "PO_Box_Address" ? (
                  <>
                    {/* P.O. Box Address */}
                    <Col xs={24}>
                      <Form.Item
                        name={"street"}
                        initialValue={address?.street}
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder={translate("contacts.po_box_address")}
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : form.getFieldValue("type") === "Packing_Station" ? (
                  <>
                    {/* Postnummer (c/o) */}
                    <Col xs={24}>
                      <Form.Item
                        name={"address_supplement_1"}
                        initialValue={address?.address_supplement_1}
                      >
                        <Input
                          placeholder={translate("contacts.post_number")}
                        />
                      </Form.Item>
                    </Col>
                    {/* Packstation */}
                    <Col xs={24}>
                      <Form.Item
                        name={"street"}
                        initialValue={address?.street}
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder={translate("contacts.packstation")}
                        />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <>
                    {/* Address Optional (c/o) */}
                    <Col xs={24}>
                      <Form.Item
                        name={"address_supplement_1"}
                        initialValue={address?.address_supplement_1}
                      >
                        <Input
                          placeholder={translate("contacts.address_optional")}
                        />
                      </Form.Item>
                    </Col>
                    {/* Street */}
                    <Col xs={24}>
                      <Form.Item
                        name={"street"}
                        initialValue={address?.street}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder={translate("contacts.street")} />
                      </Form.Item>
                    </Col>
                  </>
                )}

                {/* Zip */}
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={"zip"}
                    initialValue={address?.zip}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={translate("contacts.zip")} />
                  </Form.Item>
                </Col>
                {/* City */}
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={"city"}
                    initialValue={address?.city}
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={translate("contacts.city")} />
                  </Form.Item>
                </Col>
                {/* Country */}
                <Col xs={24} sm={24}>
                  <Form.Item
                    name={"country"}
                    initialValue={address?.country}
                    // rules={[{ required: true }]}
                  >
                    <Select
                      placeholder={translate("contacts.country")}
                      size="large"
                      options={[
                        { value: "de", label: translate("countries.DE") },
                        { value: "at", label: translate("countries.AT") },
                        { value: "ch", label: translate("countries.CH") },
                        { value: "us", label: translate("countries.US") },
                        { value: "fr", label: translate("countries.FR") },
                        { value: "uk", label: translate("countries.UK") },
                        { value: "es", label: translate("countries.ES") },
                        { value: "it", label: translate("countries.IT") },
                      ]}
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          }
        />
      ))}

      {/* Custom Fields */}
      {custom_fields && schema && (
        <CustomFormBuilderPage
          mode="pureView"
          fields={schema}
          data={custom_fields}
          contactId={id}
          setFields={() => null}
          setActiveForm={(e) => setActiveForm(e)}
          activeForm={activeForm}
        />
      )}

      {/* Add Property Button */}
      <Space style={{ padding: "0 0 5px" }}>
        <Dropdown
          menu={{ items, onClick: handleMenuClick }}
          trigger={["click"]}
        >
          <Button
            onClick={(e) => e.preventDefault()}
            style={{ fontSize: "0.80rem", fontWeight: 500, paddingTop: 0 }}
            type="link"
          >
            <Space>
              <PlusOutlined />
              {translate("contacts.add_property")}
            </Space>
          </Button>
        </Dropdown>
      </Space>
    </>
  );
};

export default BasicContact;
