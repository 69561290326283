import {
  ArrowRightOutlined,
  DeleteOutlined,
  GlobalOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { EmailField, UrlField, useTable } from "@refinedev/antd";
import {
  useCreate,
  useInvalidate,
  useNavigation,
  useSubscription,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Flex,
  List,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../contexts/ContextProvider";
import { useDeleteForJunctionTable } from "../../hooks/useDeleteForJunctionTable";
import { getContactFormatedName } from "../../utility/contactName";
import { transformUrl } from "../../utility/transform";
import CustomFormBuilderPage from "../custom-fields/CustomFormBuilderPage";
import { SingleElementFormV2 } from "../single-element-form/single-element-form-v2";
import { Text } from "../text";
import ContactAutoSelect from "./ContactAutoSelect";

type Props = {
  parent: any;
  contacts: any;
  triggerRefetch: any;
  schema?: any;
  contact?: any;
};

export default function CompanyContactsTable({
  parent,
  contacts,
  triggerRefetch,
  schema,
  contact,
}: Props) {
  const [ids, setIds] = useState<any>([]);
  const [addButtonClicked, setAddButtonClicked] = useState<boolean>(false);
  const [detailModal, setDetailModal] = useState<any>();
  const [popoverVisible, setPopoverVisible] = useState(false); // Control popover visibility

  const [selectedContact, setSelectedContact] = useState<string>("");
  const { mutateAsync: createMutate } = useCreate();
  const invalidate = useInvalidate();
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { show } = useNavigation();
  const { tableProps, tableQuery } = useTable<any>({
    resource: "contacts",
    meta: {
      select: "*, phone_numbers(*), addresses(*)",
    },
    pagination: {
      mode: "off",
    },
    syncWithLocation: false,
    sorters: {
      initial: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
    liveMode: "off",
    filters: {
      permanent: [
        {
          field: "id",
          operator: "in",
          value: ids,
        },
        {
          field: "account",
          value:
            currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
          operator: "eq",
        },
      ],
    },
  });
  useSubscription({
    channel: "contact_has_contact",
    types: ["*"],
    params: {
      filters: [
        {
          field: "contact_id",
          operator: "eq",
          value: parent,
        },
      ],
    },
    onLiveEvent: async () => {
      invalidate({ invalidates: ["all"] });
    },
  });

  useEffect(() => {
    setIds(contacts?.map((contact) => contact.id));
  }, [contacts]);

  async function HandleDeleteContactAsPerson(value: string) {
    const isParent = contacts.find((item) => item.id == value)?.parent;
    const data = await useDeleteForJunctionTable("contact_has_contact", {
      contact_id: isParent ? parent : value,
      child: isParent ? value : parent,
      account: currentTeam?.account_id ?? "",
    });
    if (data) {
      setIds(ids.filter((id) => id !== value));
      tableQuery.refetch();
      triggerRefetch();
    }
  }

  const handleSelect = (value: string) => {
    setSelectedContact(value);
  };

  const handleSaveContact = async () => {
    if (!selectedContact || isNaN(Number(selectedContact))) {
      console.error("Invalid contact selected:", selectedContact);
      return;
    }

    await createMutate({
      resource: "contact_has_contact",
      values: {
        contact_id: parent,
        child: selectedContact,
        account: currentTeam?.account_id,
      },
      meta: {
        select: "*",
      },
      invalidates: ["resourceAll"],
    });
    invalidate({
      invalidates: ["resourceAll"],
      resource: "contact_has_contact",
    });
    setIds([...ids, Number(selectedContact)]);
    setSelectedContact("");
    triggerRefetch();
    tableQuery.refetch();
    setPopoverVisible(false);
    setAddButtonClicked(false);
  };

  return (
    <>
      <Modal
        open={detailModal}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        centered
        styles={{
          header: { borderBottom: "none" },
          footer: { borderTop: "none" },
          body: { maxHeight: "50vh", overflow: "auto" },
        }}
        title={
          <Space direction="vertical" size={"small"} style={{ gap: 0 }}>
            <Typography.Text style={{ fontSize: "1.1rem" }}>
              {getContactFormatedName(
                "",
                detailModal?.firstname,
                detailModal?.lastname,
                detailModal?.email
              )}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontWeight: "normal" }}>
              {detailModal?.company_name}{" "}
              {detailModal?.company_name && detailModal?.job_title && "-"}{" "}
              {detailModal?.job_title}
            </Typography.Text>
          </Space>
        }
        footer={
          <Flex justify="flex-end" align="center">
            <Button
              onClick={() => {
                setDetailModal(false);
                setTimeout(() => {
                  show("contacts", detailModal?.id, "replace");
                }, 100);
              }}
              icon={<ArrowRightOutlined />}
              iconPosition="end"
            >
              {translate("contacts.view_full_details")}
            </Button>
          </Flex>
        }
        onCancel={() => setDetailModal(undefined)}
        onClose={() => setDetailModal(undefined)}
      >
        {detailModal?.phone_numbers?.map((phoneNumber: any, index: number) => (
          <SingleElementFormV2
            key={phoneNumber?.id}
            disableHoverBg
            state={"view"}
            itemProps={{
              name: "phone_numbers",
              label: "",
            }}
            icon={<PhoneOutlined height={24} width={24} />}
            view={
              <Space direction="vertical">
                <Flex key={index}>
                  <Text>
                    <Space size={"middle"}>
                      <Link to={`tel:${phoneNumber?.number}`}>
                        {phoneNumber?.number}
                      </Link>
                      {phoneNumber?.label && <Tag>{phoneNumber?.label}</Tag>}
                    </Space>
                  </Text>
                </Flex>
              </Space>
            }
          />
        ))}
        {detailModal?.email && (
          <SingleElementFormV2
            state={"view"}
            disableHoverBg
            itemProps={{
              name: "email",
              label: "",
            }}
            icon={<MailOutlined />}
            view={
              <Space size={"middle"}>
                <EmailField value={detailModal?.email} />
              </Space>
            }
          />
        )}
        {detailModal?.url && (
          <SingleElementFormV2
            disableHoverBg
            state={"view"}
            itemProps={{
              name: "url",
              label: "",
            }}
            view={
              <Space size={"middle"}>
                <UrlField
                  target="blank"
                  value={transformUrl(detailModal?.url ?? "")}
                />
              </Space>
            }
            icon={<GlobalOutlined />}
          />
        )}
        {detailModal?.addresses?.map((address: any) => (
          <SingleElementFormV2
            key={address?.id}
            state={"view"}
            itemProps={{
              name: "addresses",
              label: "",
            }}
            icon={<HomeOutlined />}
            disableHoverBg
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            view={
              <Space size={"middle"} align="start">
                <Text>
                  <Row>{address?.street && address?.street}</Row>
                  <Row>
                    {address?.zip && address?.zip}{" "}
                    {address?.city && address?.city}
                  </Row>
                  <Row>{address?.country && address?.country}</Row>
                </Text>
                {address?.label && <Tag>{address?.label}</Tag>}
              </Space>
            }
          />
        ))}
        {detailModal?.custom_fields && (
          <CustomFormBuilderPage
            mode="pureView"
            fields={schema}
            data={detailModal?.custom_fields}
            contactId={detailModal?.id}
            setFields={() => null}
            disableHoverBg
          />
        )}
      </Modal>
      <Row
        justify={"space-between"}
        align={"middle"}
        gutter={24}
        style={{ marginBottom: 5, marginLeft: 0 }}
      >
        <Flex style={{ width: "100%" }} align="baseline" gap={10}>
          <Typography.Title
            level={5}
            style={{
              fontSize: "1.02em",
              marginBottom: 0,
              paddingLeft: 10,
            }}
          >
            {translate("contacts.contacts_related", "Connections")}
          </Typography.Title>
          <Flex align="center" justify="start" style={{ flex: 1 }}>
            {addButtonClicked && (
              <div style={{ width: "50%" }}>
                <ContactAutoSelect
                  onSelect={handleSelect}
                  filterIds={[...ids, ...(contact ? [parseInt(contact)] : [])]}
                  initialValue={selectedContact}
                  label=""
                />
              </div>
            )}

            {addButtonClicked && (
              <Button
                danger
                icon={!addButtonClicked && <PlusOutlined />}
                onClick={() => setAddButtonClicked(false)}
                size="small"
              >
                {translate("buttons.cancel")}
              </Button>
            )}
          </Flex>
        </Flex>
      </Row>

      {tableProps.dataSource?.length ? (
        <List
          dataSource={tableProps.dataSource ? [...tableProps.dataSource] : []}
          loading={tableQuery.isLoading}
          renderItem={(record: any) => (
            <div
              key={record.id}
              className="related-contact-list-item"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
                borderRadius: 4,
              }}
            >
              <div style={{ flex: 1, flexDirection: "row" }}>
                <UserOutlined />
                <Space
                  direction="vertical"
                  size={"small"}
                  style={{ marginLeft: 15, gap: 0 }}
                >
                  <Typography.Text
                    style={{ margin: 0, fontWeight: 400 }}
                    className="clickableRow"
                    onClick={() => {
                      setDetailModal(record);
                    }}
                  >
                    {record.firstname || record.lastname
                      ? getContactFormatedName(
                          "",
                          record.firstname,
                          record.lastname,
                          record.email
                        )
                      : record.company_name}
                  </Typography.Text>
                  <Typography.Text
                    type="secondary"
                    style={{ fontWeight: "normal" }}
                  >
                    {(record.firstname || record.lastname) &&
                      record.company_name}{" "}
                    {record.company_name &&
                      record.job_title &&
                      (record.firstname || record.lastname) &&
                      "-"}{" "}
                    {record.job_title}
                  </Typography.Text>
                </Space>
              </div>
              <Space>
                <Popconfirm
                  title={translate("contacts.contact_person")}
                  description={translate("contacts.remove_as_contact_person")}
                  placement="leftBottom"
                  onConfirm={() => HandleDeleteContactAsPerson(record?.id)}
                  okText={translate("buttons.yes")}
                  cancelText={translate("buttons.no")}
                >
                  <Button
                    size="small"
                    icon={<DeleteOutlined />}
                    danger
                    type="text"
                    className="related-contact-delete-button"
                  />
                </Popconfirm>
              </Space>
            </div>
          )}
          footer={
            <Popover
              content={
                <Flex style={{ width: "100%", marginRight: 5 }} gap={5}>
                  <div
                    style={{
                      width: "100%",
                      minWidth: "300px",
                      height: "40px",
                      fontSize: "14px", // Adjust as necessary
                    }}
                  >
                    <ContactAutoSelect
                      onSelect={handleSelect}
                      filterIds={[
                        ...ids,
                        ...(contact ? [parseInt(contact)] : []),
                      ]}
                      initialValue={selectedContact}
                      label=""
                    />
                  </div>
                  <Button
                    onClick={() => handleSaveContact()}
                    type="primary"
                    size="small"
                    disabled={
                      !selectedContact || isNaN(Number(selectedContact))
                    } // Disable when no valid contact is selected
                  >
                    {translate("contacts.add", "Add")}
                  </Button>
                </Flex>
              }
              visible={popoverVisible} // Use the controlled visibility state
              onVisibleChange={(visible) => setPopoverVisible(visible)} // Sync visibility with state
              overlayInnerStyle={{
                width: "600px", // Adjust the width
                height: "100px",
                padding: "16px", // Adjust padding
              }}
              title={translate("contacts.add_new_related_contact")}
              trigger="click"
            >
              <Button
                onClick={(e) => e.preventDefault()}
                style={{ fontSize: "0.80rem", fontWeight: 500, padding: 7 }}
                type="link"
              >
                <Space>
                  <PlusOutlined />
                  {translate("contacts.add_contact")}
                </Space>
              </Button>
            </Popover>
          }
        />
      ) : (
        <Space direction="vertical">
          <Typography style={{ paddingLeft: 10 }}>
            {translate("contacts.empty")}
          </Typography>
          <Popover
            content={
              <Flex style={{ width: "100%", marginRight: 5 }} gap={5}>
                <div
                  style={{
                    width: "100%",
                    minWidth: "300px",
                    height: "40px",
                    fontSize: "14px", // Adjust as necessary
                  }}
                >
                  <ContactAutoSelect
                    onSelect={handleSelect}
                    filterIds={[
                      ...ids,
                      ...(contact ? [parseInt(contact)] : []),
                    ]}
                    initialValue={selectedContact}
                    label=""
                  />
                </div>

                <Button
                  onClick={() => handleSaveContact()}
                  type="primary"
                  size="small"
                  disabled={!selectedContact || isNaN(Number(selectedContact))} // Disable when no valid contact is selected
                >
                  {translate("contacts.add", "Add")}
                </Button>
              </Flex>
            }
            visible={popoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
            overlayInnerStyle={{
              width: "600px",
              height: "100px",
              padding: "16px",
            }}
            title={translate("contacts.add_new_related_contact")}
            trigger="click"
          >
            <Button
              onClick={(e) => e.preventDefault()}
              style={{ fontSize: "0.80rem", fontWeight: 500, padding: 7 }}
              type="link"
            >
              <Space>
                <PlusOutlined />
                {translate("contacts.add_contact")}
              </Space>
            </Button>
          </Popover>
        </Space>
      )}
    </>
  );
}
