import { useCreate, useTranslate } from "@refinedev/core";
import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useUser } from "../../../contexts/ContextProvider";

const phone_regex = /^[\d\s+/-]*$/;

interface PhoneNumberModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  contactId: any;
}

const PhoneNumberModal: React.FC<PhoneNumberModalProps> = ({
  visible,
  onClose,
  onSuccess,
  contactId,
}) => {
  const [form] = Form.useForm();
  const { mutateAsync: createOne } = useCreate();
  const translate = useTranslate();
  const { currentTeam } = useUser();

  const handleAddPhoneNumber = async (values: any) => {
    await createOne({
      resource: "phone_numbers",
      values: {
        ...values,
        contact: contactId,
        account: currentTeam?.account_id,
      },
    });
    form.resetFields();
    onSuccess();
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      destroyOnClose={true}
      title={translate("contacts.add_phone_number")}
    >
      <Form form={form} onFinish={handleAddPhoneNumber} layout="vertical">
        <Form.Item
          name="number"
          label={translate("contacts.number")}
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (!value || phone_regex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(translate("contacts.phone_number_error"))
                );
              },
            },
          ]}
        >
          <Input placeholder={translate("contacts.number")} />
        </Form.Item>
        <Form.Item name="label" label={translate("contacts.label")}>
          <Input placeholder={translate("contacts.label")} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {translate("buttons.save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PhoneNumberModal;
