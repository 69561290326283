import React from "react";
import { Flex, Typography, Button, Popconfirm } from "antd";
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslate } from "@refinedev/core";
import { BillingType } from "../../../interfaces/phase.interface";

interface SubscriptionPhaseShowStartEndProps {
  item: {
    start_date: string;
    end_date: string | null;
  };
  index: number;
  handlePhaseDelete?: (index: number) => void; // Optional gemacht
}

const SubscriptionPhaseShowStartEnd: React.FC<SubscriptionPhaseShowStartEndProps> = ({
                                                                                       item,
                                                                                       index,
                                                                                       handlePhaseDelete,
                                                                                     }) => {
  const translate = useTranslate();

  return (
    <Flex align="center" gap={16}>
      <Typography.Title level={5} style={{ margin: 0 }}>
        {dayjs(item.start_date).format(translate("global.date_format"))}
      </Typography.Title>

      <ArrowRightOutlined style={{ fontSize: 14 }} />

      <Typography.Title level={5} style={{ margin: 0 }}>
        {item.end_date == null
          ? translate(
            "subscription_products.fields.billing_type." + BillingType.Month
          )
          : dayjs(item.end_date).format(translate("global.date_format"))}
      </Typography.Title>

      {handlePhaseDelete && ( // Button nur anzeigen, wenn handlePhaseDelete vorhanden ist
        <Popconfirm
          title={translate(
            "subscription_products.buttons.delete.title_single"
          )}
          description={translate(
            "subscription_products.buttons.delete.description_single"
          )}
          onConfirm={() => {
            handlePhaseDelete(index);
          }}
          okText={translate("buttons.yes", "Yes")}
          cancelText={translate("buttons.no", "No")}
        >
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            style={{ fontSize: "0.80rem", padding: 7 }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {translate("subscription_products.show.delete_phase")}
          </Button>
        </Popconfirm>
      )}
    </Flex>
  );
};

export default SubscriptionPhaseShowStartEnd;
