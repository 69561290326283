import { Form, Input } from "antd";
import { useTranslate } from "@refinedev/core";

const FirstNameSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.firstname.label")}
      name="firstname"
    >
      <Input placeholder={translate("contacts.firstname.placeholder")} />
    </Form.Item>
  );
};

export default FirstNameSection;
