import { Dayjs } from "dayjs";

export enum ActivityType {
  Note = "note",
  PipelineChange = "pipeline_change",
}
type ActivityBase = {
  id?: string;
  deal: string;
  created_at: string | Date;
};
export type NoteType = {
  type: ActivityType.Note;
  value: {
    text: string;
    files: any;
    created_by: string;
    created_by_name?: string;
    due_date?: Dayjs;
    assignedTo?: [];
    resolve?: boolean | undefined;
  };
} & ActivityBase;

export type PipelineChangeType = ActivityBase & {
  type: ActivityType.PipelineChange;
  value: {
    to: {
      stage: string;
      pipeline: string;
    };
    from: {
      stage: string;
      pipeline: string;
    };
  };
};

export type Activity = PipelineChangeType | NoteType;

export type ActivityResource = "pipeline_items" | "contacts";
