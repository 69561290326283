export const numberToCurrency = (
  number: number,
  currency: "eur" | "usd" | "chf" = "eur"
): string | undefined => {
  if (isNaN(number)) {
    return undefined;
  }

  // Ensure rounding to two decimal places
  const rounded = roundToTwoDecimals(number);

  return rounded.toLocaleString("de-DE", {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
    useGrouping: true,
    minimumFractionDigits: 2,
  });
};

export function formatCurrency(number: string | number): string | undefined {
  const numericValue = typeof number === "string" ? parseFloat(number) : number;

  if (isNaN(numericValue)) {
    return undefined;
  }

  // Round and format as a string with two decimal places
  return numericValue.toFixed(2).replace(".", ",");
}

export const currencySymbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  GBP: "£", // British Pound
  JPY: "¥", // Japanese Yen
  // Add more currencies and their symbols as needed
};

export function roundToTwoDecimals(value) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}
