import { Form, Select } from "antd";
import { useTranslate } from "@refinedev/core";

const SalutationSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.salutation.label")}
      name="salutation"
    >
      <Select
        placeholder={translate("contacts.salutation.placeholder")}
        options={[
          { value: "not_specified", label: translate("contacts.salutation.not_specified") },
          { value: "mr", label: translate("contacts.salutation.mr") },
          { value: "mrs", label: translate("contacts.salutation.mrs") },
        ]}
      />
    </Form.Item>
  );
};

export default SalutationSection;
