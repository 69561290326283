import { useList } from "@refinedev/core";
import { useMemo } from "react";

interface InvoiceSummary {
  unfinished: { count: number; total: number };
  finished: { count: number; total: number };
  unpaid: { count: number; total: number };
}
interface Invoice {
  status: "unfinished" | "finished";
  amount: number;
  amount_paid: number;
}
export default function useInvoiceSummary(
  accountId: string,
  contactId?: string
) {
  const { data: invoiceData, isLoading } = useList<Invoice>({
    resource: "invoices",
    filters: [
      { field: "account", operator: "eq", value: accountId },
      ...(contactId
        ? [{ field: "contact", operator: "eq" as const, value: contactId }]
        : []),
    ],
    pagination: { current: 1, pageSize: 1000 }, // Adjust `pageSize` based on your data size
  });

  const invoiceSummary: InvoiceSummary = useMemo(() => {
    if (!invoiceData?.data) {
      return {
        unfinished: { count: 0, total: 0 },
        finished: { count: 0, total: 0 },
        unpaid: { count: 0, total: 0 },
      };
    }

    return invoiceData.data.reduce(
      (acc, invoice) => {
        const { status, amount = 0, amount_paid = 0 } = invoice;

        if (!acc[status]) acc[status] = { count: 0, total: 0 };
        acc[status].count += 1;
        acc[status].total += amount;

        if (status === "finished" && amount_paid === 0) {
          acc.unpaid.count += 1;
          acc.unpaid.total += amount;
        }

        return acc;
      },
      {
        unfinished: { count: 0, total: 0 },
        finished: { count: 0, total: 0 },
        unpaid: { count: 0, total: 0 },
      }
    );
  }, [invoiceData]);

  return { invoiceSummary, isLoading };
}
