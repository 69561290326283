import { List, useTable } from "@refinedev/antd";
import {
  BaseKey,
  CanAccess,
  useDeleteMany,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Col,
  Flex,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { PropsWithChildren, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/ContextProvider";
import { formatAddressLabel } from "../../utility/contactAddress";
import { getContactFormatedName } from "../../utility/contactName";

const ContactOrders: React.FC<PropsWithChildren<{ contactId: number }>> = ({
  children,
  contactId,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const navigate = useNavigate();
  const { currentTeam } = useUser();
  const translate = useTranslate();
  const { show } = useNavigation();

  const {
    tableProps,
    filters: activeFilters,
    setFilters,
  } = useTable({
    resource: "orders",
    meta: {
      select: "*, invoice_contact!inner(*), invoice_contact_address!inner(*)",
    },
    filters: {
      permanent: [
        {
          field: "account",
          value:
            currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
          operator: "eq",
        },
        {
          field: "invoice_contact",
          operator: "eq",
          value: contactId,
        },
      ],
    },
    sorters: {
      initial: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  });

  const { mutate } = useDeleteMany();

  const handleDeleteBulk = () => {
    mutate({
      resource: "orders",
      ids: selectedRowKeys as BaseKey[],
    });
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <CanAccess
      resource="orders"
      action="list"
      fallback={translate("module.noAccessTitle")}
    >
      <List
        breadcrumb={false}
        title=""
        headerButtons={() => (
          <Row>
            <Col
              span={24}
              style={{
                justifyContent: "flex-end",
                display: "flex",
                gap: 10,
              }}
            >
              {selectedRowKeys?.length != 0 && (
                <Popconfirm
                  title={translate("orders.delete_order_title_bulk")}
                  description={translate("orders.delete_order_content_bulk")}
                  onConfirm={handleDeleteBulk}
                  okText={translate("buttons.yes", "Yes")}
                  cancelText={translate("buttons.no", "No")}
                >
                  <Button type="primary" danger size="small">
                    {translate("buttons.delete", "Delete")}{" "}
                    {selectedRowKeys?.length != 0 &&
                      `${selectedRowKeys?.length} `}
                    {selectedRowKeys?.length != 0 &&
                      translate(
                        "buttons.delete_bulk_orders",
                        "selected orders"
                      )}
                  </Button>
                </Popconfirm>
              )}
            </Col>
          </Row>
        )}
      >
        <Flex vertical gap={24}>
          <Space align={"center"} size={20}></Space>

          <Table
            {...tableProps}
            rowKey={(record) => record.id ?? ""}
            rowSelection={rowSelection}
            pagination={{
              ...tableProps.pagination,
              showSizeChanger: true,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  record.id && show("orders", record.id);
                },
              };
            }}
          >
            <Table.Column
              title={translate("subscriptions.list.customer")}
              dataIndex="invoice_contact"
              render={(value) =>
                getContactFormatedName(
                  value?.company_name,
                  value?.firstname,
                  value?.lastname,
                  value?.email
                )
              }
            />
            <Table.Column
              title={translate(
                "subscriptions.list.invoice_contact_address",
                "Invoice Address"
              )}
              dataIndex="invoice_contact_address"
              render={(value) => formatAddressLabel(value, translate)}
            />
            <Table.Column
              title={translate("product.list.title", "Produkt")}
              dataIndex="type"
              render={(value) => (
                <Tag>
                  {value === "simple"
                    ? translate("orders.simple_type", "One-Time Order")
                    : value === "subscription"
                    ? translate(
                        "orders.subscription_type",
                        "Subscription Order"
                      )
                    : value}
                </Tag>
              )}
            />
            <Table.Column
              title={translate("product.list.title", "Status")}
              dataIndex="status"
              render={(status) => {
                let color = "default";
                switch (status) {
                  case "upcoming":
                    color = "grey";
                    break;
                  case "active":
                    color = "green";
                    break;
                  case "failed":
                    color = "red";
                    break;
                  default:
                    break;
                }

                const dotStyle = {
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: color,
                };

                return (
                  <Tooltip
                    title={translate(`billing_details.status.${status}`)}
                  >
                    <span style={dotStyle}></span>
                  </Tooltip>
                );
              }}
            />
          </Table>
        </Flex>
        {children}
      </List>
    </CanAccess>
  );
};

export default ContactOrders;
