import { getValueFromEvent, useForm } from "@refinedev/antd";
import {
  BaseKey,
  HttpError,
  useGetIdentity,
  useTranslate,
} from "@refinedev/core";

import {
  BellOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  PlusOutlined,
  SendOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Flex,
  Form,
  Popover,
  Select,
  Upload,
} from "antd";
import { UploadFile } from "antd/lib";
import dayjs from "dayjs";
import { useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import {
  ActivityResource,
  ActivityType,
} from "../../../interfaces/activity.interface";
import { supabaseClient } from "../../../utility";
import { singularizeResource } from "../../../utility/transform";
import TipTapEditor from "../../tiptap/TipTapEditor";

const CommentForm = ({
  itemId,
  resource,
}: {
  itemId?: BaseKey | undefined;
  resource: ActivityResource;
}) => {
  const { data: me } = useGetIdentity<any>();
  const translate = useTranslate();
  const { currentTeam, personalAccount, teamMembers } = useUser();
  const [isDueDateSet, setIsDueDateSet] = useState<boolean>(false);
  const [isAssignSet, setIsAssignSet] = useState<boolean>(false);
  const { form, formProps } = useForm<HttpError>({
    resource: "activities",
    action: "create",

    queryOptions: {
      enabled: false,
    },
    meta: {
      select: `*`,
    },
    redirect: false,
    mutationMode: "pessimistic",
    invalidates: ["all"],
    successNotification: false,
    onMutationSuccess: () => {
      form.resetFields();
    },
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = ({ fileList }) =>
    setFileList(fileList.filter((file) => file.status !== "error"));
  const onRemove = async (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };
  const dummyRequest = async (options: any) => {
    const { onSuccess } = options;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const props = {
    onChange: handleChange,
    multiple: true,
    customRequest: dummyRequest,
    onRemove: onRemove,
  };
  return (
    <Form
      form={form}
      style={{
        width: "100%",
        background: "#fff",
      }}
      {...formProps}
      onFinish={async (values: any) => {
        let fileArr: any[] = [];
        if (fileList?.length == 0 && values?.text == undefined) {
          return false;
        }
        if (fileList?.length > 0) {
          fileArr = await Promise.all(
            fileList.map(async (file: any) => {
              const fileName = `${Date.now()}-${file.name}`;
              const { data, error } = await supabaseClient.storage
                .from("files")
                .upload(
                  `teams/${currentTeam?.account_id}/${resource}/${itemId}/activities/${fileName}`,
                  file.originFileObj,
                  {
                    //test
                  }
                );
              if (error) {
                return error;
              } else {
                return {
                  ...data,
                  name: fileName,
                };
              }
            })
          );
        }
        setFileList([]);
        setIsAssignSet(false);
        setIsDueDateSet(false);
        return formProps?.onFinish?.({
          // pipeline_item: itemId,
          type: ActivityType.Note,
          value: {
            text: values.text,
            created_by: me.id,
            files: fileArr,
            due_date: values.due_date,
            assignedTo: values.assignedTo,
          },
          [singularizeResource(resource)]: itemId,
          account: currentTeam?.account_id,
          user: personalAccount.account_id,
        });
      }}
    >
      <Form.Item
        name="text"
        rules={[
          {
            transform(value) {
              return value?.trim();
            },
            message: translate("notes.please_enter_note"),
          },
        ]}
      >
        <TipTapEditor
          onUpdateProps={(value) => {
            form.setFieldValue("text", value.editor.getHTML());
          }}
        />
      </Form.Item>
      <Flex align="baseline" justify="end" gap={5}>
        <Popover
          content={
            <>
              {fileList?.length != 0 && (
                <div>
                  {fileList?.map((file) => (
                    <Flex
                      align="center"
                      justify="space-between"
                      key={file.uid}
                      className="fileListItem"
                    >
                      {file.name}
                      <Button
                        icon={<DeleteOutlined />}
                        danger
                        className="hideFileDeleteBtn"
                        type="link"
                        style={{ padding: 0, height: 25 }}
                        onClick={() =>
                          setFileList((prevFileList) =>
                            prevFileList.filter(
                              (file) => file.uid !== file?.uid
                            )
                          )
                        }
                      />
                    </Flex>
                  ))}
                </div>
              )}
              <Form.Item
                name="files"
                valuePropName="fileList"
                label={translate("files.files")}
                getValueFromEvent={getValueFromEvent}
                noStyle
              >
                <Upload {...props} fileList={fileList} showUploadList={false}>
                  <Button
                    style={{ padding: 0, marginTop: 5 }}
                    type="text"
                    icon={<PlusOutlined />}
                  >
                    {translate("activity.add_new_file")}
                  </Button>
                </Upload>
              </Form.Item>
            </>
          }
          title={translate("activity.upload_files")}
          trigger="click"
        >
          <Button
            icon={
              <Badge
                count={fileList?.length}
                size="small"
                style={{ fontSize: "0.6rem", top: -5, right: -2 }}
                color="blue"
              >
                <PaperClipOutlined
                  style={{ color: fileList.length > 0 ? "#198EFB" : "#999" }}
                />
              </Badge>
            }
            type="text"
          />
        </Popover>
        <Popover
          content={
            <>
              <Form.Item name={"assignedTo"}>
                <Select
                  style={{ width: "100%" }}
                  mode="multiple"
                  placeholder={translate("pipeline_items.select_owner")}
                  options={
                    teamMembers?.map((user) => ({
                      value: user.user_id,
                      label: <>{user.name ?? ""}</>,
                    })) ?? []
                  }
                  onChange={(e) => setIsAssignSet(e?.length > 0 ? true : false)}
                />
              </Form.Item>
            </>
          }
          title={translate("activity.assign_team_members")}
          trigger="click"
        >
          <Button
            icon={
              <UsergroupAddOutlined
                style={{
                  color: isAssignSet ? "#198EFB" : "#999",
                }}
              />
            }
            type="text"
          />
        </Popover>
        <Popover
          content={
            <>
              <Form.Item name={"due_date"}>
                <DatePicker
                  minDate={dayjs()}
                  format={translate("global.date_format")}
                  onChange={(d) => setIsDueDateSet(d ? true : false)}
                />
              </Form.Item>
            </>
          }
          title={translate("activity.due_date")}
          trigger="click"
        >
          <Button
            icon={
              <BellOutlined
                style={{
                  color: isDueDateSet ? "#198EFB" : "#999",
                }}
              />
            }
            type="text"
          />
        </Popover>

        <Form.Item noStyle>
          <Button type="primary" htmlType="submit" icon={<SendOutlined />} />
        </Form.Item>
      </Flex>
    </Form>
  );
};
export default CommentForm;
