import {
  BaseKey,
  BaseRecord,
  GetListResponse,
  useList,
  useShow,
} from "@refinedev/core";
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { IField } from "../../interfaces/custom-fields.interface";
import { useUser } from "../ContextProvider";

type ContactContextType = {
  contact: any;
  isLoading: boolean;
  isError: boolean;
  refetch: () => void;
  id: BaseKey | undefined;
  firstname?: string;
  lastname?: string;
  delivery_block: boolean;
  email?: string;
  name_prefix?: string;
  customer_number?: number | null | undefined;
  company_name?: string;
  company_line_2?: string;
  job_title?: string;
  salutation?: string;
  url?: string;
  phone_numbers?: any[];
  addresses?: any[];
  tags?: any[];
  custom_fields?: any;
  type?: string;
  email_invoices?: string;
  override_salutation?: string;
  contact_has_contact?: any[];
  created_at?: string;
  user?: any;
  schemaField?: IField[];
  dataTags: GetListResponse<BaseRecord> | undefined;
  marketing_optout: boolean;
  invoice_postal_mail: boolean;
  suppress_invoice_reminders: boolean;
  suppress_invoice_reminders_stage?: number | null;
  payment_deadline?: number;
  tax_number?: string;
  vat_registration_id?: string;
};
export const ContactContext = createContext<ContactContextType>({
  contact: null,
  isLoading: false,
  isError: false,
  refetch: () => [],
  id: undefined,
  firstname: "",
  lastname: "",
  email: "",
  name_prefix: "",
  delivery_block: false,
  company_name: "",
  company_line_2: "",
  job_title: "",
  salutation: "",
  url: "",
  email_invoices: "",
  customer_number: null,
  phone_numbers: [],
  addresses: [],
  tags: [],
  type: "",
  custom_fields: {},
  override_salutation: "",
  contact_has_contact: [],
  created_at: "",
  user: null,
  dataTags: undefined,
  schemaField: undefined,
  marketing_optout: true,
  invoice_postal_mail: false,
  suppress_invoice_reminders: false,
  suppress_invoice_reminders_stage: null,
  payment_deadline: undefined,
  tax_number: undefined,
  vat_registration_id: undefined,
});

export const ContactProvider: React.FC<
  PropsWithChildren<{ contactId: any }>
> = ({ contactId, children }) => {
  const [schemaField, setSchemaField] = useState<IField[]>([]);

  const { currentTeam } = useUser();
  const { query } = useShow({
    resource: "contacts",
    id: contactId,
    meta: {
      select:
        "*, phone_numbers(*), addresses(*), tags(*), contact_has_contact!contact_has_contact_contact_id_fkey(child:contacts!contact_has_contact_child_fkey(id))",
    },
  });
  const { data, isLoading, isError, refetch } = query;
  const { data: dataTags } = useList({
    resource: "tags",
    pagination: {
      mode: "off",
    },
    filters: [
      {
        field: "account",
        value: currentTeam?.account_id,
        operator: "eq",
      },
    ],
  });
  const { data: settingQuery } = useList({
    resource: "settings",
    filters: [
      {
        field: "account",
        value: currentTeam?.account_id,
        operator: "eq",
      },
    ],
  });
  useEffect(() => {
    if (
      settingQuery &&
      settingsData?.contact_custom_fields_json &&
      settingsData?.contact_custom_fields_json.length > 0
    ) {
      setSchemaField(settingsData?.contact_custom_fields_json);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingQuery]);
  const settingsData = settingQuery?.data[0];
  const {
    id,
    firstname,
    lastname,
    email,
    name_prefix,
    customer_number,
    company_name,
    company_line_2,
    job_title,
    salutation,
    url,
    type,
    phone_numbers,
    addresses,
    email_invoices,
    tags,
    custom_fields,
    override_salutation,
    contact_has_contact,
    created_at,
    user,
    marketing_optout,
    invoice_postal_mail,
    suppress_invoice_reminders,
    suppress_invoice_reminders_stage,
    payment_deadline,
    delivery_block,
    tax_number,
    vat_registration_id,
  } = data?.data ?? {};
  return (
    <ContactContext.Provider
      value={{
        contact: data?.data,
        id,
        firstname,
        lastname,
        email,
        name_prefix,
        customer_number,
        delivery_block,
        company_name,
        company_line_2,
        job_title,
        salutation,
        url,
        phone_numbers,
        addresses,
        email_invoices,
        tags,
        custom_fields,
        type,
        override_salutation,
        contact_has_contact,
        created_at,
        user,
        isLoading,
        isError,
        dataTags,
        schemaField,
        marketing_optout,
        invoice_postal_mail,
        suppress_invoice_reminders,
        suppress_invoice_reminders_stage,
        payment_deadline,
        refetch,
        tax_number,
        vat_registration_id,
      }}
    >
      {data && children}
    </ContactContext.Provider>
  );
};
