import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { BaseKey, useTranslate, useUpdate } from "@refinedev/core";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Flex,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Rate,
  Row,
  Select,
  Space,
  Tag,
  TimePicker,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { CSSProperties, useState } from "react";
import { SingleElementFormV2 } from "../../../components/single-element-form/single-element-form-v2";
import { IField } from "../../../interfaces/custom-fields.interface";
import CustomFormSingle from "./CustomFormSingle";

const { Title } = Typography;

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : "",
    transition,
    padding: "8px",
    margin: "4px 0",
    backgroundColor: "#f0f0f0",
    borderRadius: "4px",
  };
  return (
    <div ref={setNodeRef} style={{ ...style }} {...attributes}>
      <Card>
        <Flex gap={30} align="center">
          <HolderOutlined {...listeners} style={{ cursor: "grab" }} />

          {children}
        </Flex>
      </Card>
    </div>
  );
};

type Props = {
  mode: "edit" | "view" | "viewWithoutForm" | "pureView";
  data?: JSON;
  saveData?: (any) => void;
  onChange?: (any) => void;
  fields: IField[];
  setFields: (field: IField[]) => void;
  productId?: any;
  setActiveForm?: (any) => void;
  disableHoverBg?: boolean;
  activeForm?: any;
};

const fieldViewStyle: CSSProperties = {
  margin: 0,
  width: "100%",
  minWidth: "150px",
};
const CustomFormBuilderPage = ({
  mode = "view",
  data,
  saveData,
  fields,
  setFields,
  onChange,
  productId,
  setActiveForm,
  activeForm,
  disableHoverBg,
}: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<IField | undefined>(undefined);
  const translate = useTranslate();
  const { mutateAsync: updateSingle } = useUpdate();
  const [form] = Form.useForm();
  const fieldOptions = [
    { label: translate("global.text_field"), key: "text" },
    { label: translate("global.input_field"), key: "input" },
    { label: translate("global.select_field"), key: "select" },
    { label: translate("global.checkbox_field"), key: "checkbox" },
    { label: translate("global.radiobutton_field"), key: "radio" },
    { label: translate("global.rating_field"), key: "rating" },
    { label: translate("global.textarea_field"), key: "textarea" },
    { label: translate("global.timepicker_field"), key: "timepicker" },
    { label: translate("global.datepicker_field"), key: "datepicker" },
    { label: translate("global.currency_field"), key: "currency" },
  ];

  const handleAddField = (type) => {
    const fieldSchema = fieldOptions.find((option) => option.key === type.key);
    const id = new Date().getTime().toString();
    if (fieldSchema?.key === "text") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: { value: translate("global.text_field_value"), level: 2 },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "input") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "input" + id,
          label: translate("global.input_field"),
          required: false,
          required_message: "Please fill the field",
        },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "select") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "select" + id,
          label: translate("global.select_field"),
          required: false,
          required_message: "Please select one",
          options: ["Option 1", "Option 2", "Option 3"],
          multiple: false,
        },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "checkbox") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "check" + id,
          label: translate("global.checkbox_field"),
          required: false,
          required_message: "Please select one",
          columns: 8,
          options: ["Option 1", "Option 2", "Option 3"],
        },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "radio") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "radio" + id,
          label: translate("global.radiobutton_field"),
          required: false,
          required_message: "Please select one",
          options: ["Option 1", "Option 2", "Option 3"],
          columns: 8,
        },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "rating") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "rating" + id,
          label: translate("global.rating_field"),
          allowClear: false,
          allowHalf: false,
          tooltips: [
            translate("global.rating_terrible"),
            translate("global.rating_bad"),
            translate("global.rating_normal"),
            translate("global.rating_good"),
            translate("global.rating_wonderful"),
          ],
        },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "textarea") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "textarea" + id,
          label: translate("global.textarea_field"),
          required: false,
          required_message: "Please fill the field",
          rows: 4,
        },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "timepicker") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "timepicker" + id,
          label: translate("global.timepicker_field"),
          required: false,
          required_message: "Please fill the field",
        },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "datepicker") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "datepicker" + id,
          label: translate("global.datepicker_field"),
          required: false,
          format: translate("global.date_format"),
          required_message: "Please fill the field",
        },
      };
      setFields([...fields, newField]);
    } else if (fieldSchema?.key === "currency") {
      const newField: IField = {
        id,
        type: fieldSchema.key || "",
        col_span: 24,
        field: {
          name: "currency" + id,
          label: translate("global.currency_field"),
          required: false,
          required_message: "Please fill the field",
          currency_code: "USD",
          currency_symbol: "$",
          show_symbol: true,
          decimal_places: 2,
        },
      };
      setFields([...fields, newField]);
    }
  };
  const renderField = (field: IField) => {
    switch (field.type) {
      case "text":
        return (
          <Title style={{ ...fieldViewStyle }} level={field.field.level}>
            {field.field.value}
          </Title>
        );
      case "input":
        return (
          <Form.Item
            label={field.field.label}
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            rules={[
              {
                required: field.field.required,
                message: field.field.required_message,
              },
            ]}
            initialValue={data && data?.[field.field.name]}
            style={{ ...fieldViewStyle }}
          >
            <Input />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            rules={[
              {
                required: field.field.required,
                message: field.field.required_message,
              },
            ]}
            label={field.field.label}
            initialValue={data && data?.[field.field.name]}
            style={{ ...fieldViewStyle, width: "100%" }}
          >
            <Select
              mode={field.field.multiple ? "multiple" : undefined}
              options={field.field.options.map((option) => ({
                label: option,
                value: option,
              }))}
            />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            rules={[
              {
                required: field.field.required,
                message: field.field.required_message,
              },
            ]}
            label={field.field.label}
            style={{ ...fieldViewStyle }}
            initialValue={data && data?.[field.field.name]}
          >
            <Checkbox.Group
              style={{
                width: "100%",
              }}
            >
              <Row gutter={16}>
                {field.field.options.map((option) => (
                  <Col span={field.field.columns} key={option}>
                    <Checkbox value={option}>{option}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            rules={[
              {
                required: field.field.required,
                message: field.field.required_message,
              },
            ]}
            label={field.field.label}
            style={{ ...fieldViewStyle }}
            initialValue={data && data?.[field.field.name]}
          >
            <Radio.Group>
              <Row>
                {field.field.options.map((option) => (
                  <Col span={field.field.columns} key={option}>
                    <Radio value={option}>{option}</Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
        );
      case "rating":
        return (
          <Form.Item
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            label={field.field.label}
            style={{ ...fieldViewStyle }}
            initialValue={data && data?.[field.field.name]}
          >
            <Rate
              allowClear={field.field.allowClear}
              allowHalf={field.field.allowHalf}
              tooltips={field.field.tooltips}
            />
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            rules={[
              {
                required: field.field.required,
                message: field.field.required_message,
              },
            ]}
            label={field.field.label}
            style={{ ...fieldViewStyle }}
            initialValue={data && data?.[field.field.name]}
          >
            <Input.TextArea rows={field.field.rows} />
          </Form.Item>
        );
      case "timepicker":
        return (
          <Form.Item
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            label={field.field.label}
            rules={[
              {
                required: field.field.required,
                message: field.field.required_message,
              },
            ]}
            initialValue={
              data?.[field.field.name] && mode == "view"
                ? dayjs(data?.[field.field.name])
                : undefined
            }
            style={{ ...fieldViewStyle }}
          >
            <TimePicker format={"HH:mm"} />
          </Form.Item>
        );
      case "datepicker":
        return (
          <Form.Item
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            label={field.field.label}
            rules={[
              {
                required: field.field.required,
                message: field.field.required_message,
              },
            ]}
            initialValue={
              data?.[field.field.name] && mode == "view"
                ? dayjs(data?.[field.field.name], field.field.format)
                : undefined
            }
            style={{ ...fieldViewStyle }}
          >
            <DatePicker
              format={translate("global.date_format")}
              style={{ width: "100%" }}
            />
          </Form.Item>
        );
      case "currency":
        return (
          <Form.Item
            label={field.field.label}
            name={
              mode === "viewWithoutForm"
                ? ["custom_fields", field.field.name]
                : field.field.name
            }
            rules={[
              {
                required: field.field.required,
                message: field.field.required_message,
              },
            ]}
            initialValue={data && data?.[field.field.name]}
            style={{ ...fieldViewStyle }}
          >
            <InputNumber
              precision={field.field.decimal_places}
              addonAfter={
                field.field.show_symbol
                  ? field.field.currency_symbol
                  : field.field.currency_code
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        );
      default:
        return null;
    }
  };
  const renderFieldView = (field: IField) => {
    switch (field.type) {
      case "input":
        return (
          <SingleElementFormV2
            disableHoverBg={disableHoverBg}
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              rules: [
                {
                  required: field.field.required,
                  message: field.field.required_message,
                },
              ],
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={<Typography.Text>{data?.[field.field.name]}</Typography.Text>}
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <Input />
          </SingleElementFormV2>
        );
      case "select":
        return (
          <SingleElementFormV2
            disableHoverBg={disableHoverBg}
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              rules: [
                {
                  required: field.field.required,
                  message: field.field.required_message,
                },
              ],
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={<Typography.Text>{data?.[field.field.name]}</Typography.Text>}
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <Select
              style={{ width: "100%" }}
              mode={field.field.multiple ? "multiple" : undefined}
              options={field.field.options.map((option) => ({
                label: option,
                value: option,
              }))}
            />
          </SingleElementFormV2>
        );
      case "checkbox":
        return (
          <SingleElementFormV2
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              rules: [
                {
                  required: field.field.required,
                  message: field.field.required_message,
                },
              ],
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            disableHoverBg={disableHoverBg}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={data?.[field.field.name].map((option: any) => (
              <Tag>{option}</Tag>
            ))}
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <Checkbox.Group
              style={{
                width: "100%",
              }}
            >
              <Row>
                {field.field.options.map((option) => (
                  <Col span={field.field.columns} key={option}>
                    <Checkbox value={option}>{option}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </SingleElementFormV2>
        );
      case "radio":
        return (
          <SingleElementFormV2
            disableHoverBg={disableHoverBg}
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              rules: [
                {
                  required: field.field.required,
                  message: field.field.required_message,
                },
              ],
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={<Tag>{data?.[field.field.name]}</Tag>}
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <Radio.Group>
              {field.field.options.map((option) => (
                <Radio value={option}>{option}</Radio>
              ))}
            </Radio.Group>
          </SingleElementFormV2>
        );
      case "rating":
        return (
          <SingleElementFormV2
            disableHoverBg={disableHoverBg}
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={
              <Rate
                allowClear={field.field.allowClear}
                allowHalf={field.field.allowHalf}
                tooltips={field.field.tooltips}
                value={data?.[field.field.name]}
                disabled
              />
            }
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <Rate
              allowClear={field.field.allowClear}
              allowHalf={field.field.allowHalf}
              tooltips={field.field.tooltips}
              value={data?.[field.field.name]}
            />
          </SingleElementFormV2>
        );
      case "textarea":
        return (
          <SingleElementFormV2
            disableHoverBg={disableHoverBg}
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              rules: [
                {
                  required: field.field.required,
                  message: field.field.required_message,
                },
              ],
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={<Typography.Text>{data?.[field.field.name]}</Typography.Text>}
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <Input.TextArea rows={field.field.rows} />
          </SingleElementFormV2>
        );
      case "timepicker":
        return (
          <SingleElementFormV2
            disableHoverBg={disableHoverBg}
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              rules: [
                {
                  required: field.field.required,
                  message: field.field.required_message,
                },
              ],
              getValueProps: (value) => ({
                value: value ? dayjs(value) : "",
              }),
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={
              <Typography.Text>
                {data?.[field.field.name] &&
                  dayjs(data?.[field.field.name]).format("HH:mm")}
              </Typography.Text>
            }
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <TimePicker format={"HH:mm"} />
          </SingleElementFormV2>
        );
      case "datepicker":
        return (
          <SingleElementFormV2
            disableHoverBg={disableHoverBg}
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              rules: [
                {
                  required: field.field.required,
                  message: field.field.required_message,
                },
              ],
              getValueProps: (value) => ({ value: value ? dayjs(value) : "" }),
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={
              <Typography.Text>
                {data?.[field.field.name] &&
                  dayjs(data?.[field.field.name]).format(
                    translate("global.date_format")
                  )}
              </Typography.Text>
            }
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <DatePicker
              format={translate("global.date_format")}
              style={{ width: "100%" }}
            />
          </SingleElementFormV2>
        );
      case "currency":
        return (
          <SingleElementFormV2
            disableHoverBg={disableHoverBg}
            state={
              activeForm && activeForm === field.field.name
                ? "form"
                : data && data[field.field.name]
                ? "view"
                : "empty"
            }
            itemProps={{
              name: ["custom_fields", field.field.name],
              label: field.field.label,
              rules: [
                {
                  required: field.field.required,
                  message: field.field.required_message,
                },
              ],
              initialValue: data?.[field.field.name],
            }}
            useFormProps={{
              resource: "product_items",
              id: productId,
            }}
            formProps={{
              onFinish(values) {
                values.custom_fields = {
                  ...data,
                  ...values.custom_fields,
                };
                updateSingle({
                  resource: "product_items",
                  id: productId as BaseKey,
                  values,
                });
              },
            }}
            view={
              <Typography.Text>
                {data?.[field.field.name]}{" "}
                {field.field.show_symbol
                  ? field.field.currency_symbol
                  : field.field.currency_code}
              </Typography.Text>
            }
            icon={<RightCircleOutlined style={{ color: "#999" }} />}
            iconStyle={{ alignSelf: "start", marginTop: 1 }}
            onClick={() => setActiveForm?.(field.field.name)}
            onUpdate={() => setActiveForm?.(undefined)}
            onCancel={() => setActiveForm?.(undefined)}
            onDelete={() => {
              delete data?.[field.field.name];
              updateSingle({
                resource: "product_items",
                id: productId as BaseKey,
                values: { custom_fields: data ?? {} },
              });
            }}
          >
            <InputNumber
              precision={field.field.decimal_places}
              addonAfter={
                field.field.show_symbol
                  ? field.field.currency_symbol
                  : field.field.currency_code
              }
              style={{ width: "100%" }}
            />
          </SingleElementFormV2>
        );
      default:
        return null;
    }
  };
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = fields.findIndex((field) => field.id === active.id);
      const newIndex = fields.findIndex((field) => field.id === over.id);
      setFields(arrayMove(fields, oldIndex, newIndex));
    }
  };

  const handleUpdateField = (field: IField) => {
    const updatedFields = fields.map((f) => {
      if (f.id === field.id) {
        return field;
      }
      return f;
    });
    setFields(updatedFields);
    setDrawerOpen(undefined);
  };
  const handleDeleteField = (id: string) => {
    const updatedFields = fields.filter((f) => f.id !== id);
    setFields(updatedFields);
  };
  return (
    <div>
      {mode === "edit" && (
        <CustomFormSingle
          open={!!drawerOpen}
          onClose={() => setDrawerOpen(undefined)}
          field={drawerOpen}
          handleUpdateField={handleUpdateField}
        />
      )}
      {mode === "view" && (
        <Form
          form={form}
          onFinish={(value) => {
            const updatedValue = value;
            Object.keys(value).forEach((element) => {
              if (value?.[element]?.["$isDayjsObject"]) {
                updatedValue[element] = value[element].format(
                  translate("global.date_format")
                );
              }
            });
            saveData?.(updatedValue);
          }}
          onChange={() => onChange?.(form.getFieldsValue())}
          layout="vertical"
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row gutter={[10, 20]}>
              {fields.map((field) => (
                <Col span={field.col_span} key={field.id}>
                  {renderField(field)}
                </Col>
              ))}
            </Row>
            <Button type="primary" htmlType="submit">
              {translate("custom_fields.update")}
            </Button>
          </Space>
        </Form>
      )}
      {mode === "viewWithoutForm" && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row gutter={[10, 20]}>
            {fields.map((field) => (
              <Col span={field.col_span} key={field.id}>
                {renderField(field)}
              </Col>
            ))}
          </Row>
        </Space>
      )}
      {mode === "pureView" && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Row gutter={[10, 0]}>
            {fields
              .filter(({ field }: any) => field?.name in (data ?? []))
              .map((field) => (
                <Col span={24} key={field.id}>
                  {renderFieldView(field)}
                </Col>
              ))}
          </Row>
        </Space>
      )}
      {mode === "edit" && (
        <>
          {fields && (
            <DndContext
              onDragEnd={handleDragEnd}
              modifiers={[restrictToWindowEdges]}
            >
              <SortableContext items={fields?.map((field: any) => field.id)}>
                <Form layout="vertical">
                  <Row gutter={16}>
                    {fields?.map((field) => (
                      <Col span={field.col_span} key={field.id}>
                        <SortableItem id={field.id}>
                          <Flex
                            justify="space-between"
                            align={field?.type == "text" ? "center" : "end"}
                            style={{ flex: 1 }}
                            gap={10}
                          >
                            {renderField(field)}
                            <Space>
                              <Button
                                icon={<EditOutlined />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDrawerOpen(field);
                                }}
                              />
                              <Popconfirm
                                title={translate("custom_fields.delete")}
                                description={translate(
                                  "custom_fields.delete_description"
                                )}
                                placement="leftBottom"
                                onConfirm={() => handleDeleteField(field.id)}
                                okText={translate("buttons.yes")}
                                cancelText={translate("buttons.no")}
                              >
                                <Button danger icon={<DeleteOutlined />} />
                              </Popconfirm>
                            </Space>
                          </Flex>
                        </SortableItem>
                      </Col>
                    ))}
                  </Row>
                </Form>
              </SortableContext>
            </DndContext>
          )}
          <Dropdown
            menu={{ items: fieldOptions, onClick: handleAddField }}
            placement="top"
          >
            <Button>{translate("buttons.add_new_field")}</Button>
          </Dropdown>
        </>
      )}
    </div>
  );
};

export default CustomFormBuilderPage;
