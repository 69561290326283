import Placeholder from "@tiptap/extension-placeholder";
import { EditorProvider } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Input } from "antd";
import { useTranslate } from "@refinedev/core"; // Beispiel für Übersetzungsfunktion
import "./index.css";

type Props = {
    onUpdateProps: (value: any) => void;
};

export default function TipTapEditor({ onUpdateProps }: Props) {
    const translate = useTranslate(); // Verwende den useTranslate-Hook für Übersetzungen

    const extensions = [
        StarterKit,
        Placeholder.configure({
            emptyEditorClass: "is-editor-empty",
            placeholder: translate("activity.placeholder"), // Dynamischer Placeholder-Text
        }),
    ];

    return (
        <>
            <Input type="hidden" />
            <EditorProvider
                extensions={extensions}
                onUpdate={(value) => onUpdateProps(value)}
            />
        </>
    );
}
