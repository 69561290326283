import React from 'react';
import { Space, Row, Tag, Typography } from 'antd';
import {useTranslate} from "@refinedev/core";

const ContactAddress = ({ contactAddress }) => {
  const translate = useTranslate();

  if (!contactAddress) return null;

  const renderAddressDetails = () => {

    switch (contactAddress?.type) {
      case "PO_Box_Address":
        return <Row>{contactAddress?.street}</Row>;

      case "Packing_Station":
        return (
          <>
            {contactAddress?.address_supplement_1 && (
              <Row>
                {translate("contacts.post_number")}: {contactAddress?.address_supplement_1}
              </Row>
            )}
            <Row>
              {translate("contacts.packstation")}: {contactAddress?.street}
            </Row>
          </>
        );

      default:
        return (
          <>
            {contactAddress?.address_supplement_1 && (
              <Row>{contactAddress?.address_supplement_1}</Row>
            )}
            <Row>{contactAddress?.street}</Row>
          </>
        );
    }
  };

  return (
    <Space size="middle" align="start">
      <Typography.Text>
        {renderAddressDetails()}
        <Row>
          {contactAddress?.zip} {contactAddress?.city}
        </Row>
        {contactAddress?.country && (
          <Row>{translate(`countries.${contactAddress?.country?.toUpperCase()}`)}</Row>
        )}
      </Typography.Text>
      {contactAddress?.label && <Tag>{contactAddress?.label}</Tag>}
    </Space>
  );
};

export default ContactAddress;