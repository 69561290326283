import {
  CanAccess,
  useDelete,
  useNavigation,
  useShow,
  useTranslate, useUpdate,
} from "@refinedev/core";
import React, {PropsWithChildren, useEffect, useState} from "react";

import {
  ArrowRightOutlined, CheckCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {useForm} from "@refinedev/antd";
import {
  Button, Card,
  Col, Descriptions,
  Divider,
  Drawer,
  Flex,
  Form,
  Input,
  List, Modal,
  Popconfirm, Progress,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import {Text} from "../../../components";
import IssuesTable from "../../../components/shop/issues/IssuesTable";
import InvoiceTable from "../../../components/shop/product-items";
import SinglePhase from "../../../components/shop/single-phase";
import {useUser} from "../../../contexts/ContextProvider";
import {BillingType} from "../../../interfaces/phase.interface";
import ContactInformation from "./ContactInformation";
import EmailInformation from "./EmailInformation";
import OrderInformation from "./OrderInformation";
import ContactDetails from "../../../components/contacts/ContactDetails";
import ContactAddress from "../../../components/contacts/ContactAddress";
import {numberToCurrency, supabaseClient} from "../../../utility";
import ContactDisplay from "../../../components/invoices/ContactDisplay";
import FileDisplay from "../../../components/invoices/FileDisplay";
import SubscriptionPhaseShowStartEnd from "../../../components/shop/orders/SubscriptionPhaseShowStartEnd";
import HorizontalItemDisplay from "../../../components/common/HorizontalItemDisplay";
import {URL} from "react-dnd-html5-backend/dist/NativeTypes";
import {ContactProvider} from "../../../contexts/ContactProvider/useContactContext";
import OverviewSection from "../../../components/contacts/OverviewSection";
import {Simulate} from "react-dom/test-utils";
import suspend = Simulate.suspend;

const OrdersShow: React.FC<PropsWithChildren> = ({children}) => {
  const [deliveryContact, setDeliveryContact] = useState<string | undefined>(
    undefined
  );
  const [deliveryContactAddress, setDeliveryContactAddress] = useState<
    string | undefined
  >(undefined);

  const [showContact, setShowContact] = useState<number>();
  const [showInvoice, setShowInvoice] = useState<any>();
  const [editOrder, setEditOrder] = useState<any>();
  const [editSubscription, setEditSubscription] = useState<any>();

  const params = useParams();
  const {currentTeam} = useUser();
  const {list} = useNavigation();
  const {mutate: deleteMutation} = useDelete();
  const { mutate } = useUpdate();
  const {query} = useShow({
    resource: "orders",
    id: params.subscriptionId,
    meta: {
      select:
        "*, invoice_contact(*, phone_numbers(*)), invoice_contact_address(*), delivery_contact(*), delivery_contact_address(*), product(type), invoices(*, parent_invoice:parent(*)), product_items(*)",
    },
  });
  const {formProps, form} = useForm({
    action: "edit",
    redirect: false,
    autoSave: {
      enabled: true,
      invalidateOnUnmount: true,
    },
    onMutationSuccess: () => {
      query.refetch();
    },
    invalidates: ["all"],
    mutationMode: "pessimistic",
  });
  const translate = useTranslate();

  const closeModal = () => {
    list("orders", "replace");
  };

  const {data, isLoading, isError} = query;
  useEffect(() => {
    setDeliveryContact(data?.data?.delivery_contact?.id);
    setDeliveryContactAddress(data?.data?.delivery_contact_address?.id);
  }, [
    data?.data?.delivery_contact?.id,
    data?.data?.delivery_contact_address?.id,
  ]);
  if (isError) {
    closeModal();
    return null;
  }
  if (isLoading && !currentTeam?.account_id) {
    return (
      <Drawer
        open
        styles={{
          body: {
            background: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Spin/>
      </Drawer>
    );
  }

  const {
    id,
    name,
    components,
    created_at,
    type,
    shipping_type,
    start_date,
    next_notice_date,
    contract_end_date,
    invoice_contact,
    issues_left,
    invoice_contact_address,
    delivery_contact,
    delivery_contact_address,
    customized,
    product,
    billed_until,
    order_number,
    source_id,
    suspended,
    product_items
  } = data?.data ?? {};

  function handlePhaseDelete(index: number) {
    const changedPhase = components.filter(
      (_item, indexFilter) => index != indexFilter
    );
    form.setFieldValue("components", changedPhase);
    form.submit();
  }

  function handleChangesOnPhases(phase: any, index: number) {
    const changedPhase = components.map((item: any, indexPhase: number) => {
      if (indexPhase == index) {
        item = phase;
      }
      return item;
    });

    form.setFieldValue("components", changedPhase);
  }

  const handleAddNewPhase = () => {
    const lastPhase = components[components.length - 1];
    let newPhase;
    if (lastPhase)
      newPhase = {
        ...lastPhase,
        key: lastPhase.key + 1,
        start: lastPhase.start,
        end: lastPhase.end,
        billing_interval: {...lastPhase.billing_interval},
        products: lastPhase?.products?.map((item: any) => ({...item})),
      };
    else
      newPhase = {
        key: 1,
        billing_interval: {
          interval: "month",
          interval_count: 3,
        },
        start: dayjs().format(translate("global.date_format")),
        end: dayjs().format(translate("global.date_format")),
        products: [],
      };
    const updatedPhases = [...components, newPhase];

    form.setFieldValue("components", updatedPhases);
    form.submit();
  };

  const handleUnsuspend = () => {
    mutate(
      {
        resource: "orders", // Passen Sie den Ressourcennamen an
        id: id, // ID des Datensatzes
        values: { suspended: false }, // Die neuen Werte
      },
      {
        onSuccess: () => {
          console.log("Erfolgreich aktualisiert!");
        },
        onError: (error) => {
          console.error("Fehler:", error);
        },
      }
    );
  };

  const statusColors: Record<string, string> = {
    unfinished: "red",
    finished: "green",
    unpaid: "orange",
    overdue: "red",
    paid: "green",
    canceled: "grey",
    partially_paid: "blue",
  };


  const getCurrentPhase = (components) => {
    if (!Array.isArray(components)) {
      return null;
    }

    const today = new Date();
    return components.filter((phase) => {
      const startDate = new Date(phase.start_date);
      const endDate = new Date(phase.end_date);
      return today >= startDate && today <= endDate;
    })[0] || null;
  };


  const showProductsJson = (phases) => {
    if (!phases?.products) return [];

    // Helper function to calculate price with tax and discount
    const calculatePrice = (product) => {
      const discountFactor = product.discount ? (1 - product.discount / 100) : 1;
      const netPrice = product.price_per_item * product.quantity * discountFactor;
      const grossPrice = netPrice * (1 + product.tax_rate / 100);
      return Math.round(grossPrice * 100) / 100; // Round to two decimal places
    };

    // Helper function to format billing interval
    const formatBillingInterval = (billingInterval) => {
      const {interval, interval_count} = billingInterval;
      if (interval_count === 1) {
        return translate(`subscriptions.list.${interval}`);
      } else {
        return `${translate('subscriptions.list.every')} ${interval_count} ${translate(`subscriptions.list.${interval}s`)}`;
      }
    };

    // Map products to desired format
    return phases.products.map((product, index) => {
      const quantityPrefix = product.quantity > 1 ? `${product.quantity} x ` : "";
      const discountedName = product.discount
        ? `${quantityPrefix}${product.name} (-${product.discount}%)`
        : `${quantityPrefix}${product.name}`;

      return {
        key: `${index + 1}`,
        name: discountedName,
        price: numberToCurrency(calculatePrice(product)),
        billing_interval: formatBillingInterval(phases.billing_interval),
      };
    });
  };


  const subscriptionProductTableColumns = [
    {
      title: translate('product_items.show.title'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: translate('product_items.show.billing_frequency'),
      dataIndex: 'billing_interval',
      key: 'price',
    },
    {
      title: translate('payments.amount'),
      dataIndex: 'price',
      key: 'price',
    },
  ];

  const subscriptionProductTableData = showProductsJson(getCurrentPhase(components));

  const showProducts = (phases) => {
    let result = "";
    phases?.products?.map((product, index) => (
      // add discount
      result +=
        product.quantity + " x " +
        product.name +
        (product.discount ? " (-" + product.discount + "%)" : "") +
        (index < phases.products.length - 1 ? ", " : "")
    ))
    return result;
  }


  const currentPhase = getCurrentPhase(components);

  const invoice_number = showInvoice?.parent_invoice?.invoice_number || showInvoice?.invoice_number;

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>

        <Form {...formProps} layout="vertical">

          <Drawer
            width={"50%"}
            open={showContact != undefined}
            onClose={() => setShowContact(undefined)}
            title={translate("contacts.contact")}
          >
            {showContact && (
              <ContactProvider contactId={showContact}>
                <OverviewSection />
              </ContactProvider>
            )}
          </Drawer>

          <Modal
            title="Edit Order"
            width={"70%"}
            style={{minWidth: "840px"}}
            open={editOrder != undefined}
            footer={null}
            onCancel={() => setEditOrder(undefined)}
          >
            <InvoiceTable
              initialValues={components?.products}
              initialShipping={components?.shipping}
            />

          </Modal>

          <Modal
            title="Edit Subscription"
            width={"70%"}
            style={{minWidth: "900px"}}
            open={editSubscription != undefined}
            footer={null}
            onCancel={() => setEditSubscription(undefined)}
          >
            <List
              dataSource={components}
              renderItem={(phase: any, index: number) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    title={
                      <SubscriptionPhaseShowStartEnd
                        key={index}
                        item={phase}
                        index={index}
                        handlePhaseDelete={handlePhaseDelete}
                      />
                    }
                    description={
                      <>
                        <SinglePhase
                          handleChange={(current) =>
                            handleChangesOnPhases(current, index)
                          }
                          handlePhaseDelete={() => handlePhaseDelete(index)}
                          data={phase}
                          totalPhases={components?.length}
                          index={index}
                          isSubscription
                        />
                      </>
                    }
                  />
                </List.Item>
              )}
            />

            <Button
              type="link"
              icon={<PlusOutlined/>}
              onClick={handleAddNewPhase}
              style={{fontSize: "0.80rem", fontWeight: 500, padding: 7}}
            >
              {translate("subscription_products.list.add")}
            </Button>

          </Modal>

          <Drawer
            width={"600"}
            open={showInvoice != undefined}
            onClose={() => setShowInvoice(undefined)}
            title={translate("billing_details.title")}
          >
            <Space direction={"vertical"} style={{width: "100%"}}>
              <Flex justify={"space-between"}>
                <Typography.Text strong>Erstellt:</Typography.Text>
                <Typography.Text>
                  {dayjs(showInvoice?.created_at).format(translate("global.date_time_format"))}
                </Typography.Text>
              </Flex>

              <Flex justify={"space-between"}>
                <Typography.Text strong>Status:</Typography.Text>
                <Typography.Text>
                  {translate(`billing_details.status.${showInvoice?.status}`)}
                </Typography.Text>
              </Flex>

              <Flex justify={"space-between"}>
                <Typography.Text strong>
                  {showInvoice?.amount_paid == 0 && (
                    <>
                      {translate(`billing_details.status.open`)}:
                    </>
                  )}
                  {showInvoice?.amount_paid > 0 && showInvoice?.amound_paid != showInvoice?.amount && (
                    <>
                      {translate(`billing_details.status.partially_paid`)}:
                    </>
                  )}
                </Typography.Text>
                <Typography.Text>
                  {showInvoice?.amount_paid == 0 && (
                    <>
                      {numberToCurrency(showInvoice?.amount || 0)}
                    </>
                  )}
                  {showInvoice?.amount_paid > 0 && showInvoice?.amound_paid != showInvoice?.amount && (
                    <>
                      {`${numberToCurrency(showInvoice?.amount_paid || 0)} von ${numberToCurrency(showInvoice?.amount || 0)}`}
                    </>
                  )}
                </Typography.Text>
              </Flex>

              {showInvoice?.shipping_conditions?.shippingType && (
                <Flex justify={"space-between"}>
                  <Typography.Text strong>{translate(`subscriptions.fields.execution_date_${showInvoice?.shipping_conditions?.shippingType}`)}:</Typography.Text>
                  <Typography.Text>
                    {dayjs(showInvoice?.shipping_conditions?.shippingDate).format(translate("global.date_format"))}
                    {(showInvoice?.shipping_conditions?.shippingType == "serviceperiod" && showInvoice?.shipping_conditions?.shippingEndDate) && (
                      <>
                        {" "}- {dayjs(showInvoice?.shipping_conditions?.shippingEndDate).format(translate("global.date_format"))}
                      </>
                    )}
                  </Typography.Text>
                </Flex>
              )}

              {invoice_number && (
                <Flex justify={"space-between"}>
                  <Typography.Text strong>{translate("settings.invoices.fields.invoice_number")}:</Typography.Text>
                  <Typography.Text>
                    {invoice_number}
                  </Typography.Text>
                </Flex>
              )}

              <Divider/>

            </Space>

            <HorizontalItemDisplay
              items={[
                {
                  label: translate("billing_details.recipient"),
                  value:
                    <>
                      <ContactDetails
                        contact={invoice_contact}
                        address={invoice_contact_address}
                      />
                      <Typography>{invoice_contact?.email}</Typography>
                    </>,
                  onClick: () => setShowContact(showInvoice?.contact)
                },
              ]}
            />

            <Divider/>

            <FileDisplay
              label={translate("files.files")}
              invoice_number={invoice_number}
            />

          </Drawer>

          <Drawer
            open
            onClose={() => closeModal()}
            title={
              <Flex align={"center"} gap={12} justify={"space-between"}>
                {type == "simple" && (
                  <>
                    {translate(`orders.type.${type}`)}
                  </>
                )}
                {type == "subscription" && (
                  <Space size={"middle"}>
                    <Flex gap={8} align={"center"}>
                      {product_items.title} - {name}
                      {suspended && (
                        <Popconfirm
                          overlayStyle={{ maxWidth: 400 }}
                          title={translate("orders.suspended.title")}
                          description={translate("orders.suspended.description")}
                          onConfirm={() => handleUnsuspend()}
                          okText={translate("orders.suspended.resume")}
                          okButtonProps={{ type: "primary", style: { backgroundColor: "green"} }}
                          cancelText={translate("orders.suspended.cancel")}
                        >
                          <Tag
                            style={{ cursor: "pointer" }}
                            color="#FACA15"
                          >
                            {translate("orders.suspended.status")}
                          </Tag>
                        </Popconfirm>
                      )}
                    </Flex>
                    {/*<span style={{ fontWeight: "normal" }}>*/}
                    {/*  ({translate(`orders.type.${type}`)})*/}
                    {/*</span>*/}
                  </Space>
                )}
                <span style={{color: "#787878"}}>
                  {order_number}
                    {source_id && (
                      <>
                        {" "}({source_id})
                      </>
                    )}
                </span>

              </Flex>
            }
            width={"70%"}
            footer={
              <Flex justify="space-between" align="center">
                <Text className="ant-text tertiary">
                  {translate("subscription_products.fields.created_at")}{" "}
                  {dayjs(created_at).format(
                    translate("page.settings.date_format")
                  )}{" "}
                  {translate("page.settings.clock")}
                </Text>
                <div>
                  <Popconfirm
                    title={translate("subscriptions.buttons.delete.title")}
                    description={translate(
                      "subscriptions.buttons.delete.description"
                    )}
                    onConfirm={() => {
                      if (id) {
                        deleteMutation(
                          {
                            id,
                            resource: "orders",
                          },
                          {
                            onSuccess: () => closeModal(),
                          }
                        );
                      }
                    }}
                    okText={translate("buttons.yes", "Yes")}
                    cancelText={translate("buttons.no", "No")}
                  >
                    <Button type="link" danger icon={<DeleteOutlined/>}>
                      {translate("subscriptions.buttons.delete.title")}
                    </Button>
                  </Popconfirm>
                  <Button onClick={() => form.submit()} type="primary">
                    Save
                  </Button>
                </div>
              </Flex>
            }
          >

            <HorizontalItemDisplay
              columns={2}
              items={
                [
                  {
                    label: "Auftraggeber",
                    value:
                      <>
                        <ContactDetails
                          contact={invoice_contact}
                          address={invoice_contact_address}
                        />
                        <Typography>{invoice_contact?.email}</Typography>
                      </>,
                    onClick: () => setShowContact(invoice_contact?.id)
                  },
                  ...(delivery_contact?.id && invoice_contact?.id !== delivery_contact?.id ? [
                    {
                      label: "Abweichende Lieferanschrift",
                      value: <ContactDetails
                        contact={delivery_contact}
                        address={delivery_contact_address}
                      />,
                      onClick: () => setShowContact(delivery_contact?.id)
                    },
                  ] : []),
                ]
              }/>


            <Row gutter={48}>
              <Col span={16}>

                <HorizontalItemDisplay
                  title={translate("orders.show.title")}
                  columns={2}
                  items={
                    [
                      {
                        label: translate(`subscriptions.fields.execution_date`),
                        value: dayjs(start_date).format(translate("global.date_format"))
                      },
                      ...((type == "simple" && shipping_type) ? [
                        {
                          label: translate(`subscriptions.fields.execution_date_${shipping_type}`),
                          value: <div>
                            {shipping_type == "service" || shipping_type == "delivery" && (
                              <>
                                {dayjs(start_date).format(translate("global.date_format"))}
                              </>
                            )}
                          </div>
                        }
                      ] : []),
                      ...(type == "subscription" ? [
                        {
                          label: translate(`orders.show.current_phase`),
                          value: <div>
                            {currentPhase?.start_date && currentPhase?.end_date && (
                              <>
                                {dayjs(currentPhase?.start_date).format(translate("global.date_format"))} - {dayjs(currentPhase?.end_date).format(translate("global.date_format"))}
                              </>
                            )}
                            {currentPhase?.start_date && !currentPhase?.end_date && (
                              <>
                                {dayjs(currentPhase?.start_date).format(translate("global.date_format"))}
                              </>
                            )}
                          </div>
                        },
                        {
                          label: translate("orders.termination_date"),
                          value: dayjs(next_notice_date).format(
                            translate("page.settings.date_format")
                          )
                        },
                      ] : []),
                      ...(type == "subscription" ? [
                        {
                          label: translate("orders.billed_until"),
                          value: billed_until > start_date ? dayjs(billed_until).format(
                            translate("page.settings.date_format")
                          ) : "-"
                        },
                        {
                          label: translate("subscriptions.issues_left.title"),
                          value: issues_left || 0
                        }
                      ] : []),
                    ]
                  }/>


                {type == "subscription" && (
                  <Flex vertical gap={16}>

                    <Table
                      scroll={{ x: 'max-content' }}
                      dataSource={subscriptionProductTableData}
                      columns={subscriptionProductTableColumns}
                      pagination={false}
                    />
                    <div>
                      <Button
                        onClick={() => setEditSubscription(true)}
                      >
                        Edit Subscription
                      </Button>
                    </div>
                  </Flex>
                )}

                {type == "simple" && (
                  <Space direction={"vertical"}>
                    {showProducts(components)}
                    <Button
                      onClick={() => setEditOrder(true)}
                    >
                      Details
                    </Button>
                  </Space>
                )}

                <Space direction="vertical" size={"large"} style={{width: "100%"}}>

                  {product?.type == "issue" && id && (
                    <>
                      <Typography.Title
                        level={5}
                        style={{
                          fontSize: "1.02em",
                          marginBottom: 5,
                          marginTop: 20,
                        }}
                      >
                        {translate("subscriptions.issues_left.transactions")}
                      </Typography.Title>
                      <IssuesTable
                        orderId={id}
                        totalIssuesLeft={issues_left ?? 0}
                        contact_id={invoice_contact.id}
                        components={components}
                      />
                      {/* <Table
                  dataSource={issuesLeftWithProductNames}
                  columns={columns}
                  rowKey="productId"
                  pagination={false}
                /> */}
                    </>
                  )}
                </Space>


              </Col>
              <Col span={8}>

                <Divider
                  plain
                  orientationMargin={0}
                  orientation={"left"}
                  >
                  <Typography.Text strong>
                    {translate("commerce.invoices")}
                  </Typography.Text>
                </Divider>

                {data?.data?.invoices?.length > 0 ? (
                  <>
                    {data?.data?.invoices?.map((invoice: any) => (
                      <Flex
                        onClick={() => setShowInvoice(invoice)}
                        justify={"space-between"}
                        key={invoice.id}
                        title={invoice.name}
                        style={{
                          marginBottom: 16,
                          padding: "6px 16px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          backgroundColor: "#f0f0f0",
                          transition: "background-color 0.3s, transform 0.2s",
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#e4e5e6"}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#f0f0f0"}
                      >
                        <Typography.Text>
                          {dayjs(invoice.created_at).format(
                            translate("global.date_format")
                          )}
                        </Typography.Text>
                        <Typography.Text style={{marginLeft: 8}}>
                          {numberToCurrency(invoice.amount)}
                        </Typography.Text>
                      </Flex>
                    ))}
                  </>
                ) : (
                  <Typography.Text type="secondary">
                    {translate("commerce.no_invoices")}
                  </Typography.Text>
                )}


              </Col>
            </Row>

          </Drawer>
          {children}
        </Form>
      </>
    </CanAccess>
  );
};

export default OrdersShow;
