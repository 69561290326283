import { DeleteOutlined } from "@ant-design/icons";
import { List, useModalForm, useTable } from "@refinedev/antd";
import { useInvalidate, useNotification, useTranslate } from "@refinedev/core";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { useState } from "react";
import { useContactContext } from "../../contexts/ContactProvider";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";
import { getPaymentMethodErrorMessage } from "../../utility/errorMessages";
import { isValidIBAN, isValidBIC } from "../../utility/validation";

function CreateOrEditPaymentMethod({
  open,
  payment_method,
  onClose,
  contactId,
}: {
  open?: boolean;
  payment_method?: any;
  onClose: () => void;
  contactId: any;
}) {
  const { personalAccount, currentTeam } = useUser();
  const translate = useTranslate();
  const {
    formProps: { onFinish, ...formProps },
    modalProps,
  } = useModalForm({
    resource: "payment_methods",
    action: payment_method?.id ? "edit" : "create",
    id: payment_method?.id,
    onMutationSuccess: () => {
      onClose();
    },
  });

  return (
    <Modal
      {...modalProps}
      open={open}
      onCancel={onClose}
      title={
        payment_method?.id
          ? translate("payments.edit_payment_method", "Edit Payment Method")
          : translate("payments.create_payment_method", "Create Payment Method")
      }
    >
      <Form
        {...formProps}
        onFinish={(values) =>
          onFinish?.({
            ...values,
            created_by: personalAccount.account_id,
            account: currentTeam?.account_id,
            contact: contactId,
          })
        }
        layout="vertical"
      >
        <Form.Item
          name={"type"}
          label={translate("payments.payment_type")}
          initialValue={"sepa_directdebit"}
        >
          <Select
            options={[
              {
                label: translate(
                  "billing_details.payment_methods.sepa_directdebit",
                  "SEPA Direct Debit"
                ),
                value: "sepa_directdebit",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label={translate("payments.account_name")}
          rules={[
            {
              required: true,
              message: translate("payments.validation.enter_account_name"),
            },
            {
              min: 2,
              message: translate("payments.validation.account_name_at_least_2"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="iban"
          label="IBAN"
          normalize={(val) => val.toUpperCase()}
          rules={[
            {
              required: true,
              message: translate("payments.validation.enter_iban"),
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  // This condition is not strictly needed since `required: true` already handles it.
                  return Promise.resolve();
                }

                // Validate IBAN with comprehensive function
                if (!isValidIBAN(value)) {
                  return Promise.reject(
                    new Error(translate("payments.validation.enter_valid_iban"))
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="bic"
          label="BIC"
          normalize={(val) => val.toUpperCase()}
          rules={[
            {
              required: true,
              message: translate("integrations.iban.bic.error"),
            },
            {
              validator: (_, value) => {
                if (!value) {
                  // This condition is not strictly needed since `required: true` already handles it.
                  return Promise.resolve();
                }

                // Validate BIC with comprehensive function
                if (!isValidBIC(value)) {
                  return Promise.reject(
                    new Error(translate("payments.validation.enter_valid_bic"))
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="mandate_reference" label="Mandate ID">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default function PaymentMethods() {
  const [openCreate, setOpenCreate] = useState<{
    open: boolean;
    payment_method?: any;
  } | null>(null);
  const translate = useTranslate();
  const { id } = useContactContext();
  const { open } = useNotification();
  const invalidate = useInvalidate();
  const { tableProps, tableQuery } = useTable({
    resource: "payment_methods",
    filters: {
      permanent: [{ field: "contact", operator: "eq", value: id ?? 0 }],
    },
    syncWithLocation: false,
    sorters: {
      initial: [
        { field: "expiration_date", order: "desc" },
        { field: "created_at", order: "desc" },
      ],
    },
  });
  const handleExpire = async (
    payment_method_id: any,
    apiKey: "set-expiration-date" | "delete-item" = "set-expiration-date"
  ) => {
    try {
      const { data, error } = await supabaseClient.functions.invoke(
        `payment-methods?api-key=${apiKey}`,
        {
          body: { contact_id: id, payment_method_id },
          method: apiKey == "delete-item" ? "DELETE" : undefined,
        }
      );
      if (error) {
        open?.({
          description: translate("notifications.error"),
          message: getPaymentMethodErrorMessage(
            translate,
            error.context?.status ?? 500
          ),
          type: "error",
        });
      } else if (data) {
        open?.({
          description: translate("notifications.success"),
          message: translate(
            `notifications.${
              apiKey == "delete-item" ? "deleteSuccess" : "editSuccess"
            }`
          ),
          type: "success",
        });
        invalidate({
          invalidates: ["resourceAll"],
          resource: "payment_methods",
        });
        await tableQuery.refetch();
      }
    } catch (error: any) {
      open?.({
        description: translate("notifications.error"),
        message: getPaymentMethodErrorMessage(
          translate,
          error.context?.status ?? 500
        ),
        type: "error",
      });
    }
  };

  return (
    <>
      <List
        title={false}
        breadcrumb={false}
        createButtonProps={{ onClick: () => setOpenCreate({ open: true }) }}
      >
        <Table
          {...tableProps}
          className="clickableRow"
          onRow={(data) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                event.preventDefault();
                setOpenCreate({ open: true, payment_method: data });
              },
            };
          }}
          columns={[
            {
              title: translate("payments.payment_type"),
              dataIndex: "type",
              key: "type",
              render: (text) => <Tag>{text}</Tag>,
            },
            { title: "IBAN", dataIndex: "iban", key: "iban" },
            { title: "BIC", dataIndex: "bic", key: "bic" },
            {
              title: translate("payments.created_at"),
              key: "created_at",
              dataIndex: ["created_at"],

              render: (value) => (
                <>
                  {new Date(value).toLocaleDateString(
                    translate("pipelines.date_locale")
                  )}
                </>
              ),
            },
            {
              title: translate("payments.expiration_date"),
              key: "expiration_date",
              dataIndex: ["expiration_date"],

              render: (value) => (
                <>
                  {value &&
                    new Date(value).toLocaleDateString(
                      translate("pipelines.date_locale")
                    )}
                </>
              ),
            },
            {
              key: "action",
              title: translate("payments.actions"),
              render: (_text: any, record: any) => (
                <Space>
                  {!record.expiration_date && (
                    <Button
                      onClick={async (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        await handleExpire(record.id);
                      }}
                      type="primary"
                    >
                      {translate(
                        "billing_details.payment_methods.expire",
                        "Expire"
                      )}
                    </Button>
                  )}
                  <Popconfirm
                    title="Delete Block"
                    description="Are you sure to delete this payment?"
                    onConfirm={async (event) => {
                      event?.preventDefault();
                      event?.stopPropagation();
                      await handleExpire(record.id, "delete-item");
                    }}
                    onCancel={(event) => {
                      event?.preventDefault();
                      event?.stopPropagation();
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Space
                      style={{ color: "red" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <DeleteOutlined />
                    </Space>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </List>

      {openCreate?.open && (
        <CreateOrEditPaymentMethod
          open={openCreate?.open}
          onClose={() => setOpenCreate(null)}
          contactId={id}
          payment_method={openCreate?.payment_method}
        />
      )}
    </>
  );
}
