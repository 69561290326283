import { MailOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import { useList, useTranslate } from "@refinedev/core";
import { Button, Flex, Input, Table, Tooltip, Typography } from "antd";
import type { ColumnType } from "antd/es/table";
import type { FilterDropdownProps } from "antd/es/table/interface";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/ContextProvider";
import CreateOrEditInvoice from "./CreateOrEditInvoice";
import useInvoiceSummary from "./useInvoiceSummary";

export default function InvoiceTable({ contact }: { contact?: any }) {
  const [searchText, setSearchText] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const navigate = useNavigate();

  const { currentTeam } = useUser();
  const { data } = useList({
    resource: "revenue_overview",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const { invoiceSummary } = useInvoiceSummary(
    currentTeam?.account_id || "",
    contact?.id
  );
  const translate = useTranslate();
  const { i18n } = useTranslation();
  const { tableProps, setFilters, setCurrent } = useTable({
    resource: "invoices",
    meta: { select: "*,contact(*,payment_methods(*))" },
    syncWithLocation: false,
    filters: {
      permanent: [
        { field: "account", operator: "eq", value: currentTeam?.account_id },
        ...(contact?.id
          ? [{ field: "contact", operator: "eq" as const, value: contact?.id }]
          : []),
      ],
    },
    sorters: { initial: [{ field: "created_at", order: "desc" }] },
  });

  const { tableProps: forecasts } = useTable({
    resource: "forecasts",
    meta: { select: "*" },
    syncWithLocation: false,
    filters: {
      permanent: [
        ...(contact
          ? [{ field: "contact", operator: "eq" as const, value: contact?.id }]
          : []),
      ],
    },

    sorters: { initial: [{ field: "created_at", order: "desc" }] },
  });

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrent(1);

    setFilters?.(
      [
        {
          operator: "or",
          value: [
            {
              field: "amount",
              operator: "eq",
              value: isNaN(Number(value.replace(",", ".")))
                ? 0
                : Number(value.replace(",", ".")) * 100,
            },
            {
              field: "invoice_number",
              operator: "contains",
              value: value,
            },
          ],
        },
      ],
      "merge"
    );
  };
  const handleRowClick = (event: any, id: any) => {
    if (event.target.className !== "ant-checkbox-input") navigate("show/" + id);
  };
  const getColumnSearchProps = (dataIndex: string): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={translate(
            `settings.invoices.fields.search_${dataIndex}`
          )}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm({ closeDropdown: true })}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => confirm({ closeDropdown: true })}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translate("settings.invoices.fields.search_button")}
        </Button>
        <Button
          onClick={() => {
            if (clearFilters) {
              clearFilters();
              setSelectedKeys([]);
              confirm({ closeDropdown: true });
            }
          }}
          size="small"
          style={{ width: 110 }} // Increase the width
        >
          {translate("settings.invoices.fields.reset_button")}
        </Button>
      </div>
    ),

    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const recordValue = record[dataIndex];

      if (dataIndex === "amount") {
        return `$${recordValue.toFixed(2)}`.includes(String(value));
      }

      return String(recordValue)
        .toLowerCase()
        .includes(String(value).toLowerCase());
    },
  });

  const columns: ColumnType<any>[] = [
    {
      title: translate("settings.invoices.fields.invoice_date"),
      dataIndex: "date",
      key: "date",
      width: "10%",
      render: (date) => {
        return new Date(date).toLocaleString(i18n.language, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
      sorter: (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    },
    {
      title: translate("settings.invoices.fields.invoice_number"),
      dataIndex: "invoice_number",
      width: "10%",

      key: "invoice_number",
      ...getColumnSearchProps("invoice_number"),
    },
    {
      title: translate("settings.invoices.fields.amount"),
      dataIndex: "amount",
      width: "3%",

      key: "amount",
      render: (amount) =>
        `${Number(amount.toFixed(2)).toLocaleString(i18n.language ?? "de", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })} €`, // Place the symbol after the amount
      sorter: (a, b) => a.amount - b.amount,
      align: "right", // Align the column content to the right
      ...getColumnSearchProps("amount"),
    },

    {
      title: translate("settings.invoices.fields.description"),
      dataIndex: "line_items",
      width: "55%",

      key: "line_items",
      render: (line_items) => {
        if (!Array.isArray(line_items) || line_items.length === 0) {
          return "-"; // Fallback if line_items is not an array or empty
        }

        // Extract names and join with commas
        const productNames = line_items
          .map((line_item) => line_item.name || "")
          .filter(Boolean);
        const productNamesString = productNames.join(", ");

        const isLong = productNamesString.length > 40;
        const displayText = isLong
          ? `${productNamesString.slice(0, 37)}...`
          : productNamesString;

        return (
          <Tooltip title={isLong ? productNamesString : ""}>
            <span>{displayText}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status, record) => {
        let color: React.CSSProperties["color"] = "default";
        switch (status) {
          case "unfinished":
            color = "grey";
            break;
          case "finished":
            color = "blue";
            break;
          case "unpaid":
            color = "yellow";
            break;
          case "paid":
            color = "green";
            break;
          case "failed":
            color = "red";
            break;
          case "overdue":
            color = "red";
            break;
          case "partially_paid":
            color = "orange";
            break;
          case "deleted":
            color = "lightgray";
            break;
          default:
            break;
        }

        const dotStyle = {
          display: "inline-block",
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: color,
          marginRight: "8px",
        };

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Tooltip title={translate(`billing_details.status.${status}`)}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span style={dotStyle}></span>
              </div>
            </Tooltip>
            {record.send_by_hand && (
              <MailOutlined style={{ color: "#1890ff", fontSize: "16px" }} />
            )}
          </div>
        );
      },
    },
  ];
  const totalAmountOfForecasts = useMemo(() => {
    if (forecasts?.dataSource) {
      return forecasts.dataSource.reduce(
        (sum, record) => sum + (record.amount || 0),
        0
      );
    }
    return 0;
  }, [forecasts?.dataSource]);

  return (
    <div>
      <Flex align="center" style={{ marginBottom: 16 }}>
        {/* Search Input */}
        <Input
          placeholder={translate("settings.invoices.search_invoices")}
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: "300px" }}
        />
        {!contact && (
          <>
            {" "}
            <Typography.Text style={{ paddingLeft: "15px" }}>
              {translate("billing_details.status.unfinished")}{" "}
              <strong>
                {invoiceSummary.unfinished.count} (
                {invoiceSummary.unfinished.total.toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                })}
                )
              </strong>
            </Typography.Text>
            <Typography.Text style={{ paddingLeft: "15px" }}>
              {translate("billing_details.status.finished")}{" "}
              <strong>
                {invoiceSummary.finished.count} (
                {invoiceSummary.finished.total.toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                })}
                )
              </strong>
            </Typography.Text>
            <Typography.Text style={{ paddingLeft: "15px" }}>
              {translate("billing_details.status.unpaid")}{" "}
              <strong>
                {invoiceSummary.unpaid.count} (
                {invoiceSummary.unpaid.total.toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                })}
                )
              </strong>
            </Typography.Text>
          </>
        )}
      </Flex>

      {contact && (
        <Flex align="ceneter" justify="end" style={{ marginBottom: 20 }}>
          <Button
            icon={<PlusOutlined />}
            size="small"
            type="primary"
            onClick={() => setCreateModalOpen(true)}
          />
          <CreateOrEditInvoice
            open={createModalOpen}
            onClose={() => setCreateModalOpen(false)}
            contact={contact}
          />
        </Flex>
      )}
      <Table
        {...tableProps}
        columns={columns}
        rowClassName={"clickableRow"}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        onRow={(record) => {
          return {
            onClick: (event) => handleRowClick(event, record.id),
          };
        }}
      />
      {contact && (
        <>
          <Typography style={{ marginBottom: 16, paddingTop: 20 }}>
            {translate("commerce.forecasts.titleForInvoicesTab")}
          </Typography>
          <Typography style={{ marginTop: "16px" }}>
            {translate("settings.invoices.total_amount")}:{" "}
            <span style={{ fontWeight: "bold", color: "black" }}>
              {totalAmountOfForecasts.toLocaleString("en-US", {
                style: "currency",
                currency: "EUR",
              })}
            </span>
          </Typography>
        </>
      )}
      {contact && (
        <Table
          {...forecasts}
          rowClassName={"clickableRow"}
          columns={[
            {
              title: translate("commerce.forecasts.date"),
              dataIndex: "date_start",
              key: "date_start",
              render: (value) => (
                <span>
                  {new Date(value).toLocaleDateString(
                    translate("pipelines.date_locale"),
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    }
                  )}
                </span>
              ),
            },
            {
              title: translate("commerce.forecasts.title"),
              dataIndex: "title",
              key: "title",
              render: (value) => <span>{value}</span>,
            },
            {
              title: translate("commerce.forecasts.amount"),
              dataIndex: "amount",
              key: "amount",
              render: (value) => <span>{value}</span>,
            },
          ]}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
        />
      )}
    </div>
  );
}
