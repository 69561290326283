import {DownloadOutlined, FilePdfOutlined} from "@ant-design/icons";
import { Button, Flex, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";
import PdfViewer from "../common/PdfViewer";

const { Text } = Typography;

interface AddressDisplayProps {
  invoice_number?: React.ReactNode;
  label?: string;
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({
                                                         invoice_number,
                                                         label,
                                                       }) => {
  const [invoicePdfUrl, setInvoicePdfUrl] = useState<string | null>(null);
  const { currentTeam } = useUser();

  useEffect(() => {
    const fetchFileUrl = async () => {
      const filePath = `teams/${currentTeam?.account_id}/invoices/${invoice_number}.pdf`;

      // Check if the file exists
      const { data: fileExists, error: listError } = await supabaseClient.storage
        .from("files")
        .list(`teams/${currentTeam?.account_id}/invoices`, {
          limit: 1,
          search: `${invoice_number}.pdf`,
        });

      if (listError || !fileExists || fileExists.length === 0) {
        console.error("Error checking file existence:", listError);
        return;
      }

      // Generate a downloadable URL
      const { data, error: downloadError } = await supabaseClient.storage
        .from("files")
        .download(filePath);

      if (downloadError) {
        console.error("Error creating download URL:", downloadError);
        return;
      }

      const url = window.URL.createObjectURL(data);
      setInvoicePdfUrl(url);
    };

    fetchFileUrl();
  }, [currentTeam?.account_id, invoice_number]);

  async function downloadFile() {
    if (!invoicePdfUrl) {
      console.error("No file URL available for download.");
      return;
    }

    const a = document.createElement("a");
    a.href = invoicePdfUrl;
    a.download = `${invoice_number}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  if (!invoicePdfUrl) {
    return null;
  }

  return (
    <Flex vertical gap={16}>
      {label && <Text strong>{label}:</Text>}

      <Button onClick={downloadFile}>
        <Space>
          <DownloadOutlined />
          <FilePdfOutlined />
          <Typography.Text> {`${invoice_number}.pdf`}</Typography.Text>
        </Space>
      </Button>

      {/*<Text type="secondary">PDF URL: <a href={invoicePdfUrl} target="_blank" rel="noopener noreferrer">Open PDF</a></Text>*/}

      <PdfViewer fileURL={invoicePdfUrl} />

    </Flex>
  );
};

export default AddressDisplay;
