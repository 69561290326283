import { useTranslate, useUpdate } from "@refinedev/core";
import { Button, Form, Input, Modal } from "antd";
import React from "react";

interface EmailModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  contactId: any;
}

const EmailModal: React.FC<EmailModalProps> = ({
  visible,
  onClose,
  onSuccess,
  contactId,
}) => {
  const [form] = Form.useForm();
  const { mutateAsync: updateSingle } = useUpdate();
  const translate = useTranslate();

  const handleAddEmail = async (values: any) => {
    await updateSingle({
      resource: "contacts",
      id: contactId,
      values: { email: values.email },
    });
    form.resetFields();
    onSuccess();
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      destroyOnClose={true}
      title={translate("contacts.add_email")}
    >
      <Form form={form} onFinish={handleAddEmail} layout="vertical">
        <Form.Item
          name="email"
          label={translate("contacts.email.label")}
          rules={[
            {
              required: true,
              message: translate("contacts.email_required"), // Custom message for required validation
            },
            {
              type: "email",
              message: translate("contacts.invalid_email"), // Custom message for email format validation
            },
          ]}
        >
          <Input placeholder={translate("contacts.email.label")} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {translate("buttons.save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EmailModal;
