import { Button, Form, Input, Tooltip } from "antd";
import MagicIcon from "../../../assets/icons/magic";
import { useTranslate } from "@refinedev/core";

const CustomerNumberSection = ({ generateCustomerNumber }: { generateCustomerNumber: () => void }) => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.customer_number.label")}
      name="customer_number"
    >
      <Input
        placeholder={translate("contacts.customer_number.placeholder")}
        addonAfter={
          <Tooltip
            title={translate("contacts.customer_number.tooltip")}
            overlayInnerStyle={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <Button
              type="link"
              size="small"
              style={{ fontSize: "0.8em", color: "#2878c8" }}
              onClick={generateCustomerNumber}
              icon={<MagicIcon />}
            >
              {translate("contacts.customer_number.button")}
            </Button>
          </Tooltip>
        }
      />
    </Form.Item>
  );
};

export default CustomerNumberSection;
