import { PlusOutlined } from "@ant-design/icons";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useTranslate } from "@refinedev/core";
import { Button, Flex, Form, InputNumber, Space } from "antd";
import { useEffect, useState } from "react";
import { roundToTwoDecimals } from "../../../utility";
import { formatNumber } from "../../../utility/formatNumber";
import InvoiceProductRow from "./InvoiceProductRow";
import "./InvoiceTable.css";

type ShippingData = {
  cost: number; // shipping cost (€
  discount: number; // discount % (0 - 100)
  tax_rate: number;
};

type Props = {
  initialValues?: any[];
  initialShipping?: ShippingData;
  onProductsChange?: (products: any[]) => void;
  onTotalChange?: (totals: any) => void;
  onShippingCost?: (shippingData: {
    cost: number;
    discount: number;
    tax_rate: number;
  }) => void;
  subscriptionProduct?: boolean;
};

const InvoiceTable = ({
  initialValues = [],
  initialShipping = { cost: 0, discount: 0, tax_rate: 0 },
  onProductsChange,
  onTotalChange,
  onShippingCost,
  subscriptionProduct = false,
}: Props) => {
  const translate = useTranslate();

  // -----------------------------
  // 1) Products State
  // -----------------------------
  const [products, setProducts] = useState(
    initialValues.length > 0
      ? initialValues
      : [
          {
            id: "product-1",
            position: 1,
            type: "item",
            name: "",
            description: "",
            reference_id: null,
            price_per_item: 0,
            variant: null,
            tax_rate: 19,
            discount: 0,
            quantity: 1,
          },
        ]
  );
  const [idCounter, setIdCounter] = useState(products.length + 1);

  // -----------------------------
  // 2) Shipping State
  // -----------------------------
  const [shippingData, setShippingData] =
    useState<ShippingData>(initialShipping);

  // -----------------------------
  // 3) Totals State
  // -----------------------------
  const [totals, setTotals] = useState<any>();

  // -----------------------------
  // Lifecycle
  // -----------------------------
  useEffect(() => {
    const mappedProducts = products.map((product) => {
      const { position, ...productData } = product;
      return productData;
    });

    const newTotals = calculateTotals();

    setTotals(newTotals);

    if (onProductsChange) {
      onProductsChange(mappedProducts);
    }

    if (onTotalChange) {
      onTotalChange(newTotals);
    }
    if (setShippingData) {
      setShippingData({
        cost: shippingData.cost,
        discount: shippingData.discount,
        tax_rate: newTotals.shipping_tax_rate,
      });
    }
  }, [products]);
  useEffect(() => {
    const newTotals = calculateTotals();
    setTotals(newTotals);

    // If you need to notify the parent about the new total
    if (setTotals) {
      setTotals(newTotals);
    }
    // Pass only cost & discount to parent
    if (onShippingCost) {
      onShippingCost({
        cost: shippingData.cost,
        discount: shippingData.discount,
        tax_rate: newTotals.shipping_tax_rate,
      });
    }
  }, [shippingData]);

  // -----------------------------
  // 4) Handlers
  // -----------------------------

  // a) Product row updates
  const handleSaveRow = (updatedProduct, index) => {
    setProducts((prev) =>
      prev.map((product, idx) => {
        if (idx === index) {
          return { ...updatedProduct };
        }
        return product;
      })
    );
  };

  const handleDeleteRow = (index) => {
    setProducts((prev) =>
      prev
        .filter((_, idx) => idx !== index - 1)
        .map((product, newIndex) => ({ ...product, position: newIndex + 1 }))
    );
  };

  const handleAddRow = () => {
    setProducts((prev) => [
      ...prev,
      {
        id: `product-${idCounter}`,
        position: prev.length + 1,
        type: "item",
        name: "",
        description: "",
        reference_id: null,
        price_per_item: 0,
        variant: null,
        tax_rate: 19,
        // unit: "piece",
        discount: 0,
        quantity: 1,
      },
    ]);
    setIdCounter(idCounter + 1);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setProducts((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);

        // Update positions
        return newItems.map((item: any, idx) => ({
          ...item,
          position: idx + 1,
        }));
      });
    }
  };

  // b) Shipping cost input changes
  const handleShippingCostChange = (cost: number) => {
    setShippingData((prev) => ({
      ...prev,
      cost: cost || 0,
    }));
  };

  const handleShippingDiscountChange = (discount: number) => {
    setShippingData((prev) => ({
      ...prev,
      discount: discount || 0,
    }));
  };

  // -----------------------------
  // 5) Calculation
  // -----------------------------
  const calculateTotals = () => {
    // Subtotals based on tax rate
    const subtotal19 = products
      .filter((product) => product.tax_rate === 19)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );
    const subtotal7 = products
      .filter((product) => product.tax_rate === 7)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );

    const subtotal0 = products
      .filter((product) => product.tax_rate === 0)
      .reduce(
        (sum, product) =>
          sum +
          product.quantity *
            product.price_per_item *
            (1 - product.discount / 100),
        0
      );

    // Tax amounts
    const tax19 = roundToTwoDecimals(subtotal19 * 0.19);
    const tax7 = roundToTwoDecimals(subtotal7 * 0.07);
    const tax0 = 0;

    // Determine shipping tax rate automatically
    let shippingTaxRate = 0;
    if (subtotal19 > 0 && subtotal7 === 0) {
      shippingTaxRate = 19;
    } else if (subtotal7 > 0 && subtotal19 === 0) {
      shippingTaxRate = 7;
    } else if (subtotal19 > 0 && subtotal7 > 0) {
      shippingTaxRate = subtotal19 >= subtotal7 ? 19 : 7;
    } else if (subtotal0 > 0 && subtotal19 === 0 && subtotal7 === 0) {
      shippingTaxRate = 0;
    }

    // Shipping net, discount, and tax
    const shipping_net = shippingData.cost * (1 - shippingData.discount / 100);
    const shipping_tax = roundToTwoDecimals(
      shipping_net * (shippingTaxRate / 100)
    );
    const shipping_gross = shipping_net + shipping_tax;

    // Combine everything
    const netTotal = subtotal19 + subtotal7 + subtotal0 + shipping_net;
    const taxTotal = tax19 + tax7 + tax0 + shipping_tax;

    return {
      subtotal19: roundToTwoDecimals(subtotal19),
      subtotal7: roundToTwoDecimals(subtotal7),
      subtotal0: roundToTwoDecimals(subtotal0),
      tax19: roundToTwoDecimals(tax19),
      tax7: roundToTwoDecimals(tax7),
      tax0: roundToTwoDecimals(tax0),

      // Shipping breakdown (internal display)
      shipping_net: Number(roundToTwoDecimals(shipping_net)),
      shipping_tax: Number(roundToTwoDecimals(shipping_tax)),
      shipping_tax_rate: shippingTaxRate,
      shipping_gross: Number(roundToTwoDecimals(shipping_gross)),

      // Grand total
      total: roundToTwoDecimals(netTotal) + roundToTwoDecimals(taxTotal),
    };
  };

  // -----------------------------
  // 6) Rendering
  // -----------------------------
  return (
    <div className="invoice-table-container">
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={products.map((product) => product.id)}
          strategy={verticalListSortingStrategy}
        >
          <table className="invoice-table">
            <thead>
              <tr>
                <th>{translate("invoice_table.pos")}</th>
                <th>{translate("invoice_table.product")}</th>
                <th>{translate("invoice_table.quantity")}</th>
                {/* <th>{translate("invoice_table.unit")}</th> */}
                <th>{translate("invoice_table.price")}</th>
                <th>{translate("invoice_table.tax")}</th>
                <th>{translate("invoice_table.discount")}</th>
                <th>{translate("invoice_table.total")}</th>
                <th></th>
              </tr>
            </thead>

            {products.map((product, index) => (
              <InvoiceProductRow
                key={product.id}
                id={product.id}
                data={product}
                isEditing={true}
                onSave={(val) => handleSaveRow(val, index)}
                onDelete={handleDeleteRow}
                subscriptionProduct={!subscriptionProduct && index === 0}
                order={index + 1}
              />
            ))}
          </table>
        </SortableContext>
      </DndContext>

      <Flex justify="space-between">
        <Space direction="vertical" size="large">
          <Button
            type="dashed"
            onClick={handleAddRow}
            icon={<PlusOutlined />}
            className="add-row-button"
          >
            {translate("invoice_table.new_position")}
          </Button>
        </Space>

        <div>
          <Space>
            {/* Shipping cost */}
            <Form.Item label="Shipping Cost (€)">
              <InputNumber
                value={shippingData.cost}
                decimalSeparator={translate("global.decimal_seperator")}
                precision={2}
                min={0}
                onChange={(cost) => {
                  handleShippingCostChange(cost ?? 0); // Default to 0 when cleared
                }}
                parser={(valueStr: any) => {
                  const currentSeparator = translate(
                    "global.decimal_seperator"
                  );
                  let sanitized = valueStr.replace(/[^\d.,-]/g, "");

                  if (currentSeparator === ".") {
                    sanitized = sanitized.replace(",", ".");
                  } else {
                    sanitized = sanitized.replace(",", ".");
                  }

                  return sanitized ? parseFloat(sanitized) : 0; // Default to 0 when empty
                }}
              />
            </Form.Item>

            {/* Shipping cost discount (%) */}
            <Form.Item label="Shipping Discount (%)">
              <InputNumber
                min={0}
                max={100}
                value={shippingData.discount}
                decimalSeparator={translate("global.decimal_seperator")}
                onChange={(discount: number | null) =>
                  handleShippingDiscountChange(discount ?? 0)
                }
              />
            </Form.Item>
          </Space>

          {/* Totals Breakdown */}
          <div className="totals">
            {totals?.subtotal19 != 0 && (
              <div>
                <span>{translate("invoice_table.subtotal")} (19%): </span>
                <span>{formatNumber(totals?.subtotal19)} €</span>
              </div>
            )}
            {totals?.subtotal7 != 0 && (
              <div>
                <span>{translate("invoice_table.subtotal")} (7%): </span>
                <span>{formatNumber(totals?.subtotal7)} €</span>
              </div>
            )}
            {totals?.subtotal0 != 0 && (
              <div>
                <span>{translate("invoice_table.subtotal")} (0%): </span>
                <span>{formatNumber(totals?.subtotal0)} €</span>
              </div>
            )}
            {totals?.tax19 != 0 && (
              <div>
                <span>{translate("invoice_table.taxes")} (19%): </span>
                <span>{formatNumber(totals?.tax19)} €</span>
              </div>
            )}
            {totals?.tax7 != 0 && (
              <div>
                <span>{translate("invoice_table.taxes")} (7%): </span>
                <span>{formatNumber(totals?.tax7)} €</span>
              </div>
            )}

            {/* Show shipping lines */}
            {totals?.shipping_net > 0 && (
              <div>
                <span>Shipping (net): </span>
                <span>{formatNumber(totals?.shipping_net)} €</span>
              </div>
            )}
            {totals?.shipping_tax > 0 && (
              <div>
                <span>Shipping tax ({totals?.shipping_tax_rate}%): </span>
                <span>{formatNumber(totals?.shipping_tax)} €</span>
              </div>
            )}

            {/* Grand total */}
            <div className="total-amount">
              <span>{translate("invoice_table.total_amount")}: </span>
              <span>{formatNumber(totals?.total)} €</span>
            </div>
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default InvoiceTable;
