/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import styles from "./stageSelector.module.css";

import { DownOutlined } from "@ant-design/icons";
import { useForm, useList, useShow } from "@refinedev/core";
import { Dropdown } from "antd";
import { useUser } from "../../contexts/ContextProvider";
import { getContrastColor } from "../../utility";
import { sortPipelines } from "../../utility/pipelines";

type Props = {
  itemId?: string | number;
  pipelineId?: number | string;
  onUpdate?: () => void;
};

function findIndexById(objects: any[], targetId: number): number {
  for (let i = 0; i < objects?.length; i++) {
    const currentObject = objects[i];
    if (currentObject.id === targetId) {
      return i; // Found the object with the matching id
    }
    if (currentObject.children) {
      const childIndex = findIndexById(currentObject.children, targetId);
      if (childIndex !== -1) {
        return i; // Found the parent of the child object
      }
    }
  }
  return -1; // Object with the given id not found
}

export default function StageSelector({ itemId, onUpdate }: Props) {
  const { currentTeam } = useUser();

  const { query } = useShow({
    resource: "pipeline_items",
    id: itemId,
    meta: {
      select:
        "id,pipeline, pipeline_stage, pipeline_stages!pipeline_items_pipeline_stage_fkey(*)",
    },
  });
  const { data } = useList({
    resource: "pipeline_stages",
    meta: { select: "*" },
    filters: [
      {
        field: "pipeline",
        operator: "eq",
        value: query?.data?.data.pipeline ?? 0,
      },
      {
        field: "account",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        operator: "eq",
      },
    ],
    pagination: {
      pageSize: 100,
    },
  });
  const { onFinish } = useForm({
    resource: "pipeline_items",
    id: itemId,
    action: "edit",
    redirect: false,
  });

  const handleUpdate = async (stageId: number) => {
    try {
      await onFinish({
        pipeline_stage: stageId,
        stage_updated_at: new Date(),
      });
      await query.refetch();
      onUpdate?.();
    } catch (error) {
      console.error(error);
    }
  };

  const stages = React.useMemo(
    () =>
      sortPipelines(data?.data ?? []).reduce(
        (acc: any, current: any, _index, arr) => {
          if (current.parent !== null) {
            const parent = arr.find((item: any) => {
              return item.id == current.parent;
            });
            if (parent) {
              parent.children = parent.children || [];
              if (
                !parent.children.some((child: any) => child.id == current.id)
              ) {
                parent.children.push(current);
              }
            }
          } else {
            acc.push(current);
          }
          return acc;
        },
        []
      ),
    [data?.data]
  );

  const stagePipelineIndx = React.useMemo(
    () => findIndexById(stages, query.data?.data.pipeline_stage) ?? -1,
    [query.data?.data.pipeline_stage, stages]
  );

  return (
    <div>
      <ul className={`${styles.container} `}>
        {stages?.map((row: any, index: number) => {
          const stageColor =
            index <= stagePipelineIndx
              ? {
                  bgColor: query?.data?.data.pipeline_stages.color ?? "#163cff",
                  color: getContrastColor(
                    query?.data?.data.pipeline_stages.color ?? "#163cff"
                  ),
                  borderColor: "#096DD9",
                }
              : {
                  bgColor: "#FAFAFA",
                  color: "#8C8C8C",
                };
          if (row.children?.length) {
            const modifiedChildren = row.children.map((child: any) => ({
              label: <a onClick={() => handleUpdate(child.id)}>{child.name}</a>,
              key: child.id,
            }));
            const items = [
              {
                label: (
                  <a
                    key={row.id + "children"}
                    onClick={() => handleUpdate(row.id)}
                  >
                    {row.name}
                  </a>
                ),
                key: row.id,
              },
              ...modifiedChildren,
            ];
            return (
              <li
                className={`${styles.item} ${
                  index <= stagePipelineIndx ? styles.active : ""
                } `}
                style={
                  index <= stagePipelineIndx
                    ? {
                        backgroundColor: stageColor.bgColor,
                        color: stageColor.bgColor,
                      }
                    : {}
                }
                key={row.id}
              >
                <Dropdown
                  arrow
                  trigger={["click"]}
                  placement="bottomRight"
                  menu={{
                    items,
                  }}
                >
                  <div>
                    <a style={{ color: stageColor.color }}>
                      {items.find((item) => {
                        return item.key == query?.data?.data.pipeline_stages.id;
                      })?.label ?? row.name}
                    </a>
                    <DownOutlined className={styles.arrow} />
                  </div>
                </Dropdown>
              </li>
            );
          }
          return (
            <li
              className={`${styles.item} ${
                index <= stagePipelineIndx ? styles.active : ""
              } `}
              style={
                index <= stagePipelineIndx
                  ? {
                      backgroundColor: stageColor.bgColor,
                      color: stageColor.bgColor,
                    }
                  : {}
              }
              key={row.id}
              onClick={() => handleUpdate(row.id)}
            >
              <a style={{ color: stageColor.color }}>{row.name}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
