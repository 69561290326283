import { Form, Input, Row, Col } from "antd";
import { useTranslate } from "@refinedev/core";

const PhoneSection = () => {
  const translate = useTranslate();

  return (
    <Row gutter={[12, 0]}>
      <Col span={12}>
        <Form.Item
          name="phone_number"
          label={translate("contacts.phone_number.label")}
        >
          <Input placeholder={translate("contacts.phone_number.placeholder")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="phone_label"
          label={translate("contacts.phone_label.label")}
        >
          <Input placeholder={translate("contacts.phone_label.placeholder")} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PhoneSection;
