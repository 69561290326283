import React from "react";
import { Form, Input } from "antd";
import { useTranslate } from "@refinedev/core";

const LastNameSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.lastname.label")}
      name="lastname"
      rules={[
        {
          required: true,
          message: translate("contacts.lastname.required"),
        },
      ]}
    >
      <Input placeholder={translate("contacts.lastname.placeholder")} />
    </Form.Item>
  );
};

export default LastNameSection;
