import React from "react";
import { Form, Input } from "antd";
import { useTranslate } from "@refinedev/core";

const TitleSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.title.label")}
      name="name_prefix"
    >
      <Input placeholder={translate("contacts.title.placeholder")} />
    </Form.Item>
  );
};

export default TitleSection;
