export function formatAddressLabel(address: any, translate: any) {
  let label = "";

  if (address?.type === "PO_Box_Address") {
    label += `${address?.street ?? ""}\n`;
  } else if (address?.type === "Packing_Station") {
    if (address?.address_supplement_1) {
      label += `${translate("contacts.post_number")}: ${
        address?.address_supplement_1
      }\n`;
    }
    label += `${translate("contacts.packstation")}: ${address?.street ?? ""}\n`;
  } else {
    if (address?.address_supplement_1) {
      label += `${address?.address_supplement_1}\n`;
    }
    label += `${address?.street ?? ""}\n`;
  }

  label += `${address?.zip ?? ""} ${address?.city ?? ""}\n`;
  if (address?.country) {
    const countryName = translate(
      `countries.${address?.country?.toUpperCase()}`,
      address?.country
    );
    label += `${countryName}\n`;
  }

  if (address?.label) {
    label += `[${address?.label}]\n`;
  }

  return label?.trim();
}
