import { Col, Form, Input } from "antd";
import { useTranslate } from "@refinedev/core";

const EmailSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.email.label")}
      name="email"
      rules={[
        {
          type: "email",
          message: translate("contacts.email.validation_message"),
        },
      ]}
      validateTrigger="onBlur"
    >
      <Input placeholder={translate("contacts.email.placeholder")} />
    </Form.Item>
  );
};

export default EmailSection;
