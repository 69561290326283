import { CrudFilters } from "@refinedev/core";
import { FormInstance } from "antd";
import ContactAutoSelect from "../ContactAutoSelect";
import CompanyNameSection from "./CompanyNameSection";
import CustomerNumberSection from "./CustomerNumberSection";
import EmailSection from "./EmailSection";
import FirstNameSection from "./FirstNameSection";
import InvoiceEmailSection from "./InvoiceEmailSection";
import LastNameSection from "./LastNameSection";
import PhoneSection from "./PhoneSection";
import SalutationSection from "./SalutationSection";
import TaxNumberSection from "./TaxNumberSection";
import TitleSection from "./TitleSection";
import URLSection from "./URLSection";
import VatRegistrationIdSection from "./VatRegistrationIdSection";

export const getSectionsConfig = (translate, form: FormInstance) => [
  {
    key: "customer_number",
    title: translate("contacts.create_buttons.customer_number"),
    component: CustomerNumberSection,
    types: ["person", "company"],
    span: 12,
    required: false,
  },
  {
    key: "salutation",
    title: translate("contacts.create_buttons.salutation"),
    component: SalutationSection,
    types: ["person"],
    span: 4,
    required: true,
  },
  {
    key: "title",
    title: translate("contacts.create_buttons.title"),
    component: TitleSection,
    types: ["person"],
    span: 4,
    required: true,
  },
  {
    key: "firstname",
    title: translate("contacts.create_buttons.firstname"),
    component: FirstNameSection,
    types: ["person"],
    span: 8,
    required: true,
  },
  {
    key: "lastname",
    title: translate("contacts.create_buttons.lastname"),
    component: LastNameSection,
    types: ["person"],
    span: 8,
    required: true,
  },
  {
    key: "company_name",
    title: translate("contacts.create_buttons.company_name"),
    component: CompanyNameSection,
    types: ["company"],
    span: 24,
    required: true,
  },
  {
    key: "email",
    title: translate("contacts.create_buttons.email"),
    component: EmailSection,
    types: ["person", "company"],
    span: 12,
    required: true,
  },
  {
    key: "phone_number",
    title: translate("contacts.create_buttons.phone_number"),
    component: PhoneSection,
    types: ["person", "company"],
    span: 12,
    required: false,
  },
  {
    key: "url",
    title: translate("contacts.create_buttons.url"),
    component: URLSection,
    types: ["person", "company"],
    span: 12,
    required: false,
  },
  {
    key: "email_invoices",
    title: translate("contacts.create_buttons.email_invoices"),
    component: InvoiceEmailSection,
    types: ["person", "company"],
    span: 12,
    required: false,
  },
  {
    key: "tax_number",
    title: translate("contacts.create_buttons.tax_number"),
    component: TaxNumberSection,
    types: ["person", "company"],
    span: 12,
    required: false,
  },
  {
    key: "vat_registration_id",
    title: translate("contacts.create_buttons.vat_registration_id"),
    component: VatRegistrationIdSection,
    types: ["company"],
    span: 12,
    required: false,
  },
  {
    key: "contact_person",
    title: translate("contacts.create_buttons.contact_person"),
    component: ContactAutoSelect,
    types: ["company"],
    span: 12,
    required: false,
    props: {
      disableType: "company",
      disableCreate: false,
      required: false,
      onSelect: (value) => {
        form.setFieldsValue({ contact: value });
      },
      filter: [
        {
          field: "type",
          operator: "eq",
          value: "person",
        },
        {
          field: "company_id",
          operator: "null",
          value: true,
        },
      ] as CrudFilters,
    },
  },
];
