import React from "react";
import { Typography, List, Space, Divider } from "antd";

interface Item {
  label?: string;
  value: React.ReactNode;
  onClick?: () => void;
}

interface HorizontalItemDisplayProps {
  items?: Item[];
  title?: React.ReactNode;
  columns?: number;
}

const HorizontalItemDisplay: React.FC<HorizontalItemDisplayProps> = ({ items, title, columns = 1 }) => {
  return (
    <>
      {title &&
          <Divider
              plain
              orientation="left"
              orientationMargin={0}
          >
              <Typography.Text strong>
                {title}
              </Typography.Text>
          </Divider>}
      <List
        grid={{ gutter: [20, 30], column: columns }}
        dataSource={items}
        renderItem={(item) => (
          <List.Item>
            <Space
              style={{
                textAlign: item.label ? "left" : "right",
                cursor: item.onClick ? "pointer" : "default",
              }}
              direction={"vertical"}
              onClick={item.onClick}
              onMouseEnter={(e) => {
                if (item.onClick && item.label) {
                  (e.currentTarget.querySelector(".ant-typography") as HTMLElement).style.color = "#595959";
                }
              }}
              onMouseLeave={(e) => {
                if (item.onClick && item.label) {
                  (e.currentTarget.querySelector(".ant-typography") as HTMLElement).style.color = "#8c8c8c";
                }
              }}

            >
              {item.label && (
                <Typography.Text
                  style={{
                    color: "#8c8c8c",
                    fontSize: "0.875rem",
                    transition: "color 0.3s ease",
                  }}
                >
                  {item.label}
                </Typography.Text>
              )}
              <>
                {item.value}
              </>
            </Space>
          </List.Item>
        )}
      />
    </>
  );
};

export default HorizontalItemDisplay;
