import React from 'react';
import { useTranslate } from "@refinedev/core";
import ContactTypeIcon from './ContactTypeIcon';
import {Flex, Space, Typography} from 'antd';

type ContactDetailsProps = {
  contact?: any;
  address?: any;
};

const ContactDetails: React.FC<ContactDetailsProps> = ({ contact, address }) => {
  const translate = useTranslate();
  if (!contact && !address) return null;

  return (
    <>
      <Flex vertical={true}>
        <Flex gap={12}>
          <Typography.Text strong>
            {contact?.type === "company" && (
              <>
                {contact?.company_name} {contact?.company_line_2}
              </>
            )}
            {contact?.type === "person" && (
              <>
                {contact?.title && <>{contact?.title}</>} {contact?.firstname} {contact?.lastname}
              </>
            )}
          </Typography.Text>
          <ContactTypeIcon type={contact?.type}/>
        </Flex>
        {address && (
          <Flex vertical>
            {address?.street && (
              <span>{address?.street}</span>
            )}
            {(address?.zip || address?.city) && (
              <span>{address?.zip} {address?.city}</span>
            )}
            {address?.country != "de" && (
              <span>{translate(`countries.${address?.country?.toUpperCase()}`)}</span>
            )}
          </Flex>
        )}
      </Flex>

    </>
  );
};

export default ContactDetails;