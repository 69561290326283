import {
  DownOutlined,
  FileExcelOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useList, useNavigation, useTranslate } from "@refinedev/core";
import {
  Button,
  Dropdown,
  Flex,
  MenuProps,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import useFile from "../../../../hooks/useFile";
import { supabaseClient } from "../../../../utility";
import ProductIssuesExportList from "./export/list";

const ProductIssuesList: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isLoadingButton, setIsLoadingButton] = React.useState<any>();
  const { getFileDataURL } = useFile();
  const { id: productId } = useParams();
  const { replace } = useNavigation();
  const translate = useTranslate();

  const { data, isLoading } = useList({
    resource: "product_issues",
    filters: [
      {
        field: "product",
        operator: "eq",
        value: productId,
      },
    ],
    pagination: {
      pageSize: 1000,
    },
    sorters: [{ field: "created_at", order: "desc" }],
  });
  const handleMenuClick = ({ key }, record) => {
    if (key === "configure") {
      setIsModalVisible(true);
    }
    if (key === "publish") {
      // Call the Edge function to publish
    }
  };
  const handlePublishClick = async (productIssueId) => {
    await supabaseClient.functions.invoke("shop-core/publish-issue", {
      method: "POST",
      body: {
        product_issue_id: productIssueId,
      },
    });
  };
  const items: MenuProps["items"] = [
    {
      label: "Configure export lists",
      key: "configure",
    },
  ];
  async function downloadFile(filePath) {
    const { data, error } = await supabaseClient.storage
      .from("files")
      .download(filePath);

    if (error) {
      console.error("Error downloading file:", error);
      return;
    }
    const url = window.URL.createObjectURL(data);
    const fileName = filePath.split("/").pop();
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  return (
    <>
      {/*<Flex justify="space-between" align="center">*/}
      {/*  <Typography.Title*/}
      {/*    level={5}*/}
      {/*    style={{*/}
      {/*      fontSize: "1.02em",*/}
      {/*      marginBottom: 20,*/}
      {/*      marginTop: 20,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {translate("product_items.product_issues.title")}*/}
      {/*  </Typography.Title>*/}
      {/*</Flex>*/}
      <Table
        dataSource={data?.data}
        loading={isLoading}
        rowKey="id"
        pagination={false}
      >
        <Table.Column
          title={translate("product_items.product_issues.name")}
          onCell={(record) => {
            return {
              onClick: () => {
                replace(`product-issues/${record.id}`);
              },
            };
          }}
          render={(_, record) => (
            <>
              <Typography.Text>
                {record.name}{" "}
                {record.status == "draft" && <Tag>{record.status}</Tag>}
              </Typography.Text>
            </>
          )}
        />
        <Table.Column
          render={(_, record) => (
            <>
              <Dropdown.Button
                size="small"
                icon={<DownOutlined />}
                menu={{
                  items,
                  onClick: (info) => handleMenuClick(info, record),
                }}
                onClick={async (e) => {
                  setIsLoadingButton(record?.id);
                  e.preventDefault();
                  e?.stopPropagation();
                  await handlePublishClick(record?.id);
                  setIsLoadingButton(undefined);
                }}
                loading={isLoadingButton == record?.id}
                disabled={
                  record.status == "published" || isLoadingButton != undefined
                }
              >
                {record.status == "published"
                  ? translate("product_items.product_issues.published")
                  : translate("product_items.product_issues.publish")}
              </Dropdown.Button>
            </>
          )}
        />
        <Table.Column
          dataIndex="exports"
          title={translate("product_items.product_issues.exported_files")}
          render={(value) =>
            value ? (
              <Typography.Link
                onClick={() => downloadFile(value.filePath.path)}
              >
                <Space>
                  <FileExcelOutlined />{" "}
                  <Typography.Text> {value?.name}</Typography.Text>
                </Space>
              </Typography.Link>
            ) : (
              ""
            )
          }
        />
      </Table>

      <Flex>
        <Button
          icon={<PlusOutlined />}
          type="link"
          style={{ color: "var(--colorPrimary)" }}
          onClick={() => replace("product-issues/create")}
        >
          {translate("product_items.product_issues.create")}
        </Button>
      </Flex>

      <ProductIssuesExportList
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        productId={productId}
      />
    </>
  );
};

export default ProductIssuesList;
