import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  message,
} from "antd";
import { useTranslate } from "@refinedev/core";
import { useUser } from "../../../../contexts/ContextProvider";
import { supabaseClient } from "../../../../utility";
import { useState } from "react";

const AddPostalConnectionModal = ({
  isAddConnectionModalVisible,
  setAddConnectionModalVisible,
  form,
  emailValidationStatus,
  setEmailValidationStatus,
  refetch,
}) => {
  const { currentTeam } = useUser();
  const translate = useTranslate();

  const [submitLoading, setSubmitLoading] = useState(false);

  const resetFormAndStates = () => {
    setEmailValidationStatus({});
    form.resetFields();
  };

  const handleOk = async () => {
    setSubmitLoading(true);
    try {
      const values = await form.validateFields();
      const { postal_host, postal_api_key, emails, signature } = values;

      const postalDetails = {
        account: currentTeam?.account_id,
        account_type: "postal",
        postal_host,
        postal_api_key,
        emails,
        signature,
      };

      const { data, error } = await supabaseClient.functions.invoke(
        "integration-accounts/setup",
        {
          body: postalDetails,
        }
      );

      if (error) {
        throw new Error(translate("alerts.smtp.description"));
      }

      if (data.success) {
        message.success(translate("notifications.editSuccess"));
        refetch();
        setAddConnectionModalVisible(false);
        resetFormAndStates();
      } else if (data.emailValidation) {
        const emailFeedback = data.emailValidation;
        const updatedFields = emails.map((email: any, index: any) => ({
          name: ["emails", index],
          validateStatus: emailFeedback[email] ? "error" : "success",
        }));
        setEmailValidationStatus(updatedFields);
        message.error(translate("alerts.smtp_email.description"));
      } else {
        throw new Error(
          translate("alerts.submitError.defaultMessage") + ": " + data.message
        );
      }
    } catch (err: any) {
      message.error(
        translate("alerts.submitError.defaultMessage") + ": " + err.message
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {
    setAddConnectionModalVisible(false);
    resetFormAndStates();
  };

  return (
    <Modal
      title="Add Postal Mailserver"
      open={isAddConnectionModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          loading={submitLoading}
        >
          OK
        </Button>,
      ]}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          postal_host: "",
          postal_api_key: "",
          emails: [""],
        }}
      >
        <Form.Item
          label="Postal Mailserver Hostname"
          name="postal_host"
          rules={[
            { required: true, message: "Please enter Postal Mailserver Host" },
          ]}
        >
          <Input
            placeholder="your.post-server.eu"
            prefix={
              <Typography style={{ color: "rgba(0,0,0,.50)" }}>
                https://
              </Typography>
            }
          />
        </Form.Item>
        <Form.Item
          label="Postal API Key"
          name="postal_api_key"
          rules={[{ required: true, message: "Please enter Postal API Key" }]}
        >
          <Input placeholder="Enter API Key" />
        </Form.Item>

        <Form.List name="emails">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div
                  key={field.key}
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "flex-start",
                  }}
                >
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, "email"]}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address.",
                          },
                          {
                            required: true,
                            message:
                              "Please enter an email address or delete this field.",
                          },
                        ]}
                        style={{ flex: 1 }}
                      >
                        <Input placeholder="email@example.com" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...field}
                        name={[field.name, "name"]}
                        validateTrigger={["onChange", "onBlur"]}
                        style={{ flex: 1 }}
                      >
                        <Input placeholder="Name (optional)" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Signature"
                        name={[field.name, "signature"]}
                        style={{ flex: 1 }}
                      >
                        <Input.TextArea
                          placeholder="Enter Email signature"
                          rows={4}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      onClick={() => remove(field.name)}
                      style={{ marginLeft: 10, marginTop: 10 }}
                    />
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="link"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                  size={"small"}
                >
                  Add more E-mail Adresses
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AddPostalConnectionModal;
