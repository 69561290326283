import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { DeleteButton, useForm } from "@refinedev/antd";
import { useList, useTranslate, useUpdate } from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import { supabaseClient } from "../../../utility"; // still used for other calls, if any
import useAppsList from "./appsList";

import dayjs from "dayjs";
import AddConnectionModal from "./AddConnectionModal";
import AddLetterXpressConnectionModal from "./letterXpress/AddLetterXpressApiModal";
import AddPostalConnectionModal from "./postal/AddPostalConnectionModal";
import AddSmtpConnectionModal from "./smtp/AddSmtpConnectionModal";

export default function IntegrationsList() {
  type AppType = {
    id: string;
    title: string;
    icon: JSX.Element;
    comingSoon?: boolean;
    limit?: number;
  };

  enum Mode {
    test = "Test",
    live = "Live",
  }

  interface Account {
    id: string;
    created_at: string;
    account_type: string;
    credentials: {
      api_key?: string;
      iban?: string;
      bic?: string;
      username?: string;
      mode: "test" | "live";
    };
    email_sender_addresses: Array<{
      id?: number;
      email: string;
      name?: string;
      signature?: string;
    }>;
  }

  const [selectedApp, setSelectedApp] = useState<AppType | null>(null);
  const [activeTab, setActiveTab] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [emailValidationStatus, setEmailValidationStatus] = useState({});
  const [isAddConnectionModalVisible, setAddConnectionModalVisible] =
    useState(false);
  const { currentTeam } = useUser();
  const translate = useTranslate();

  const { form, formProps } = useForm();
  const { data, isLoading, refetch } = useList({
    resource: "integration_accounts",
    meta: { select: "*, email_sender_addresses(*)" },
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const products = useMemo(() => data?.data ?? [], [data?.data]);
  const groupedProducts = useMemo(() => {
    return products.reduce((acc, product) => {
      const key = product.account_type;
      if (!acc[key]) acc[key] = [];
      acc[key].push(product);
      return acc;
    }, {});
  }, [products]);

  const appsList = useAppsList();

  useEffect(() => {
    if (selectedApp) setDetailsModalVisible(true);
  }, [selectedApp]);

  const handleSearch = debounce(
    (event) => setSearchTerm(event.target.value.toLowerCase()),
    300
  );

  const handleCardClick = (app) => setSelectedApp(app);

  const getFilteredApps = () => {
    const filteredApps = appsList.filter((app) => {
      return (
        app.title.toLowerCase().includes(searchTerm) ||
        app.description.toLowerCase().includes(searchTerm)
      );
    });
    if (activeTab === "connected")
      return filteredApps.filter((app) => groupedProducts[app.id]);
    return filteredApps.filter((app) => app.category.includes(activeTab));
  };

  // ---------------------------------------------------------------------------
  // NEW: State & logic for editing name/signature in SMTP/Postal using Refine
  // ---------------------------------------------------------------------------
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editForm] = Form.useForm();
  const [editSender, setEditSender] = useState<{
    id?: number;
    name?: string;
    signature?: string;
  } | null>(null);

  // We will use Refine's useUpdate hook to update "email_sender_addresses"
  const { mutate: updateSenderAddress, isLoading: updateLoading } = useUpdate();

  // Open the modal and set the form with the chosen sender address’s current name/signature
  const handleOpenEditModal = (senderInfo: {
    id?: number;
    name?: string;
    signature?: string;
  }) => {
    setEditSender(senderInfo);
    editForm.setFieldsValue({
      name: senderInfo.name || "",
      signature: senderInfo.signature || "",
    });
    setEditModalVisible(true);
  };

  // Save the new name/signature to the DB via Refine
  const handleSaveEdit = async () => {
    try {
      const values = await editForm.validateFields();

      if (!editSender?.id) {
        return;
      }

      updateSenderAddress(
        {
          resource: "email_sender_addresses",
          id: editSender.id,
          values: {
            name: values.name,
            signature: values.signature,
          },
        },
        {
          onSuccess: () => {
            setEditModalVisible(false);
            refetch();
          },
          onError: (error) => {
            console.error("Error updating email sender:", error);
          },
        }
      );
    } catch (err) {
      console.error("Validation or update error:", err);
    }
  };

  const renderDetailsModal = () => {
    const connectedAccounts = selectedApp
      ? groupedProducts[selectedApp.id]
      : [];
    const canAddMoreConnections =
      selectedApp?.limit == null ||
      (connectedAccounts?.length ?? 0) < selectedApp.limit;
    return (
      <Modal
        width={"60%"}
        style={{ overflowY: "auto", padding: 50 }}
        open={isDetailsModalVisible}
        onCancel={() => setDetailsModalVisible(false)}
        title={selectedApp?.title}
        footer={
          canAddMoreConnections ? (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={async () => {
                if (selectedApp?.id === "microsoft") {
                  const { data, error } = await supabaseClient.functions.invoke(
                    "integration-accounts/setup",
                    {
                      body: {
                        account: currentTeam?.account_id,
                        account_type: selectedApp.id,
                      },
                    }
                  );
                  if (error) {
                    console.log("Error:", error);
                  } else {
                    window.location.replace(data?.redirect);
                  }
                } else if (selectedApp?.id === "lexware_partner") {
                  const { data, error } = await supabaseClient.functions.invoke(
                    "integration-accounts/setup",
                    {
                      body: {
                        account: currentTeam?.account_id,
                        account_type: selectedApp.id,
                        connection_name: currentTeam?.name,
                      },
                    }
                  );
                  if (error) {
                    console.log("Error:", error);
                  } else {
                    window.location.replace(data?.redirect);
                  }
                } else {
                  setAddConnectionModalVisible(true);
                }
              }}
            >
              {translate("integrations.add_connection")}
            </Button>
          ) : null // Keine Buttons anzeigen, wenn das Limit erreicht ist
        }
        centered
      >
        <List
          itemLayout="horizontal"
          dataSource={connectedAccounts as Account[]}
          renderItem={(account) => (
            <List.Item
              actions={[
                // -------------------------------------------------------------
                // NEW: "Edit" button for SMTP or Postal using handleOpenEditModal
                // -------------------------------------------------------------
                ...(account.account_type === "smtp" ||
                account.account_type === "postal"
                  ? [
                      <Button
                        key="edit-sender"
                        onClick={() => {
                          // For demonstration, we edit the first sender
                          // If you handle multiple addresses, you'd map them individually
                          const firstSender =
                            account.email_sender_addresses?.[0];
                          if (firstSender) {
                            handleOpenEditModal(firstSender);
                          }
                        }}
                      >
                        Edit
                      </Button>,
                    ]
                  : []),
                // Original delete logic
                ...(account.account_type !== "lexware_partner"
                  ? [
                      <DeleteButton
                        resource="integration_accounts"
                        recordItemId={account.id}
                        hideText
                        onSuccess={() => refetch()}
                      />,
                    ]
                  : [
                      <Popconfirm
                        title="Are you sure you want to delete this?"
                        onConfirm={async () => {
                          await supabaseClient.functions.invoke(
                            "integration-accounts/revoke",
                            {
                              body: {
                                account_type: "lexware_partner",
                                account: currentTeam?.account_id,
                              },
                            }
                          );
                          await refetch();
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button icon={<DeleteOutlined />} danger></Button>
                      </Popconfirm>,
                    ]),
              ]}
            >
              <List.Item.Meta
                title={
                  <>
                    {(account.account_type === "microsoft" ||
                      account.account_type === "smtp") &&
                      (account?.email_sender_addresses
                        ?.map((emailObj) => emailObj.email)
                        .join(", ") ||
                        "No email")}
                    {account.account_type === "sepa" && (
                      <Flex gap={24}>
                        <Flex gap={4} vertical={true}>
                          <Typography.Text strong={true}>IBAN</Typography.Text>
                          {account.credentials.iban}
                        </Flex>
                        <Flex gap={4} vertical={true}>
                          <Typography.Text strong={true}>BIC</Typography.Text>
                          {account.credentials.bic}
                        </Flex>
                      </Flex>
                    )}
                    {account.account_type === "lexware_public" && (
                      <>{account.credentials.api_key}</>
                    )}
                    {account.account_type == "letterxpress" && (
                      <Space direction="vertical">
                        <Flex gap={4}>
                          <Typography.Text strong>Username:</Typography.Text>
                          <Typography.Text type="secondary">
                            {account.credentials.username}
                          </Typography.Text>
                        </Flex>
                        <Flex gap={4} vertical={false}>
                          <Typography.Text strong={true}>
                            Api Key:
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            {account.credentials.api_key}
                          </Typography.Text>
                        </Flex>
                        <Flex gap={4} vertical={false}>
                          <Typography.Text strong={true}>Mode:</Typography.Text>
                          <Typography.Text type="secondary">
                            {account.credentials.mode
                              ? Mode[account.credentials.mode]
                              : ""}
                          </Typography.Text>
                        </Flex>
                      </Space>
                    )}
                  </>
                }
                description={
                  <Typography.Text style={{ fontSize: 13 }} type="secondary">
                    {dayjs(account.created_at).format(
                      translate("page.settings.date_format")
                    )}
                  </Typography.Text>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    );
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>
            {translate("settings.integrations.title")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("settings.integrations.intro")}
          </Typography.Paragraph>
        </Col>
      </Row>

      <Input
        placeholder={translate("settings.integrations.search")}
        onChange={handleSearch}
        style={{ marginBottom: 20, width: "100%" }}
      />

      <Form {...formProps} layout="vertical">
        <Tabs
          defaultActiveKey="all"
          onChange={(key) => setActiveTab(key)}
          items={[
            { key: "all", label: translate("integrations.all") },
            { key: "email", label: translate("integrations.email_messaging") },
            { key: "payments", label: translate("integrations.payments") },
            { key: "calendars", label: translate("integrations.calendars") },
            { key: "connected", label: translate("integrations.connected") },
          ]}
        />
        <Row gutter={[32, 32]} style={{ marginTop: 32 }}>
          {getFilteredApps().map((app) => (
            <Col xs={24} sm={12} md={8} key={app.id}>
              <Card
                hoverable
                onClick={() => handleCardClick(app)}
                style={{ height: "100%" }}
              >
                <Space style={{ width: "100%" }} direction="vertical">
                  <Flex justify={"space-between"}>
                    {app?.icon}
                    <div>
                      {groupedProducts[app.id] && (
                        <Tag color="green">connected</Tag>
                      )}
                      {app.comingSoon && (
                        <Tag color="yellow">
                          {translate("buttons.coming_soon")}
                        </Tag>
                      )}
                    </div>
                  </Flex>
                  <Typography.Title level={5}>{app.title}</Typography.Title>
                  <Typography.Text>{app.description}</Typography.Text>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>
      </Form>

      {renderDetailsModal()}
      {selectedApp?.id === "smtp" ? (
        <AddSmtpConnectionModal
          isAddConnectionModalVisible={isAddConnectionModalVisible}
          setAddConnectionModalVisible={() => {
            setAddConnectionModalVisible(false);
            setModalLoading(false);
            form.resetFields();
          }}
          form={form}
          refetch={refetch}
          setModalLoading={() => setModalLoading(!modalLoading)}
          emailValidationStatus={emailValidationStatus}
          setEmailValidationStatus={() =>
            setEmailValidationStatus(!emailValidationStatus)
          }
          modalLoading={modalLoading}
        />
      ) : selectedApp?.id == "postal" ? (
        <AddPostalConnectionModal
          isAddConnectionModalVisible={isAddConnectionModalVisible}
          setAddConnectionModalVisible={() => {
            setAddConnectionModalVisible(false);
            setModalLoading(false);
            form.resetFields();
          }}
          form={form}
          refetch={refetch}
          emailValidationStatus={emailValidationStatus}
          setEmailValidationStatus={() =>
            setEmailValidationStatus(!emailValidationStatus)
          }
        />
      ) : selectedApp?.id == "letterxpresss" ? (
        <AddLetterXpressConnectionModal
          isAddConnectionModalVisible={isAddConnectionModalVisible}
          setAddConnectionModalVisible={() =>
            setAddConnectionModalVisible(false)
          }
          form={form}
          refetch={refetch}
          setModalLoading={() => setModalLoading(!modalLoading)}
          modalLoading={modalLoading}
        />
      ) : (
        <AddConnectionModal
          visible={isAddConnectionModalVisible}
          onClose={() => setAddConnectionModalVisible(false)}
          form={form}
          appType={selectedApp?.id}
          refetch={refetch}
        />
      )}

      {/* -----------------------------------------------------------------------
          NEW: Modal to edit name/signature using Refine's useUpdate
      ----------------------------------------------------------------------- */}
      <Modal
        title="Edit Sender Name & Signature"
        open={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        onOk={handleSaveEdit}
        confirmLoading={updateLoading} // from useUpdate
        destroyOnClose
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter the sender name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Signature" name="signature">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
