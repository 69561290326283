import { Form, Input } from "antd";
import { useTranslate } from "@refinedev/core";

const CompanyNameSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.company_name")}
      name="company_name"
      rules={[{ required: true, message: translate("contacts.company_name_required") }]}
    >
      <Input placeholder={translate("contacts.company_name_placeholder")} />
    </Form.Item>
  );
};

export default CompanyNameSection;
