import React, { useState } from "react";
import SettingsItem from "./components/SettingsItem";
import SettingsContainer from "./components/SettingsContainer";
import { useSettingsLogic } from "./hooks/useSettingsLogic";
import { Form, Input, Space, Typography } from "antd";
import FormAutoSelect from "../mailings/FormAutoSelect";
import { useUser } from "../../contexts/ContextProvider";
import { SingleElementFormV2 } from "../single-element-form/single-element-form-v2";
import { EmailField } from "@refinedev/antd";

const SettingsPage: React.FC = () => {
  const [activeForm, setActiveForm] = useState<"payment_deadline">();
  const { currentTeam } = useUser();
  const { settings, updateModuleStatus, translate } = useSettingsLogic({
    modulePath: "shop",
  });
  return (
    <SettingsContainer
      modulePath="shop"
      title={translate("settings.shop.title")}
      description={translate("settings.shop.description")}
      intro={translate("settings.shop.intro")}
    >
      <SettingsItem
        title={translate("settings.shop.variants.title")}
        description={translate("settings.shop.variants.description")}
        checked={settings["shop.variants"]}
        onChange={() =>
          updateModuleStatus("shop.variants", !settings["shop.variants"])
        }
      />

      <SettingsItem
        title={translate("settings.shop.ecommerce.title")}
        description={translate("settings.shop.ecommerce.description")}
        checked={settings["shop.ecommerce"]}
        onChange={() =>
          updateModuleStatus("shop.ecommerce", !settings["shop.ecommerce"])
        }
      ></SettingsItem>

      <SettingsItem
        title={translate("settings.shop.selfservice.title")}
        description={translate("settings.shop.selfservice.description")}
        checked={settings["shop.selfservice"]}
        onChange={() =>
          updateModuleStatus("shop.selfservice", !settings["shop.selfservice"])
        }
      />

      <SettingsItem
        title={translate("settings.shop.draft_invoice.title")}
        description={translate("settings.shop.draft_invoice.description")}
        checked={settings["shop.draft_invoice"]}
        onChange={() =>
          updateModuleStatus(
            "shop.draft_invoice",
            !settings["shop.draft_invoice"]
          )
        }
      />
      {currentTeam?.account_id && (
        <SettingsItem title={translate("settings.shop.sender_mail.title")}>
          <Form>
            <FormAutoSelect
              onSelect={(value) =>
                updateModuleStatus("shop.sender_email", value ?? null)
              }
              teamId={currentTeam?.account_id}
              rules={[]}
              handleClear={() => updateModuleStatus("shop.sender_email", null)}
              label=""
              initialValue={settings["shop.sender_email"]}
            />
            <Typography.Text type="secondary">
              {translate("settings.shop.sender_mail.description")}
            </Typography.Text>
          </Form>
        </SettingsItem>
      )}
      <SettingsItem
        title={translate("settings.shop.payment_deadline.title")}
        description={translate("settings.shop.payment_deadline.description")}
      >
        <SingleElementFormV2
          state={
            activeForm && activeForm === "payment_deadline"
              ? "form"
              : settings["shop.payment_deadline"]
              ? "view"
              : "view"
          }
          view={
            <Space size={"middle"}>
              <Input
                value={settings["shop.payment_deadline"]}
                placeholder={translate(
                  "settings.shop.payment_deadline.placeholder"
                )}
                readOnly
                onClick={() => setActiveForm("payment_deadline")}
                style={{
                  width: "100%",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
              />
              {/* </Form.Item> */}
            </Space>
          }
          onClick={() => setActiveForm("payment_deadline")}
          onUpdate={() => {
            setActiveForm(undefined);
          }}
          onCancel={() => setActiveForm(undefined)}
          style={{ paddingLeft: 0 }}
          formProps={{
            async onFinish(value) {
              if (value.payment_deadline) {
                await updateModuleStatus(
                  "shop.payment_deadline",
                  parseInt(value.payment_deadline)
                );
              }
              setActiveForm(undefined);
            },
          }}
        >
          <Form.Item
            name="payment_deadline"
            initialValue={settings["shop.payment_deadline"]}
            style={{ flex: 1, margin: 0 }}
          >
            <Input
              placeholder={translate(
                "settings.shop.payment_deadline.placeholder"
              )}
              type="number"
              style={{
                width: "100%",
                fontSize: "14px",
                borderRadius: "4px",
                minHeight: "25px",
              }}
            />
          </Form.Item>
        </SingleElementFormV2>
      </SettingsItem>
    </SettingsContainer>
  );
};

export default SettingsPage;
