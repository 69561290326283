import React from "react";
import { Form, Input } from "antd";
import { useTranslate } from "@refinedev/core";

const TaxNumberSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.tax_number.label")}
      name="tax_number"
    >
      <Input placeholder={translate("contacts.tax_number.placeholder")} />
    </Form.Item>
  );
};

export default TaxNumberSection;
