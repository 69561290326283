import { DownloadOutlined } from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import { useNavigation, useTranslate } from "@refinedev/core";
import { Button, Col, Row, Space, Spin, Table, Tag, Typography } from "antd";
import React, { PropsWithChildren, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { numberToCurrency } from "../../utility";

const InvoicesList: React.FC<PropsWithChildren> = ({ children }) => {
  const [touch, setTouch] = useState(false);
  const translate = useTranslate();
  const { list } = useNavigation();
  const { currentTeam } = useUser();
  const { tableProps } = useTable({
    resource: "invoices",
    meta: {
      select: "*",
      schema: "basejump",
    },
    syncWithLocation: true,
    filters: {
      permanent: [
        {
          field: "account",
          value: currentTeam?.account_id,
          operator: "eq",
        },
      ],
    },
  });

  if (!currentTeam || !currentTeam?.account_id || tableProps.loading) {
    return <Spin />;
  }

  return (
    <>
      <Row>
        <Col md={8}>
          <Typography.Title level={4}>
            {translate("settings.invoices.invoices")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("settings.invoices.intro")}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginTop: 50 }}>
        <Col md={8}>
          <Typography.Title level={5}>
            {translate("settings.invoices.overview")}
          </Typography.Title>
          <Typography.Paragraph type="secondary">
            {translate("settings.invoices.overview_info")}
          </Typography.Paragraph>
        </Col>
        <Col md={16}>
          <Table
            {...tableProps}
            rowKey={(record) => record.id ?? ""}
            rowClassName={"clickableRow"}
            loading={currentTeam === undefined}
            pagination={{
              ...tableProps.pagination,
              showSizeChanger: true,
            }}
          >
            <Table.Column
              dataIndex="date"
              title={translate("settings.invoices.fields.invoice_date", "Date")}
              render={(val) => {
                return (
                  <>
                    {val
                      ? new Date(val).toLocaleDateString(
                          translate("pipelines.date_locale")
                        )
                      : null}
                  </>
                );
              }}
            />
            <Table.Column
              dataIndex="invoice_no"
              title={translate(
                "settings.invoices.fields.invoice_number",
                "Invoice No"
              )}
            />
            <Table.Column
              dataIndex="amount"
              render={(text) => <div>{numberToCurrency(text)}</div>}
              title={translate("settings.invoices.fields.amount", "Amount")}
            />
            <Table.Column
              dataIndex="status"
              render={(text) => {
                const translatedText = translate(
                  `billing_details.status.${text}`,
                  text // Fallback auf den ursprünglichen Text, falls keine Übersetzung gefunden wird
                );
                const tagColor =
                  text === "open"
                    ? "orange"
                    : text === "paid"
                    ? "green"
                    : "blue";

                return <Tag color={tagColor}>{translatedText}</Tag>;
              }}
              title={translate("settings.invoices.fields.status", "Status")}
            />
            <Table.Column
              render={() => (
                <Space size="middle">
                  <Button icon={<DownloadOutlined />} size="small" />
                </Space>
              )}
              title={translate("settings.invoices.fields.download", "Download")}
            />
          </Table>
        </Col>
      </Row>
      {children}
    </>
  );
};
export default InvoicesList;
