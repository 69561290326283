import { useTranslate, useUpdate } from "@refinedev/core";
import { Button, Form, Input, Modal } from "antd";
import React from "react";

const url_regex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

interface URLModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  contactId: any;
}

const URLModal: React.FC<URLModalProps> = ({
  visible,
  onClose,
  onSuccess,
  contactId,
}) => {
  const [form] = Form.useForm();
  const { mutateAsync: updateSingle } = useUpdate();
  const translate = useTranslate();

  const handleAddURL = async (values: any) => {
    await updateSingle({
      resource: "contacts",
      id: contactId,
      values: { url: values.url },
    });
    form.resetFields();
    onSuccess();
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      destroyOnClose={true}
      title={translate("contacts.add_url")}
    >
      <Form form={form} onFinish={handleAddURL} layout="vertical">
        <Form.Item
          name="url"
          label={translate("contacts.url.label")}
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (!value || url_regex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(translate("contacts.invalid_url"))
                );
              },
            },
          ]}
        >
          <Input placeholder={translate("contacts.url.placeholder")} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {translate("buttons.save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default URLModal;
