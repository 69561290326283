import { Drawer, Space, Typography } from "antd";
import ContactTypeIcon from "../../../components/contacts/ContactTypeIcon";
import OverviewSection from "../../../components/contacts/OverviewSection";
import { ContactProvider } from "../../../contexts/ContactProvider/useContactContext";
import { getContactFormatedName } from "../../../utility/contactName";

const ContactDrawer = ({
  isVisible,
  onClose,
  contact_id,
  type,
  firstname,
  lastname,
  company_name,
  email,
}) => {
  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      destroyOnClose
      width={"70%"}
      title={
        <Space>
          <ContactTypeIcon type={type} />
          <Typography.Text style={{ fontSize: "1.1rem" }}>
            {firstname || lastname
              ? getContactFormatedName("", firstname, lastname, email)
              : company_name}
          </Typography.Text>
        </Space>
      }
      styles={{
        header: {
          borderBottom: "none",
          alignItems: "start",
          background: "white",
        },
        body: { zIndex: 1, paddingTop: 0, background: "white" },
      }}
    >
      <ContactProvider contactId={contact_id}>
        <OverviewSection />
      </ContactProvider>
    </Drawer>
  );
};

export default ContactDrawer;
