import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import {
  useDelete,
  useList,
  useNavigation,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Drawer,
  Flex,
  Popconfirm,
  Progress,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddressDisplay from "../../../components/invoices/AddressDisplay";
import ContactDisplay from "../../../components/invoices/ContactDisplay";
import CreateOrEditInvoice from "../../../components/invoices/CreateOrEditInvoice";
import FileDisplay from "../../../components/invoices/FileDisplay";
import InvoiceFooter from "../../../components/invoices/FooterPart";
import LineItemDisplay from "../../../components/invoices/LineItemTable";
import { numberToCurrency } from "../../../utility";
import ContactDrawer from "../orders/ContactDrawer";
import ContactInformation from "../orders/ContactInformation";
import ContactDetails from "../../../components/contacts/ContactDetails";
import ContactAddress from "../../../components/contacts/ContactAddress";

const InvoiceDrawer: React.FC = () => {
  const { list } = useNavigation();

  const [mailOutbox, setMailOutbox] = useState<any[]>([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  // Open the drawer
  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  // Close the drawer
  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };
  const { contactId, invoiceId } = useParams<{
    contactId: any;
    invoiceId: string;
  }>();

  const { tableQuery: invoiceData } = useTable({
    resource: "invoices",
    pagination: {
      mode: "off",
    },
    filters: {
      permanent: [
        { field: "contact", value: contactId, operator: "eq" },
        { field: "id", value: invoiceId, operator: "eq" },
      ],
    },
    syncWithLocation: false,
  });
  const invoice = invoiceData?.data?.data[0];
  // Zweite Abfrage: Daten aus der Tabelle "mail_outbox"
  const { data: mailOutboxData, isLoading: isMailOutboxLoading } = useList({
    resource: "mail_outbox",
    filters: [
      { field: "mail_outbox_type", operator: "eq", value: "invoice" },
      { field: "mail_outbox_id", operator: "eq", value: invoice?.id },
    ],
    pagination: { mode: "off" },
  });

  // Filtere die Daten, wenn die erste Abfrage abgeschlossen ist
  useEffect(() => {
    if (invoice && mailOutboxData?.data) {
      setMailOutbox(mailOutboxData.data);
    }
  }, [invoice, mailOutboxData]);

  const translate = useTranslate();

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleEditClick = () => {
    setIsEditModalVisible(true);
  };

  const closeEditModal = () => {
    setIsEditModalVisible(false);
  };

  const { mutateAsync: updateInvoice, isLoading: updateLoading } = useUpdate();
  const { mutateAsync: deleteInvoice, isLoading: deleteLoading } = useDelete();
  const { data: contact } = useList({
    resource: "contacts",
    filters: [
      {
        field: "id",
        operator: "eq",
        value: invoice?.contact,
      },
    ],
    meta: {
      select: "*",
    },
  });

  const { id, type, firstname, lastname, company_name, email } =
    contact?.data[0] ?? {};

  const lineItems = invoice?.line_items || [];
  const handleDelete = async () => {
    if (invoice?.id) {
      await deleteInvoice({ resource: "invoices", id: invoice?.id });
    }
  };
  const handleClose = async () => {
    await updateInvoice({
      resource: "invoices",
      id: invoice?.id,
      values: { status: "finished" },
    });
  };
  const handleChangeToUnfinish = async () => {
    await updateInvoice({
      resource: "invoices",
      id: invoice?.id,
      values: { status: "unfinished" },
    });
  };
  if (invoiceData?.isLoading) {
    return <Spin />;
  }
  const closeInvoiceDrawer = () => {
    list("invoices");
  };
  const statusColors: Record<string, string> = {
    unfinished: "red",
    finished: "green",
    unpaid: "orange",
    overdue: "red",
    paid: "green",
    canceled: "grey",
    partially_paid: "blue",
  };

  return (
    <>
      <Drawer
        open
        onClose={closeInvoiceDrawer}
        className="invoiceDrawer"
        title={
          <>
            {translate("billing_details.title")}
            {invoice?.invoice_number && <> {invoice?.invoice_number}</>}
          </>
        }
        footer={null}
        width={"70%"}
        extra={
          <>
            {invoice?.status == "unfinished" && (
              <Space>
                <Popconfirm
                  title={translate("billing_details.confirmation.delete_title")}
                  description={translate(
                    "billing_details.confirmation.delete_description"
                  )}
                  onConfirm={handleDelete}
                  okText={
                    <>
                      {translate("billing_details.confirmation.ok")} -{" "}
                      {translate("billing_details.confirmation.delete_title")}
                    </>
                  }
                  okButtonProps={{ danger: true }}
                  cancelText={translate("billing_details.confirmation.cancel")}
                >
                  <Button
                    type="text"
                    danger
                    icon={<DeleteOutlined />}
                    loading={deleteLoading}
                  >
                    {translate("billing_details.buttons.delete")}
                  </Button>
                </Popconfirm>

                <Button
                  type="default"
                  onClick={handleEditClick}
                  icon={<EditOutlined />}
                >
                  {translate("settings.invoices.edit_invoice")}
                </Button>

                <Popconfirm
                  title={translate("billing_details.confirmation.finish_title")}
                  description={translate(
                    "billing_details.confirmation.finish_description"
                  )}
                  onConfirm={handleClose}
                  okText={
                    <>
                      {translate("billing_details.confirmation.ok")} -{" "}
                      {translate("billing_details.confirmation.finish_title")}
                    </>
                  }
                  cancelText={translate("billing_details.confirmation.cancel")}
                >
                  <Button
                    type="primary"
                    loading={updateLoading}
                    icon={<DownloadOutlined />}
                  >
                    {translate("billing_details.buttons.finish")}
                  </Button>
                </Popconfirm>
              </Space>
            )}
            {invoice?.invoice_number == null &&
              invoice?.status == "finished" && (
                <Space>
                  <Popconfirm
                    title={translate(
                      "billing_details.confirmation.unfinish_title"
                    )}
                    description={translate(
                      "billing_details.confirmation.unfinish_description"
                    )}
                    onConfirm={handleChangeToUnfinish}
                    okText={
                      <>
                        {translate("billing_details.confirmation.ok")} -{" "}
                        {translate(
                          "billing_details.confirmation.unfinish_title"
                        )}
                      </>
                    }
                    cancelText={translate(
                      "billing_details.confirmation.cancel"
                    )}
                  >
                    <Button loading={updateLoading}>
                      {translate("billing_details.buttons.unfinish")}
                    </Button>
                  </Popconfirm>
                </Space>
              )}
          </>
        }
      >
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          <Descriptions layout="vertical">
            <Descriptions.Item label={translate("billing_details.recipient")}>
              <Space
                onClick={showDrawer}
                style={{ cursor: "pointer" }}
                direction={"vertical"}
              >

                <Space direction={"vertical"}>
                  <ContactDisplay
                    label={translate("settings.contact")}
                    contact={invoice?.contact_name || "No contact provided"}
                  />
                  <ContactAddress contactAddress={invoice?.contact_address} />
                  <Typography>{invoice?.contact_email}</Typography>
                </Space>

              </Space>
            </Descriptions.Item>
            <Descriptions.Item
              label={translate("billing_details.status_label")}
            >
              {translate(`billing_details.status.${invoice?.status}`)}
            </Descriptions.Item>

            <Descriptions.Item label={translate("billing_details.paid_label")}>
              <Flex vertical style={{ width: "100%" }}>
                <Typography.Title level={5}>
                  {numberToCurrency(invoice?.amount_paid || 0)}
                </Typography.Title>
                <Progress
                  style={{ width: "80%" }}
                  type="line"
                  percent={(invoice?.amount_paid / invoice?.amount) * 100}
                  format={(percent) => `${percent?.toFixed(0)}%`}
                  status="active"
                  strokeColor={statusColors[invoice?.status] || "blue"}
                />
              </Flex>
            </Descriptions.Item>
          </Descriptions>

          <FileDisplay
            label={translate("files.files")}
            invoice_number={invoice?.invoice_number}
          />

          <Card>
            <LineItemDisplay lineItems={lineItems} />
            <InvoiceFooter
              taxAmounts={invoice?.tax_amounts || []}
              totalAmount={invoice?.amount}
              shippingData={invoice?.shipping}
            />
          </Card>

          {invoiceData.isLoading || isMailOutboxLoading ? (
            <Spin />
          ) : (
            <>
              {mailOutbox.map((mail) => (
                <Card>
                  <Space direction={"vertical"} size={"middle"}>
                    <Typography.Text strong>
                      {translate("billing_details.mail_outbox.title")}:
                    </Typography.Text>
                    <Row>
                      <Col span={12}>
                        <Descriptions column={1}>
                          <Descriptions.Item
                            label={translate("billing_details.mail_outbox.to")}
                          >
                            {mail.to}
                          </Descriptions.Item>

                          {mail?.status == "sent" && (
                            <Descriptions.Item
                              label={translate(
                                "billing_details.mail_outbox.sent_at"
                              )}
                            >
                              {mail.sent_at}
                            </Descriptions.Item>
                          )}
                        </Descriptions>
                      </Col>
                      <Col span={12}>
                        <Descriptions column={1}>
                          <Descriptions.Item
                            label={translate(
                              "billing_details.mail_outbox.status"
                            )}
                          >
                            {translate(
                              `billing_details.mail.status.${
                                mail?.status ?? ""
                              }`
                            )}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                  </Space>
                </Card>
              ))}
            </>
          )}
        </Space>
      </Drawer>
      <ContactDrawer
        isVisible={isDrawerVisible}
        onClose={closeDrawer}
        contact_id={invoice?.contact}
        type={type}
        firstname={firstname}
        lastname={lastname}
        company_name={company_name}
        email={email}
      />
      {isEditModalVisible && (
        <CreateOrEditInvoice
          open={isEditModalVisible}
          invoice={invoice}
          onClose={closeEditModal}
          contact={{
            id: invoice?.contact,
            name: invoice?.contact_name,
          }}
        />
      )}
    </>
  );
};

export default InvoiceDrawer;
