import { useSelect } from "@refinedev/antd";
import { useUser } from "../contexts/ContextProvider";
import { BaseRecord } from "@refinedev/core";

type UseSelectProps = Parameters<typeof useSelect>[0];

export const useStageSelect = (
  pipelineId?: number | string,
  selectQuery?: string,
  useSelectProps?: Partial<Omit<UseSelectProps, "resource">>
) => {
  const { currentTeam } = useUser();

  return useSelect({
    resource: "pipeline_stages",
    optionLabel: (item: BaseRecord) => item.name as string,
    optionValue: (item: BaseRecord) => item.id as string,
    filters: pipelineId
      ? [
          { field: "pipeline", operator: "eq", value: pipelineId },
          {
            field: "account",
            value: currentTeam?.account_id,
            operator: "eq",
          },
        ]
      : [
          {
            field: "account",
            value: currentTeam?.account_id,
            operator: "eq",
          },
        ],
    meta: {
      select: selectQuery ?? "*",
    },
    sorters: [{ field: "order", order: "asc" }],
    ...useSelectProps,
  });
};
