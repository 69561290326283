import { useTranslate } from "@refinedev/core";
import { Typography } from "antd";
import React from "react";
import i18n from "../../i18n";
import { numberToCurrency } from "../../utility";

const { Text } = Typography;

interface TaxAmount {
  netAmount: number;
  taxAmount: number;
  taxRatePercentage: number;
}

interface ShippingData {
  cost: number;
  discount: number;
  tax_rate: number;
}

interface InvoiceFooterProps {
  taxAmounts: TaxAmount[];
  totalAmount: number;
  shippingData: ShippingData;
}

const InvoiceFooter: React.FC<InvoiceFooterProps> = ({
  taxAmounts,
  totalAmount,
  shippingData,
}) => {
  const translate = useTranslate();

  // Calculate shipping tax and discount breakdown
  const shippingNet =
    shippingData?.cost ?? 0 * (1 - (shippingData?.discount ?? 0) / 100);
  const shippingTax = shippingNet * ((shippingData?.tax_rate ?? 0) / 100);

  return (
    <>
      <div style={{ textAlign: "right", padding: "8px 16px", marginTop: 16 }}>
        {/* Tax Breakdown */}
        {taxAmounts.map((tax, index) => (
          <div
            key={`net-${index}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 8,
            }}
          >
            <Text>
              {translate("settings.invoices.subtotal")} ({tax.taxRatePercentage}
              %)
            </Text>
            <Text>
              <strong>{numberToCurrency(tax?.netAmount)}</strong>
            </Text>
          </div>
        ))}
        {taxAmounts.map((tax, index) => (
          <div
            key={`tax-${index}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 8,
            }}
          >
            <Text>
              {translate("settings.invoices.taxes")} ({tax?.taxRatePercentage}%)
            </Text>
            <Text>
              <strong>{numberToCurrency(tax?.taxAmount)}</strong>
            </Text>
          </div>
        ))}

        {/* Shipping Cost Breakdown */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <Text>{translate("settings.invoices.shipping_net")}</Text>
          <Text>
            <strong>{numberToCurrency(shippingNet)}</strong>
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <Text>
            {translate("settings.invoices.shipping_tax")} (
            {shippingData?.tax_rate}%)
          </Text>
          <Text>
            <strong>{numberToCurrency(shippingTax)}</strong>
          </Text>
        </div>
      </div>

      {/* Total Amount Section */}
      <div
        style={{
          textAlign: "right",
          padding: "12px 16px",
          borderTop: "2px solid #eeeeee",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
          }}
        >
          <Text strong>{translate("settings.invoices.total_amount")}</Text>
          <Text strong style={{ fontSize: "18px" }}>
            {`${Number(totalAmount?.toFixed(2)).toLocaleString(
              i18n.language ?? "de",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )} €`}
          </Text>
        </div>
      </div>
    </>
  );
};

export default InvoiceFooter;
