import { PlusOutlined } from "@ant-design/icons";
import { useModalForm, useSelect } from "@refinedev/antd";
import { useGetIdentity, useTranslate } from "@refinedev/core";
import { Button, Form, Input, Modal, Select, Space, Typography } from "antd";
import { FormProps } from "antd/lib";
import { useMemo } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { useUserSelect } from "../../hooks/useUserSelect";
import { getContactFormatedName } from "../../utility/contactName";

type FormComponentProps = {
  formProps: FormProps;
  initialValues?: object;
};

function FormComponent({ formProps, initialValues }: FormComponentProps) {
  const translate = useTranslate();
  const { teamMembers } = useUser();
  const { selectProps: userSelectProps } = useUserSelect();
  const { data: me } = useGetIdentity<any>();
  const selectedPipeline = Form.useWatch("pipeline", formProps.form);
  const { query: pipeline_options, selectProps: pipelineSelectProps } =
    useSelect({
      resource: "pipelines",
      optionValue: "id",
      optionLabel: "name",
    });

  const { query: stage_options, selectProps: pipelineStageSelectProps } =
    useSelect({
      resource: "pipeline_stages",
      optionValue: "id",
      optionLabel: "name",
      filters: [
        { field: "pipeline", operator: "eq", value: selectedPipeline ?? 0 },
      ],
    });

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) =>
        formProps.onFinish?.({ ...values, ...initialValues })
      }
    >
      <Form.Item
        label={translate("pipeline_items.name")}
        name={["name"]}
        rules={[
          {
            required: true,
            type: "string",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={translate("pipeline_items.deal_owner")}
        name="user"
        initialValue={me?.id}
        // rules={[{ required: true }]}
      >
        <Select
          placeholder={translate("pipeline_items.select_owner")}
          {...userSelectProps}
          options={
            teamMembers?.map((user) => ({
              value: user.user_id,
              label: user.name ?? "",
            })) ?? []
          }
        />
      </Form.Item>
      <Form.Item
        name={"pipeline"}
        label={translate("pipeline_items.pipeline")}
        rules={[{ required: true }]}
      >
        <Select
          options={pipeline_options.data?.data ?? []}
          {...pipelineSelectProps}
          onChange={(value, option) => {
            formProps.form?.setFieldValue("pipeline_stage", undefined);
            pipelineSelectProps?.onChange?.(value, option);
          }}
        />
      </Form.Item>
      <Form.Item
        name={"pipeline_stage"}
        label={translate("pipeline_items.pipeline_stage")}
        rules={[{ required: true }]}
      >
        <Select
          options={stage_options.data?.data ?? []}
          {...pipelineStageSelectProps}
        />
      </Form.Item>
    </Form>
  );
}

type Props = {
  contact?: any;
};
export default function CreateDeal({ contact }: Props) {
  const { currentTeam } = useUser();
  const translate = useTranslate();

  const { modalProps, formProps, show, open } = useModalForm({
    action: "create",
    invalidates: ["all"],
    resource: "pipeline_items",
    redirect: false,
  });
  const initialValues = useMemo(
    () => ({
      account: currentTeam?.account_id ?? "",
      contact: contact?.id,
    }),
    [contact?.id, currentTeam?.account_id]
  );
  return (
    <>
      <Modal
        {...modalProps}
        centered
        title={
          <Typography.Text style={{ fontSize: "1.1rem" }}>
            {translate("contacts.create_deal", {
              name:
                contact?.firstname || contact?.lastname
                  ? getContactFormatedName(
                      "",
                      contact.firstname,
                      contact.lastname,
                      contact?.email
                    )
                  : contact?.company_name,
            })}
          </Typography.Text>
        }
      >
        <div style={{ maxHeight: "70dvh", overflowY: "auto" }}>
          {open && initialValues && (
            <FormComponent
              formProps={formProps}
              initialValues={initialValues}
            />
          )}
        </div>
      </Modal>
      <Button
        onClick={() => show()}
        style={{ fontSize: "0.80rem", fontWeight: 500, padding: 0 }}
        type="link"
      >
        <Space>
          <PlusOutlined />
          {translate("pipeline_items.add_new_deal", "Add new Deal")}
        </Space>
      </Button>
    </>
  );
}
