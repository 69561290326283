import { useList, useTranslate } from "@refinedev/core";

import { Form, Select } from "antd";
import { Rule } from "antd/es/form";

import debounce from "lodash/debounce";
import { useEffect, useState } from "react";
interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}
type Props = {
  onSelect?: (value: string) => void;
  initialValue?: any;
  teamId: string;
  label?: string | React.ReactNode;
  rules?: Rule[];
  handleClear?: () => void;
};
export default function FormAutoSelect({
  onSelect,
  initialValue,
  teamId,
  label = "From",
  rules,
  handleClear,
}: Props) {
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptionGroup[]>([]);

  const translate = useTranslate();

  const renderItem = (
    email: string | undefined,
    id: any,
    integrationAccount: any
  ) => {
    const accountType = integrationAccount?.account_type?.toUpperCase().trim();
    return {
      value: id,
      label: `${email} ${
        accountType === "MICROSOFT"
          ? "(Microsoft 365 Outlook)"
          : accountType
          ? `(${accountType})`
          : ""
      }`,
    };
  };

  const { refetch: refetchContacts } = useList<any>({
    resource: "email_sender_addresses",
    meta: { select: "*, integration_accounts(*)" },
    filters: [
      {
        operator: "contains",
        value: `%${value}%`,
        field: "email",
      },
      {
        operator: "nnull",
        value: null,
        field: "integration_accounts",
      },
      {
        operator: "eq",
        value: teamId,
        field: "account",
      },
    ],
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const orderOptionGroup = data.data.map((item) =>
          renderItem(item.email, item.id, item.integration_accounts)
        );

        setOptions(orderOptionGroup);
      },
    },
  });

  useEffect(() => {
    refetchContacts();
  }, [value]);
  return (
    <>
      <Form.Item
        name="from"
        rules={[...(rules ? rules : [{ required: true }])]}
        label={label}
        initialValue={initialValue}
      >
        <Select
          style={{
            width: "100%",
            marginRight: "1rem",
          }}
          showSearch
          options={options}
          filterOption={false}
          onSearch={debounce((value: string) => setValue(value), 300)}
          onSelect={(value: string) => (onSelect ? onSelect(value) : "")}
          onClear={handleClear}
          allowClear={Boolean(handleClear)}
        />
      </Form.Item>
    </>
  );
}
