import { Space } from "antd";
import React, { PropsWithChildren } from "react";

export default function ActivitySpace({
  children,
  className,
}: { className?: string } & PropsWithChildren) {
  return (
    <Space
      direction="vertical"
      className={className}
      style={{
        backgroundColor: "#fcfcfc",
        width: "100%",
        borderRadius: 10,
        border: "1px solid #d6d6d6",
        padding: 10,
      }}
    >
      {children}
    </Space>
  );
}
