import { DeleteOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { useEffect } from "react";
import { IField } from "../../../interfaces/custom-fields.interface";

type Props = {
  open: boolean;
  onClose: () => void;
  field?: IField;
  handleUpdateField: (field: IField) => void;
};
const currencyOptions = [
  { code: "USD", symbol: "$" },
  { code: "EUR", symbol: "€" },
  { code: "GBP", symbol: "£" },
  // Add more currencies as needed
];
export default function CustomFormSingle({
  open,
  onClose,
  field,
  handleUpdateField,
}: Props) {
  const translate = useTranslate();
  const { formProps, saveButtonProps, form } = useForm({ resource: "" });
  const required = Form.useWatch("required", form);

  useEffect(() => {
    if (!open) return;
    form.resetFields();
  }, [open, form]);

  return (
    <Drawer
      open={open}
      title={translate("custom_fields.edit_field_title")}
      onClose={() => onClose()}
      destroyOnClose
      className="customFieldsEditDrawer"
      footer={
        <Space>
          <Button type="primary" {...saveButtonProps}>
            {translate("buttons.save")}
          </Button>
          <Button onClick={() => onClose()}>
            {translate("buttons.cancel")}
          </Button>
        </Space>
      }
    >
      <Form
        {...formProps}
        initialValues={field}
        onFinish={(values: any) => {
          if (!field?.id) return;
          const col_span: 8 | 12 | 24 = values?.col_span;
          const updatedFields: any = {
            ...field,
            col_span,
          };

          if (field?.type === "text") {
            updatedFields.field = { value: values?.text, level: values?.level };
          } else if (
            field.type === "input" ||
            field.type === "select" ||
            field.type === "checkbox" ||
            field.type === "radio" ||
            field.type === "textarea" ||
            field.type === "timepicker" ||
            field.type === "datepicker" ||
            field.type === "currency" // Added currency type
          ) {
            if (values.name && "name" in field.field) {
              updatedFields.field = {
                ...updatedFields.field,
                name: values.name,
              };
            }
            if (values.label) {
              updatedFields.field = {
                ...updatedFields.field,
                label: values.label,
              };
            }
            if (typeof values.required !== "undefined") {
              updatedFields.field = {
                ...updatedFields.field,
                required: values.required,
              };
            }
            if (typeof values.required_message !== "undefined") {
              updatedFields.field = {
                ...updatedFields.field,
                required_message: values.required_message,
              };
            }

            if (field.type === "currency") {
              updatedFields.field = {
                ...updatedFields.field,
                currency_code: values.currency_code,
                currency_symbol: currencyOptions.find(
                  (item) => item.code == values.currency_code
                )?.symbol,
                show_symbol: values.show_symbol,
                decimal_places: values.decimal_places,
                name: values.name,
                label: values.label,
                required: values.required,
                required_message: values.required_message,
              };
            }

            if (
              field.type === "select" ||
              field.type === "checkbox" ||
              (field.type == "radio" && values.options)
            ) {
              updatedFields.field = {
                ...updatedFields.field,
                options: values.options,
              };
            }
            if (
              field.type === "select" &&
              typeof values.multiple !== "undefined"
            ) {
              updatedFields.field = {
                ...updatedFields.field,
                multiple: values.multiple,
              };
            }
            if (field.type === "datepicker" && values.format) {
              updatedFields.field = {
                ...updatedFields.field,
                format: values.format,
              };
            }
            if (typeof values?.field?.columns !== "undefined") {
              updatedFields.field = {
                ...updatedFields.field,
                columns: Number(values.field.columns ?? 24),
              };
            }
            if (typeof values.rows !== "undefined") {
              updatedFields.field = {
                ...updatedFields.field,
                rows: values.rows,
              };
            }
          } else if (field.type === "rating") {
            if (values.name && "name" in field.field) {
              updatedFields.field = {
                ...updatedFields.field,
                name: values.name,
              };
            }
            if (values.label) {
              updatedFields.field = {
                ...updatedFields.field,
                label: values.label,
              };
            }
            if (typeof values.allowHalf !== "undefined") {
              updatedFields.field = {
                ...updatedFields.field,
                allowHalf: values.allowHalf,
              };
            }
            if (typeof values.allowClear !== "undefined") {
              updatedFields.field = {
                ...updatedFields.field,
                allowClear: values.allowClear,
              };
            }
            if (values.tooltips) {
              updatedFields.field = {
                ...updatedFields.field,
                tooltips: values.tooltips,
              };
            }
          }

          handleUpdateField(updatedFields);
          formProps.onFinish?.({});
        }}
      >
        {field?.type === "text" && (
          <>
            <Form.Item
              label={translate("custom_fields.text")}
              name={"text"}
              initialValue={field.field.value}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              label={translate("custom_fields.level")}
              name={"level"}
              initialValue={field?.field?.level ?? false}
              style={{ marginBottom: 10 }}
            >
              <Radio.Group>
                <Radio value={1}>H1</Radio>
                <Radio value={2}>H2</Radio>
                <Radio value={3}>H3</Radio>
                <Radio value={4}>H4</Radio>
                <Radio value={5}>H5</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {field?.field && "name" in field.field && (
          <Form.Item
            label="Name"
            name={"name"}
            initialValue={field.field.name}
            hidden
          >
            <Input />
          </Form.Item>
        )}
        {field?.field && "label" in field.field && (
          <Form.Item
            label={translate("custom_fields.label")}
            name={"label"}
            initialValue={field.field.label}
          >
            <Input />
          </Form.Item>
        )}

        {field?.type === "currency" && (
          <>
            <Form.Item
              label={translate("custom_fields.currency")}
              name={"currency_code"}
              initialValue={field.field.currency_code ?? "USD"}
            >
              <Select
                onChange={(value) => {
                  const selectedCurrency = currencyOptions.find(
                    (currency) => currency.code === value
                  );
                  form.setFieldsValue({
                    currency_symbol: selectedCurrency?.symbol,
                  });
                }}
              >
                {currencyOptions.map((currency) => (
                  <Select.Option key={currency.code} value={currency.code}>
                    {currency.code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={"currency_symbol"} hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={translate("custom_fields.display_currency_as")}
              name={"show_symbol"}
              initialValue={field.field.show_symbol ?? true}
            >
              <Radio.Group>
                <Radio value={true}>{translate("custom_fields.symbol")}</Radio>
                <Radio value={false}>{translate("custom_fields.code")}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={translate("custom_fields.decimal_places")}
              name={"decimal_places"}
              initialValue={field.field.decimal_places}
            >
              <InputNumber min={0} max={10} />
            </Form.Item>
          </>
        )}

        {field?.field && "options" in field.field && (
          <Form.Item>
            <Form.List name={"options"} initialValue={field.field.options}>
              {(fields, { add, remove }) => (
                <>
                  <Typography.Paragraph>
                    {translate("custom_fields.options")}
                    {": "}
                  </Typography.Paragraph>
                  {fields.map((option, index) => {
                    return (
                      <Row
                        key={option.key}
                        style={{ marginBottom: 10, alignItems: "center" }}
                      >
                        <Col span={22}>
                          <Form.Item
                            label={`${translate("custom_fields.option")} ${
                              index + 1
                            }`}
                            name={option.name}
                            key={option.key}
                            style={{ marginBottom: 10 }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <Button
                            type="text"
                            size="small"
                            onClick={() => remove(index)}
                          >
                            <DeleteOutlined />
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  <Button block onClick={() => add()}>
                    {translate("custom_fields.add_option")}
                  </Button>
                </>
              )}
            </Form.List>
          </Form.Item>
        )}
        {field?.field && "multiple" in field.field && (
          <Form.Item
            label={translate("custom_fields.multiple")}
            name={"multiple"}
            initialValue={field?.field?.multiple ?? false}
            style={{ marginBottom: 10 }}
          >
            <Radio.Group>
              <Radio value={true}>{translate("buttons.yes")}</Radio>
              <Radio value={false}>{translate("buttons.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {field?.type === "radio" || field?.type === "checkbox" ? (
          <Form.Item
            label={translate("custom_fields.columns")}
            name={["field", "columns"]}
            style={{ marginBottom: 10 }}
          >
            <Radio.Group>
              <Radio value={6}>4</Radio>
              <Radio value={8}>3</Radio>
              <Radio value={12}>2</Radio>
              <Radio value={24}>1</Radio>
            </Radio.Group>
          </Form.Item>
        ) : null}
        {field?.field && "required" in field.field && (
          <>
            <Form.Item
              label={translate("custom_fields.required")}
              name={"required"}
              initialValue={field.field.required}
              style={{ marginBottom: 10 }}
            >
              <Radio.Group>
                <Radio value={true}>{translate("buttons.yes")}</Radio>
                <Radio value={false}>{translate("buttons.no")}</Radio>
              </Radio.Group>
            </Form.Item>
            {required && (
              <Form.Item
                label={translate("custom_fields.required_message")}
                name={"required_message"}
                initialValue={field.field.required_message}
              >
                <Input />
              </Form.Item>
            )}
          </>
        )}
        {field?.field && "tooltips" in field.field && (
          <Form.Item>
            <Form.List name={"tooltips"} initialValue={field.field.tooltips}>
              {(fields) => (
                <>
                  <Typography.Paragraph>
                    {translate("custom_fields.tooltips")}
                    {": "}
                  </Typography.Paragraph>
                  {fields.map((option, index) => {
                    return (
                      <Row key={option.key} style={{ marginBottom: 10 }}>
                        <Col span={24}>
                          <Form.Item
                            label={`${index + 1}`}
                            name={option.name}
                            key={option.key}
                            style={{ marginBottom: 10 }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )}
            </Form.List>
          </Form.Item>
        )}
        {field?.field && "allowClear" in field.field && (
          <>
            <Form.Item
              label={translate("custom_fields.allow_clear")}
              name={"allowClear"}
              initialValue={field.field.allowClear}
              style={{ marginBottom: 10 }}
            >
              <Radio.Group>
                <Radio value={true}>{translate("buttons.yes")}</Radio>
                <Radio value={false}>{translate("buttons.no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {field?.field && "allowHalf" in field.field && (
          <>
            <Form.Item
              label={translate("custom_fields.allow_half")}
              name={"allowHalf"}
              initialValue={field.field.allowHalf}
              style={{ marginBottom: 10 }}
            >
              <Radio.Group>
                <Radio value={true}>{translate("buttons.yes")}</Radio>
                <Radio value={false}>{translate("buttons.no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {field?.field && "rows" in field.field && (
          <Form.Item
            label={translate("custom_fields.rows")}
            name={"rows"}
            initialValue={field.field.rows}
          >
            <InputNumber min={1} max={10} />
          </Form.Item>
        )}
        <Form.Item
          label={translate("custom_fields.column_span")}
          name={"col_span"}
          initialValue={field?.col_span}
          style={{ marginBottom: 10 }}
        >
          <Radio.Group>
            <Radio value={12}>50%</Radio>
            <Radio value={24}>100%</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
