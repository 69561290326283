import { useForm } from "@refinedev/antd";
import {
  CanAccess,
  useInvalidate,
  useNavigation,
  useNotification,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
  Segmented,
  Select,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { PropsWithChildren, useState } from "react";
import AddressAutoselect from "../../../components/common/AddressAutoselect";
import ContactAutoSelect from "../../../components/contacts/ContactAutoSelect";
import InvoiceTable from "../../../components/shop/product-items";
import { useSubscriptionProductSelect } from "../../../hooks/useSubscriptionProductSelect";
import { supabaseClient } from "../../../utility";

const OrdersCreate: React.FC<PropsWithChildren> = ({ children }) => {
  const translate = useTranslate();
  const [selectedContact, setSelectedContact] = useState<string>("");
  const [selectedInvoiceAddress, setSelectedInvoiceAddress] =
    useState<string>("");
  const [productItems, setProductItems] = useState<any>();
  const [shippingCost, setShippingCost] = useState<any>();
  const [deliveryContact, setDeliveryContact] = useState<string | undefined>(
    undefined
  );

  const [differentDelivery, setDifferentDelivery] = useState(false);
  const handleToggleDelivery = () => {
    setDifferentDelivery((prev) => !prev);
  };

  const [type, setType] = React.useState("simple");
  const [shippingType, setShippingType] = React.useState();

  const { list } = useNavigation();
  const { open } = useNotification();
  const invalidate = useInvalidate();

  const { query: productResult, selectProps: phaseSelectProp } =
    useSubscriptionProductSelect();

  const { form, formProps, saveButtonProps } = useForm({
    resource: "orders",
    action: "create",
    redirect: false,
    invalidates: ["resourceAll"],
  });

  // useEffect(() => {
  //   //form.setFieldsValue({ delivery_contact: selectedContact });
  //   // setDeliveryContact(selectedContact);
  //   // console.log("set delivery contact", selectedContact);
  // }, [differentDelivery, selectedContact, form]);

  const handleFinish = async ({ startDate, order_date, ...values }: any) => {
    const payload: any = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      order_date: dayjs(order_date).format("YYYY-MM-DD"),
      subscriptionProductId: values.subscription_product,
      invoiceContactId: parseInt(selectedContact),
      invoiceContactAddressId: values.invoice_contact_address,
      type: values.type,
      shipping_type: shippingType,
      shipping_cost: shippingCost,
      product_items: productItems,
      deliveryContactId: values?.deliveryContactId ?? parseInt(selectedContact),
      deliveryContactAddressId:
        values?.delivery_contact_address ?? values.invoice_contact_address,
    };

    if (deliveryContact?.trim()) {
      payload.deliveryContactId = deliveryContact;
      if (values.delivery_contact_address) {
        payload.deliveryContactAddressId = values.delivery_contact_address;
      }
    }

    try {
      const { data, error } = await supabaseClient.functions.invoke(
        "shop-core/order/create",
        {
          body: {
            ...payload,
          },
          method: "POST",
        }
      );

      if (error) {
        console.log("error", data);
        open?.({
          description: translate("alerts.toast.error"),
          message: error?.error,
          type: "error",
        });
        console.error("Error creating subscription:", error);
      } else {
        open?.({
          description: translate("notifications.success"),
          message: translate("notifications.createSuccess"),
          type: "success",
        });
        invalidate({
          invalidates: ["all"],
          resource: "orders",
        });
        list("orders", "replace");
      }
    } catch (err) {
      console.error("Unexpected error:", err);
    }
  };

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <>
        <Modal
          open
          maskClosable={false}
          title={translate("orders.create.title")}
          onCancel={() => {
            list("orders", "replace");
          }}
          okText={translate("buttons.save")}
          okButtonProps={{
            ...saveButtonProps,
          }}
          width={1000}
        >
          <Form {...formProps} layout="vertical" onFinish={handleFinish}>
            <Row gutter={[40, 10]}>
              <Col span={12}>
                <Form.Item
                  name="type"
                  label={translate("orders.fields.type")}
                  rules={[
                    { required: true, message: "Please select the type" },
                  ]}
                  initialValue={type}
                >
                  <Segmented
                    options={[
                      {
                        label: translate("orders.type.simple"),
                        value: "simple",
                      },
                      {
                        label: translate("orders.type.subscription"),
                        value: "subscription",
                      },
                    ]}
                    onChange={(value) => setType(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={translate("subscriptions.fields.order_date")}
                  name="order_date"
                  initialValue={dayjs()}
                >
                  <DatePicker format={translate("global.date_format")} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <ContactAutoSelect
                  placeholder={translate("contacts.search_contact_placeholder")}
                  onSelect={(value) => {
                    setSelectedContact(value);
                    form.setFieldsValue({ invoice_contact: value });
                    if (!differentDelivery) setDeliveryContact(value);
                  }}
                  disableCreate={false}
                  name="invoice_contact"
                  initialValue={selectedContact}
                  label={translate("subscriptions.fields.user")}
                />

                {selectedInvoiceAddress && !differentDelivery && (
                  <Button
                    size={"small"}
                    type="link"
                    onClick={handleToggleDelivery}
                  >
                    {differentDelivery
                      ? "Lieferdaten ausblenden"
                      : "Abweichende Lieferdaten"}
                  </Button>
                )}
              </Col>
              <Col span={12}>
                {selectedContact && (
                  <AddressAutoselect
                    onSelect={(value) => {
                      setSelectedInvoiceAddress(value);
                      form.setFieldsValue({ invoice_contact_address: value });
                    }}
                    name={"invoice_contact_address"}
                    contactId={selectedContact}
                    label={translate(
                      "subscriptions.fields.invoice_contact_address"
                    )}
                  />
                )}
              </Col>

              <Col span={12}>
                {selectedInvoiceAddress && (
                  <>
                    {differentDelivery && (
                      <ContactAutoSelect
                        onSelect={(value) => {
                          setDeliveryContact(value);
                        }}
                        name="delivery_contact"
                        initialValue={selectedContact}
                        label={translate(
                          "subscriptions.fields.delivery_contact"
                        )}
                        required={false}
                      />
                    )}
                  </>
                )}
              </Col>

              <Col span={12}>
                {differentDelivery && (
                  <>
                    {deliveryContact && (
                      <AddressAutoselect
                        onSelect={(value) => {
                          form.setFieldsValue({
                            delivery_contact_address: value,
                          });
                        }}
                        name={"delivery_contact_address"}
                        contactId={selectedContact}
                        label={translate(
                          "subscriptions.fields.delivery_contact_address"
                        )}
                      />
                    )}
                  </>
                )}
              </Col>

              {selectedInvoiceAddress && (
                <>
                  <Divider />
                  <Col span={24}>
                    <Typography.Text strong>
                      {translate(`orders.type.${type}_details`)}
                    </Typography.Text>
                  </Col>

                  {type === "subscription" && (
                    <>
                      <Col span={12}>
                        <Form.Item
                          name={"subscription_product"}
                          label={translate(
                            "orders.fields.subscription_product"
                          )}
                          rules={[{ required: true }]}
                        >
                          <Select
                            style={{ width: "100%" }}
                            {...phaseSelectProp}
                            options={productResult.data?.data.map((item) => ({
                              value: item.id,
                              label: `${item?.product_item?.title} - ${item.name}`,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {type === "simple" && (
                    <Col span={7}>
                      <Form.Item
                        label={translate("orders.fields.shipping_type")}
                        name="shippingType"
                      >
                        <Select
                          allowClear
                          style={{ minWidth: 200 }}
                          onChange={(value) => setShippingType(value)}
                          options={[
                            {
                              label: translate("orders.shipping_type.delivery"),
                              value: "delivery",
                            },
                            {
                              label: translate("orders.shipping_type.service"),
                              value: "service",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={6}>
                    <Form.Item
                      label={
                        shippingType
                          ? translate(
                              `subscriptions.fields.execution_date_${shippingType}`
                            )
                          : translate(`subscriptions.fields.execution_date`)
                      }
                      name="startDate"
                      initialValue={dayjs()}
                    >
                      <DatePicker format={translate("global.date_format")} />
                    </Form.Item>
                  </Col>
                  {type === "simple" && (
                    <Col span={24}>
                      <InvoiceTable
                        onProductsChange={(value) => setProductItems(value)}
                        onShippingCost={(value) => setShippingCost(value)}
                        subscriptionProduct={true}
                      />
                    </Col>
                  )}
                </>
              )}
            </Row>
          </Form>
        </Modal>
        {children}
      </>
    </CanAccess>
  );
};

export default OrdersCreate;
