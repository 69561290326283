import { CommentOutlined } from "@ant-design/icons";
import {
  BaseKey,
  CrudFilter,
  MetaQuery,
  useInfiniteList,
  useList,
  useSubscription,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { Button, Flex, Timeline } from "antd";
import { TimelineItemProps } from "antd/lib";
import React, { useEffect, useRef } from "react";
import { useUser } from "../../../contexts/ContextProvider";
import {
  Activity,
  ActivityResource,
  ActivityType,
} from "../../../interfaces/activity.interface";
import { singularizeResource } from "../../../utility/transform";
import CommentForm from "./CommentForm";
import TimelineItem from "./TimelineItem";

type Props = {
  itemId?: BaseKey | undefined;
  resource: ActivityResource;
  filters?: CrudFilter[];
  meta?: MetaQuery;
};

const ActivityTypeHandler = async (
  item: Activity,
  teamMembers?: any,
  pipelines?: any
) => {
  if (item.type === ActivityType.Note && item.value?.created_by) {
    item.value.created_by_name =
      teamMembers.find((u) => u.user_id === item.value?.created_by)?.name ?? "";
    if (item.value.files) {
      item.value.files = item.value.files.map((file) => ({
        ...file,
        name: file?.name?.slice(file.name.indexOf("-") + 1),
      }));
    }
  }
  if (item.type === ActivityType.PipelineChange) {
    const fromPipeline = pipelines.find(
      (p) => p.id == item.value.from.pipeline
    );
    const toPipeline = pipelines.find((p) => p.id == item.value.to.pipeline);
    item.value = {
      from: {
        pipeline: fromPipeline?.name,
        stage: fromPipeline?.pipeline_stages?.find(
          (st) => st.id == item.value.from.stage
        ).name,
      },
      to: {
        pipeline: toPipeline?.name,
        stage: toPipeline?.pipeline_stages?.find(
          (st) => st.id == item.value.to.stage
        ).name,
      },
    };
  }
  return item;
};

export const ActivityLog = ({ itemId, resource, filters, meta }: Props) => {
  const { teamMembers, currentTeam } = useUser();
  const { mutateAsync: updateSingle } = useUpdate();
  const { data: pipelines } = useList({
    resource: "pipelines",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
    meta: {
      select: "*, pipeline_stages!pipeline_stages_pipeline_fkey(id,name)",
    },
  });
  const memoizedPipelines = React.useMemo(
    () => pipelines?.data,
    [pipelines?.data]
  );
  const [itemsToRender, setItemsToRender] = React.useState<TimelineItemProps[]>(
    []
  );
  const translate = useTranslate();

  const { data, hasNextPage, fetchNextPage, refetch } = useInfiniteList<any>({
    resource: "activities",
    filters: [
      {
        field: singularizeResource(resource),
        operator: "eq",
        value: itemId,
      },
      ...(filters ? filters : []),
    ],
    meta: { ...meta },
    liveMode: "off",
    sorters: [{ field: "created_at", order: "desc" }],
    pagination: { pageSize: 10 },
  });

  useSubscription({
    channel: `activities`,
    params: {
      filters: [
        { field: singularizeResource(resource), operator: "eq", value: itemId },
      ],
    },
    onLiveEvent: () => {
      refetch();
    },
  });

  const allPages = React.useMemo(
    () =>
      [].concat(...(data?.pages ?? []).map((page: any) => page.data)).reverse(),
    [data?.pages]
  );

  const onDelete = React.useCallback(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timelineRef = useRef<HTMLDivElement>(null); // Create a ref for the Timeline

  useEffect(() => {
    const items = async () => {
      const res = await Promise.all(
        allPages.map(async (item: Activity) => {
          const processedItem = await ActivityTypeHandler(
            item,
            teamMembers,
            memoizedPipelines
          );
          return TimelineItem(
            processedItem,
            translate,
            onDelete,
            teamMembers,
            updateSingle
          );
        })
      );

      setItemsToRender(res);
    };
    items();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPages, memoizedPipelines, teamMembers]);

  // Scroll to bottom whenever itemsToRender changes
  useEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.scrollTop = timelineRef.current.scrollHeight;
    }
  }, [itemsToRender]);

  return (
    <Flex
      vertical
      style={{
        marginTop: 30,
        paddingInline: "5px",
      }}
    >
      {hasNextPage && (
        <Button onClick={() => fetchNextPage()}>
          {translate("buttons.see_more")}
        </Button>
      )}
      <div
        ref={timelineRef}
        style={{
          overflowY: resource == "pipeline_items" ? "auto" : "scroll",
          height:
            resource == "pipeline_items" ? "auto" : "calc(100dvh - 250px)",
          padding: "15px 5px 0 5px",
        }}
      >
        <Timeline
          className="activityTimeline"
          pendingDot={<CommentOutlined style={{ paddingTop: 40 }} />}
          pending={<CommentForm itemId={itemId} resource={resource} />}
          items={itemsToRender ?? []}
        />
      </div>
    </Flex>
  );
};
