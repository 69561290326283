import { useTranslate } from "@refinedev/core";
import { Form, InputNumber, Select, Space } from "antd";
import { FormInstance } from "antd/es/form";
import React, { useEffect, useState } from "react";

interface PriceFieldsProps {
  form: FormInstance;
}

const PriceFields: React.FC<PriceFieldsProps> = ({ form }) => {
  const translate = useTranslate();

  // Observing the values from the form
  const nettoPrice = Form.useWatch("netto_price", form);
  const salesTax = Form.useWatch("sales_tax", form);

  // Last changed field: "netto_price" or "gross_price"
  const [lastChangedPrice, setLastChangedPrice] = useState<
    "netto_price" | "gross_price" | null
  >(null);

  // Calculate gross_price based on the current values
  const factor = 1 + (salesTax ?? 0) / 100;
  const calculatedGrossPrice = (nettoPrice ?? 0) * factor;

  // State for the currently displayed gross_price
  const [grossPrice, setGrossPrice] = useState<number>(calculatedGrossPrice);

  useEffect(() => {
    // Ensure sales_tax default value is set to 19 if not already set
    if (!form.getFieldValue("sales_tax")) {
      form.setFieldsValue({ sales_tax: 19 });
    }
    // const nettoPrice = form.getFieldValue("netto_price");
    // if (nettoPrice === null || nettoPrice === undefined || nettoPrice === "") {
    //   form.setFieldsValue({ netto_price: 0 });
    // }
  }, [form]);

  useEffect(() => {
    // If netto_price or sales_tax changes and
    // the last changed was netto or sales_tax,
    // recalculate gross_price.
    if (lastChangedPrice === "netto_price" || lastChangedPrice === null) {
      setGrossPrice(calculatedGrossPrice);
    } else if (lastChangedPrice === "gross_price") {
      // If sales_tax changes, but gross_price was last changed,
      // then netto_price must be recalculated and written to the form.
      const newNetto = grossPrice / factor;
      form.setFieldsValue({ netto_price: newNetto });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nettoPrice, salesTax]);

  return (
    <Space>
      <Form.Item
        name="netto_price"
        label={translate("product_items.fields.netto_price")}
      >
        <InputNumber
          addonAfter="EUR"
          onChange={() => {
            setLastChangedPrice("netto_price");
            // Netto changes -> grossPrice is recalculated in useEffect.
          }}
          decimalSeparator={translate("global.decimal_seperator")}
          precision={2}
          parser={(valueStr: any) => {
            const currentSeparator = translate("global.decimal_seperator");
            let sanitized = valueStr.replace(/[^\d.,-]/g, "");

            if (currentSeparator === ".") {
              sanitized = sanitized.replace(",", ".");
              return parseFloat(sanitized);
            } else {
              sanitized = sanitized.replace(",", ".");
              return parseFloat(sanitized);
            }
          }}
        />
      </Form.Item>

      <Form.Item
        name="sales_tax"
        label={translate("product_items.fields.sales_tax")}
      >
        <Select
          popupMatchSelectWidth={false}
          onChange={() => {
            // sales_tax changed
            // The calculation is done in useEffect depending on lastChangedPrice.
          }}
        >
          <Select.Option value={19}>19%</Select.Option>
          <Select.Option value={7}>7%</Select.Option>
          <Select.Option value={0}>0%</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={translate("product_items.fields.gross_price")}
        // gross_price is not stored in the form!
      >
        <InputNumber
          precision={2}
          addonAfter="EUR"
          value={grossPrice}
          onChange={(value) => {
            // The user changed gross_price -> now recalculate netto_price based on gross_price.
            if (typeof value === "number") {
              setLastChangedPrice("gross_price");
              const newNetto = value / factor;
              form.setFieldsValue({ netto_price: newNetto });
              setGrossPrice(value); // Update grossPrice
            }
          }}
          decimalSeparator={translate("global.decimal_seperator")}
          parser={(valueStr: any) => {
            const currentSeparator = translate("global.decimal_seperator");
            let sanitized = valueStr.replace(/[^\d.,-]/g, "");

            if (currentSeparator === ".") {
              sanitized = sanitized.replace(",", ".");
              return parseFloat(sanitized);
            } else {
              sanitized = sanitized.replace(",", ".");
              return parseFloat(sanitized);
            }
          }}
        />
      </Form.Item>
    </Space>
  );
};

export default PriceFields;
