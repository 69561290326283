import React from "react";
import { Form, Input } from "antd";
import { useTranslate } from "@refinedev/core";

const URLSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.url.label")}
      name="url"
    >
      <Input placeholder={translate("contacts.url.placeholder")} />
    </Form.Item>
  );
};

export default URLSection;
