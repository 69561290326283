import {
  BellOutlined,
  UserOutlined,
  WechatWorkOutlined,
} from "@ant-design/icons";
import { DeleteButton } from "@refinedev/antd";
import {
  Button,
  Popconfirm,
  Space,
  Tag,
  TimelineItemProps,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import dayjs from "dayjs";
import { NoteType } from "../../../../interfaces/activity.interface";
import { supabaseClient } from "../../../../utility";
import ActivitySpace from "../../../common/ActivitySpace";
import styles from "./style.module.css";

export default function NoteItem(
  item: NoteType,
  translate: any,
  onDelete: () => void,
  teamMembers?: any,
  updateSingle?: (any) => void
): TimelineItemProps {
  // const { mutateAsync: updateSingle } = useUpdate();

  const showModal = async (file: UploadFile) => {
    const dataURL = await getFileDataURL(file);
    if (dataURL) {
      window.open(dataURL, "_blank");
    } else {
      console.error("Failed to retrieve the file URL.");
    }
  };
  const getFileDataURL = async (fileObj: any) => {
    try {
      const { data, error } = await supabaseClient.storage
        .from("files")
        .createSignedUrl(fileObj.path, 60);

      if (error) {
        console.error("Error downloading image:", error);
      } else if (data) {
        try {
          return data.signedUrl;
        } catch (error) {
          console.error("Error reading image:", error);
        }
      } else {
        console.error("No data or error returned");
      }
    } catch (error) {
      console.error("Fehler beim Herunterladen der Datei:", error);
      return null;
    }
  };

  return {
    children: (
      <ActivitySpace className={styles.card}>
        <Space
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Space size={"small"} style={{ width: "100%" }}>
            <Typography.Text
              strong
              style={{ fontSize: "12px", color: "#lightGrey" }}
            >
              {translate("notes.note") + " "}
              {item.value.created_by_name ? translate("contacts.by_colon") : ""}
              {item.value.created_by_name
                ? " " + item.value.created_by_name
                : ""}
            </Typography.Text>
            {item?.value?.due_date && (
              <Popconfirm
                title={translate("activity.due_date")}
                description={translate("activity.due_date_description")}
                placement="leftBottom"
                onConfirm={async (e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  updateSingle &&
                    updateSingle({
                      resource: "activities",
                      id: item.id,
                      values: {
                        value: {
                          ...item.value,
                          resolve: item.value.resolve ? undefined : true,
                        },
                      },
                    });
                }}
                okText={translate("buttons.yes")}
                cancelText={translate("buttons.no")}
              >
                <Tag
                  color={item?.value?.resolve == true ? "green" : "default"}
                  style={{ cursor: "pointer" }}
                >
                  <BellOutlined />{" "}
                  {dayjs(item?.value?.due_date)?.format(
                    translate("global.date_format")
                  )}
                </Tag>
              </Popconfirm>
            )}
            {item?.value?.assignedTo &&
              item?.value?.assignedTo.map((item) => (
                <Tag key={item}>
                  <UserOutlined />{" "}
                  {teamMembers?.find((user) => user.user_id == item)?.name}
                </Tag>
              ))}
          </Space>
          <Typography.Paragraph italic style={{ fontSize: "10px" }}>
            {dayjs(item.created_at).format(translate("global.date_format"))}
          </Typography.Paragraph>
        </Space>
        {item.value?.text != undefined && (
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: item.value?.text ?? "" }}
          ></div>
        )}
        <div style={{ width: "fit-content" }}>
          {item?.value?.files?.length > 0 && (
            <Upload
              itemRender={(_originNode, file, _filelist, actions) => {
                return (
                  <Button
                    size="small"
                    onClick={() => {
                      showModal(file); // Use the `file` object directly
                    }}
                  >
                    {file?.name}
                  </Button>
                );
              }}
              fileList={item.value.files}
              showUploadList={{ showRemoveIcon: false }}
              listType="text"
              className="note-item-file-container"
            />
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <DeleteButton
            className={styles.deleteBtn}
            recordItemId={item.id}
            size="small"
            resource="activities"
            invalidates={["all"]}
            onSuccess={() => {
              onDelete();
            }}
            hideText
          />
        </div>
      </ActivitySpace>
    ),
    dot: (
      <WechatWorkOutlined
        size={30}
        style={{
          fontSize: 15,
          backgroundColor: "transparent",
          borderRadius: "50%",
          padding: "0.2rem",
        }}
      />
    ),
  };
}
