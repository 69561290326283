import { TimelineItemProps } from "antd/es/timeline";
import { Activity, ActivityType } from "../../../interfaces/activity.interface";
import NoteItem from "./ActivityItems/NoteItem";
import PipelineChangeItem from "./ActivityItems/PipelineChangeItem";

export default function TimelineItem(
  item: Activity,
  translate: any,
  onDelete: () => void,
  teamMembers?: any,
  updateSingle?: (params: any) => Promise<any>
): TimelineItemProps {
  if (item.type == ActivityType.Note) {
    return NoteItem(item, translate, onDelete, teamMembers, updateSingle);
  }
  if (item.type == ActivityType.PipelineChange) {
    return PipelineChangeItem(item);
  }
  return {
    children: "",
  };
}
