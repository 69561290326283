import React, { useState } from "react";
import { List, useTable, useModalForm } from "@refinedev/antd";
import { useInvalidate, useTranslate, useNotification, useList } from "@refinedev/core";
import {
  Table,
  Button,
  Space,
  InputNumber,
  Select,
  Form,
  Modal,
  Popconfirm,
} from "antd";
import { useContactContext } from "../../contexts/ContactProvider";
import { supabaseClient } from "../../utility"; // Supabase Client korrekt importieren
import { DeleteOutlined } from "@ant-design/icons";
import { useUser } from "../../contexts/ContextProvider";

type ProductCategory = {
  id: number;
  name: string;
};

export default function ContactDiscountsList() {
  const [openCreate, setOpenCreate] = useState(false);
  const [form] = Form.useForm();
  const translate = useTranslate();
  const { id: contactId } = useContactContext();
  const { open } = useNotification();
  const invalidate = useInvalidate();
  const { currentTeam, personalAccount } = useUser();

  // Load product categories
  const { data: productCategoriesData } = useList<ProductCategory>({
    resource: "product_categories",
  });

  const productCategories = productCategoriesData?.data || [];

  const { tableProps, tableQuery } = useTable({
    resource: "contact_discounts",
    meta: {
      select: `
      id,
      discount_value,
      product_categories (
        id,
        name
      )
    `,
    },
    filters: {
      permanent: [{ field: "contact", operator: "eq", value: contactId ?? 0 }],
    },
    syncWithLocation: false,
    sorters: {
      initial: [{ field: "created_at", order: "desc" }],
    },
  });

  const handleDelete = async (discountId: any) => {
    try {
      const { error } = await supabaseClient
        .from("contact_discounts")
        .delete()
        .eq("id", discountId);

      if (error) {
        open?.({
          description: translate("notifications.error"),
          message: error.message,
          type: "error",
        });
      } else {
        open?.({
          description: translate("notifications.success"),
          message: translate("contacts.discounts.delete_success", "Deleted successfully"),
          type: "success",
        });
        invalidate({
          resource: "contact_discounts",
          invalidates: ["list"],
        });
        await tableQuery.refetch();
      }
    } catch (error) {
      open?.({
        description: translate("notifications.error"),
        message: (error as Error).message,
        type: "error",
      });
    }
  };

  const handleCreate = async (values: any) => {
    try {
      const { error } = await supabaseClient.from("contact_discounts").insert([
        {
          contact: contactId,
          product_category: values.product_category,
          discount_value: values.discount_value,
          account: currentTeam?.account_id,
          user: personalAccount?.account_id,
        },
      ]);

      if (error) {
        open?.({
          description: translate("notifications.error"),
          message: error.message,
          type: "error",
        });
      } else {
        open?.({
          description: translate("notifications.success"),
          message: translate("contacts.discounts.create_success"),
          type: "success",
        });
        invalidate({
          resource: "contact_discounts",
          invalidates: ["list"],
        });
        await tableQuery.refetch();
        form.resetFields();
        setOpenCreate(false);
      }
    } catch (error) {
      open?.({
        description: translate("notifications.error"),
        message: (error as Error).message,
        type: "error",
      });
    }
  };

  return (
    <>
      <List
        title={false}
        breadcrumb={false}
        createButtonProps={{
          onClick: () => setOpenCreate(true),
          children: translate("contacts.discounts.add_discount"),
        }}
      >
        <Table
          {...tableProps}
          className="clickableRow"
          columns={[
            {
              title: translate("contacts.discounts.product_category", "Product Category"),
              dataIndex: ["product_categories", "name"], // Korrekte Datenstruktur
              key: "product_category",
            },
            {
              title: translate("contacts.discounts.discount_value", "Discount Value"),
              dataIndex: "discount_value",
              key: "discount_value",
              render: (value) => `${value}%`,
            },
            {
              key: "action",
              title: translate("contacts.discounts.actions"),
              render: (_text: any, record: any) => (
                <Space>
                  <Popconfirm
                    title={translate("contacts.discounts.delete_confirm")}
                    onConfirm={async (event) => {
                      event?.preventDefault();
                      event?.stopPropagation();
                      await handleDelete(record.id);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Space
                      style={{ color: "red" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      <DeleteOutlined />
                    </Space>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </List>

      {/* Modal for Creating a Discount */}
      <Modal
        open={openCreate}
        title={translate("contact_discounts.create_discount", "Create Discount")}
        onCancel={() => setOpenCreate(false)}
        onOk={() => form.submit()}
      >
        <Form form={form} onFinish={handleCreate} layout="vertical">
          <Form.Item
            name="product_category"
            label={translate("contact_discounts.product_category", "Product Category")}
            rules={[
              {
                required: true,
                message: translate(
                  "contact_discounts.validation.select_product_category",
                  "Please select a product category"
                ),
              },
            ]}
          >
            <Select
              placeholder={translate("contact_discounts.select_product_category", "Select a product category")}
              options={productCategories.map((category) => ({
                label: category.name,
                value: category.id,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="discount_value"
            label={translate("contact_discounts.discount_value", "Discount Value")}
            rules={[
              {
                required: true,
                message: translate(
                  "contact_discounts.validation.enter_discount_value",
                  "Please enter a discount value"
                ),
              },
              {
                type: "number",
                min: 0,
                max: 100,
                message: translate(
                  "contact_discounts.validation.discount_value_range",
                  "Discount value must be between 0 and 100"
                ),
              },
            ]}
          >
            <InputNumber min={0} max={100} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
