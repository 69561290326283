import {
  ArrowRightOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigation, useTranslate } from "@refinedev/core";
import { Button, Flex, Modal, Space, Typography } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import CustomFormBuilderPage from "../custom-fields/CustomFormBuilderPage";
import StageSelector from "../pipeline-items/StageSelector";
import { SingleElementFormV2 } from "../single-element-form/single-element-form-v2";
import { Text } from "../text";

type Props = {
  contactDeals: any[];
  refetchPipelines: () => void;
};
export default function ContactDeals({
  contactDeals,
  refetchPipelines,
}: Props) {
  const [currentDeal, setCurrentDeal] = useState<{
    pipelineItem: any;
    pipeline: any;
  }>();
  const { currentTeam, getTeamMember } = useUser();
  const translate = useTranslate();
  const { push } = useNavigation();
  return (
    <>
      {contactDeals?.map((pipeline: any) => (
        <Space
          size={"small"}
          direction="vertical"
          style={{ width: "100%" }}
          key={pipeline.id}
        >
          {pipeline.pipeline_items.map((pipelineItem: any) => (
            <Space
              size={"small"}
              direction="vertical"
              style={{ width: "100%" }}
              key={pipelineItem.id}
            >
              <Flex
                justify="start"
                align="center"
                gap={5}
                onClick={() =>
                  setCurrentDeal({
                    pipelineItem: pipelineItem,
                    pipeline: pipeline,
                  })
                }
              >
                <Typography.Text className="clickableRow">
                  {pipelineItem.name} ({pipeline.name})
                </Typography.Text>
                <Typography.Text type="secondary">
                  {translate("contacts.latest_update")}
                  {": "}
                  {dayjs(
                    pipelineItem.stage_updated_at ?? pipelineItem.created_at
                  ).format(translate("page.settings.date_format"))}{" "}
                  {translate("page.settings.clock")}
                </Typography.Text>
              </Flex>
              {currentTeam?.account_id && (
                <StageSelector
                  itemId={pipelineItem.id}
                  onUpdate={() => refetchPipelines()}
                />
              )}
            </Space>
          ))}
        </Space>
      ))}
      <Modal
        open={!!currentDeal}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        centered
        styles={{
          header: { borderBottom: "none" },
          footer: { borderTop: "none" },
          body: { maxHeight: "50dvh", overflow: "auto" },
        }}
        onCancel={() => setCurrentDeal(undefined)}
        title={
          <Space direction="vertical" size={"small"} style={{ gap: 0 }}>
            <Typography.Text style={{ fontSize: "1.1rem" }}>
              {currentDeal?.pipelineItem.name}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontWeight: "normal" }}>
              {currentDeal?.pipeline.name}
            </Typography.Text>
          </Space>
        }
        footer={
          <Flex justify="flex-end" align="center">
            <Button
              onClick={() =>
                push(
                  `/${currentTeam?.slug}/pipelines/show/${currentDeal?.pipeline.id}/items/${currentDeal?.pipelineItem.id}`
                )
              }
              icon={<ArrowRightOutlined />}
              iconPosition="end"
            >
              {translate("contacts.view_full_details")}
            </Button>
          </Flex>
        }
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {currentDeal?.pipelineItem?.name && (
            <SingleElementFormV2
              state="view"
              disableHoverBg
              itemProps={{ label: "Name", name: "pipeline_item_name" }}
              icon={<FileDoneOutlined />}
              iconStyle={{ alignSelf: "start", marginTop: 1 }}
              view={
                <Space size={"middle"} align="start">
                  <Text>{currentDeal?.pipelineItem.name}</Text>
                </Space>
              }
            />
          )}
          {currentDeal?.pipelineItem?.user && (
            <SingleElementFormV2
              disableHoverBg
              state="view"
              itemProps={{
                label: translate("pipeline_items.deal_owner"),
                name: "pipeline_item_name",
              }}
              icon={<UserOutlined />}
              iconStyle={{ alignSelf: "start", marginTop: 1 }}
              view={
                <Space size={"middle"} align="start">
                  <Text>
                    {getTeamMember(currentDeal?.pipelineItem.user)?.name}
                  </Text>
                </Space>
              }
            />
          )}
          {currentDeal?.pipelineItem?.reminder_date && (
            <SingleElementFormV2
              disableHoverBg
              state="view"
              itemProps={{
                label: translate("pipeline_items.reminder_date"),
                name: "pipeline_item_name",
              }}
              icon={<FieldTimeOutlined />}
              iconStyle={{ alignSelf: "start", marginTop: 1 }}
              view={
                <Space size={"middle"} align="start">
                  <Text>
                    {new Date(
                      currentDeal?.pipelineItem?.reminder_date
                    ).toLocaleDateString(translate("pipelines.date_locale"))}
                  </Text>
                </Space>
              }
            />
          )}
          {currentDeal?.pipelineItem.custom_fields && (
            <CustomFormBuilderPage
              mode={"pureView"}
              setFields={() => null}
              fields={currentDeal.pipeline.custom_fields_json}
              data={currentDeal?.pipelineItem.custom_fields}
              disableHoverBg
            />
          )}
        </Space>
      </Modal>
    </>
  );
}
