import { BaseOption, useList, useTranslate, useUpdate } from "@refinedev/core";

import { Form, Input, Select, Space, Spin, Switch } from "antd";
import { Typography } from "antd/lib";
import { useState } from "react";
import BasicContact from "../../components/contacts/basic-contact";
import { useContactContext } from "../../contexts/ContactProvider";
import { useUser } from "../../contexts/ContextProvider";
import { usePipelineContext } from "../../contexts/PipelineProvider";
import { useStageSelect } from "../../hooks/useStageSelect";
import { SingleElementFormV2 } from "../single-element-form/single-element-form-v2";
import ContactDeals from "./ContactDeals";
import CompanyContactsTable from "./contacts-table";
import CreateDeal from "./CreateDeal";

export default function ContactInfo() {
  const [activeForm, setActiveForm] = useState<"payment_deadline">();
  const { currentTeam } = useUser();
  const { canAccess } = usePipelineContext();
  const { refetch, ...contact_data } = useContactContext();
  const { mutateAsync: updateSingle, isLoading } = useUpdate();
  const {
    id,
    tags,
    phone_numbers,
    firstname,
    lastname,
    company_name,
    job_title,
    contact_has_contact,
    email,
    salutation,
    schemaField,
    override_salutation,
    delivery_block,
    name_prefix,
    url,
    addresses,
    custom_fields,
    email_invoices,
    marketing_optout,
    invoice_postal_mail,
    suppress_invoice_reminders,
    suppress_invoice_reminders_stage,
    payment_deadline,
  } = contact_data;
  const { data: contactList } = useList({
    resource: "contact_has_contact",
    filters: [
      {
        operator: "or",
        value: [{ field: "child", operator: "eq", value: id }],
      },
    ],
  });
  const translate = useTranslate();

  const { data: dataDeals, refetch: refetchPipelines } = useList({
    resource: "pipelines",
    sorters: [{ field: "created_at", order: "desc" }],
    filters: [
      { field: "pipeline_items.contact", operator: "eq", value: id },
      {
        field: "account",
        value: currentTeam?.account_id,
        operator: "eq",
      },
    ],
    pagination: {
      mode: "off",
    },
    meta: {
      select: `*, pipeline_items!inner(*)`,
    },
  });

  const { selectProps: stageSelectProps } = useStageSelect(
    undefined,
    "*,pipeline(name)",
    {
      optionLabel: (item) => `${item.pipeline.name}/${item.name}`,
    }
  );

  let contactHasContactIds =
    contact_has_contact?.map((contact) => ({
      id: contact.child.id,
      parent: true,
    })) ?? [];

  contactList?.data?.forEach((element) => {
    contactHasContactIds = [
      ...contactHasContactIds,
      { id: element.contact_id, parent: false },
    ];
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          height: "calc(100vh - 210px)",
          marginTop: 20,
          gap: 30,
        }}
      >
        <div
          style={{
            flex: "1",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            marginLeft: -10,
          }}
        >
          <Typography.Title
            level={5}
            style={{
              fontSize: "1.02em",
              paddingLeft: 10,
            }}
          >
            {translate("contacts.persona_info", "Contact Info")}
          </Typography.Title>
          <BasicContact
            id={id}
            firstname={firstname}
            lastname={lastname}
            email={email}
            name_prefix={name_prefix}
            company_name={company_name}
            job_title={job_title}
            email_invoices={email_invoices}
            url={url}
            phone_numbers={phone_numbers}
            addresses={addresses}
            tags={tags}
            salutation={salutation}
            override_salutation={override_salutation}
            custom_fields={custom_fields}
            schema={schemaField}
            refetch={refetch}
          />
          <Typography.Title
            level={5}
            style={{
              fontSize: "1.02em",
              paddingLeft: 10,
              marginTop: 10,
            }}
          >
            {translate("settings.settings", "Settings")}
          </Typography.Title>
          <SingleElementFormV2
            state={
              activeForm && activeForm === "payment_deadline"
                ? "form"
                : payment_deadline
                ? "view"
                : "empty"
            }
            view={
              <Space size={"middle"}>
                <Typography>{payment_deadline}</Typography>
                {/* <Form.Item
                  colon
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingInline: 10,
                    margin: 0,
                  }}
                  // labelCol={{ style: { order: 0, marginInline: 10 } }}
                >
                  <Input
                    value={payment_deadline}
                    placeholder={translate(
                      "settings.shop.payment_deadline.placeholder"
                    )}
                    readOnly
                    onClick={() => setActiveForm("payment_deadline")}
                  />
                </Form.Item> */}
              </Space>
            }
            onClick={() => setActiveForm("payment_deadline")}
            onUpdate={() => {
              setActiveForm(undefined);
            }}
            onCancel={() => setActiveForm(undefined)}
            // style={{ paddingLeft: 0 }}
            itemProps={{
              label: translate("contacts.payment_deadline"),
            }}
            formProps={{
              async onFinish(value) {
                await updateSingle({
                  resource: "contacts",
                  id,
                  values: {
                    payment_deadline: value.payment_deadline
                      ? value.payment_deadline
                      : null,
                  },
                });
                refetch();
                setActiveForm(undefined);
              },
            }}
          >
            <Form.Item
              name="payment_deadline"
              initialValue={payment_deadline}
              style={{ margin: 0, paddingInline: 10 }}
            >
              <Input
                placeholder={translate(
                  "settings.shop.payment_deadline.placeholder"
                )}
                type="number"
              />
            </Form.Item>
          </SingleElementFormV2>
          <Form.Item
            label={translate("contacts.marketing_opt_out", "Marketing Opt-Out")}
            colon={false}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingInline: 10,
            }}
            labelCol={{ style: { order: 2, marginInline: 10 } }}
          >
            <Switch
              value={marketing_optout}
              size="small"
              loading={isLoading}
              onChange={async (checked) => {
                await updateSingle({
                  resource: "contacts",
                  id,
                  values: { marketing_optout: checked },
                });
                refetch();
              }}
            />
          </Form.Item>
          <Form.Item
            label={translate(
              "contacts.invoice_postal_mail",
              "Send Invoices by Postal Mail"
            )}
            colon={false}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingInline: 10,
            }}
            labelCol={{ style: { order: 2, marginInline: 10 } }}
          >
            <Switch
              value={invoice_postal_mail}
              size="small"
              loading={isLoading}
              onChange={async (checked) => {
                await updateSingle({
                  resource: "contacts",
                  id,
                  values: { invoice_postal_mail: checked },
                });
                refetch();
              }}
            />
          </Form.Item>
          <Form.Item
            label={translate(
              "contacts.suppress_invoice_reminders",
              "Suppress Invoice Reminders"
            )}
            colon={false}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingInline: 10,
            }}
            labelCol={{ style: { order: 2, marginInline: 10 } }}
          >
            <Switch
              value={suppress_invoice_reminders}
              size="small"
              loading={isLoading}
              onChange={async (checked) => {
                await updateSingle({
                  resource: "contacts",
                  id,
                  values: { suppress_invoice_reminders: checked },
                });
                refetch();
              }}
            />
          </Form.Item>

          <Form.Item
            label={translate(
              "contacts.delivery_block_switch",
              "Delivery block"
            )}
            colon={false}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingInline: 10,
            }}
            labelCol={{ style: { order: 2, marginInline: 10 } }}
          >
            <Switch
              value={delivery_block}
              size="small"
              loading={isLoading}
              onChange={async (checked) => {
                await updateSingle({
                  resource: "contacts",
                  id,
                  values: { delivery_block: checked },
                });
                refetch();
              }}
            />
          </Form.Item>
          {suppress_invoice_reminders && (
            <Form.Item
              help={translate(
                "contacts.suppress_invoice_reminders_stage",
                "Suppress Invoice Reminders"
              )}
              colon={false}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingInline: 10,
              }}
            >
              <Select
                {...stageSelectProps}
                value={
                  !stageSelectProps.options?.length
                    ? null
                    : (suppress_invoice_reminders_stage as
                        | BaseOption
                        | null
                        | undefined) ?? null
                }
                style={{ minWidth: "250px" }}
                options={[
                  { label: "-", value: null },
                  ...(stageSelectProps.options ? stageSelectProps.options : []),
                ]}
                onSelect={async (value) => {
                  await updateSingle({
                    resource: "contacts",
                    id,
                    values: { suppress_invoice_reminders_stage: value },
                  });
                  refetch();
                }}
              />
            </Form.Item>
          )}
        </div>

        <div
          style={{
            flex: "1",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            marginLeft: -10,
          }}
        >
          <Space
            direction="vertical"
            size={"large"}
            style={{
              width: "100%",
            }}
          >
            {contactHasContactIds && (
              <CompanyContactsTable
                parent={id as string}
                contacts={contactHasContactIds}
                triggerRefetch={refetch}
                schema={schemaField}
                contact={id}
              />
            )}
            {canAccess?.modules?.pipeline &&
              canAccess?.personal_modules?.pipeline != false && (
                <div
                  style={{
                    padding: "0 10px",
                  }}
                >
                  <Typography.Title
                    level={5}
                    style={{
                      fontSize: "1.02em",
                      marginBottom: 5,
                      marginTop: 20,
                    }}
                  >
                    {translate("contacts.deals_related", "Deals")}
                  </Typography.Title>
                  <Space direction="vertical">
                    {dataDeals?.data.length ? (
                      <ContactDeals
                        contactDeals={dataDeals?.data}
                        refetchPipelines={refetchPipelines}
                      />
                    ) : dataDeals ? (
                      <Space direction="vertical">
                        {translate("pipeline_items.empty")}
                      </Space>
                    ) : (
                      <Spin />
                    )}
                    <CreateDeal contact={contact_data} />
                  </Space>
                </div>
              )}
          </Space>
        </div>
      </div>
    </>
  );
}
