import { ArrowRightOutlined, SwapOutlined } from "@ant-design/icons";
import { Flex, Space, TimelineItemProps } from "antd";
import { PipelineChangeType } from "../../../../interfaces/activity.interface";
import Typography from "antd/lib/typography";

export default function PipelineChangeItem(
  item: PipelineChangeType
): TimelineItemProps {
  return {
    children: (
      <Space>
        <Typography.Text
          strong
          style={{ fontSize: "12px", color: "#lightGrey" }}
        >
          Pipeline Changed:{" "}
        </Typography.Text>
        <Flex gap={10}>
          <Space direction="vertical">
            <Typography.Text>
              {item.value.from.stage}
              {"("}
              {item.value.from.pipeline}
              {")"}
            </Typography.Text>
          </Space>
          <ArrowRightOutlined />
          <Space direction="vertical">
            <Typography.Text>
              {item.value.to.stage}
              {"("}
              {item.value.to.pipeline}
              {")"}
            </Typography.Text>
          </Space>
        </Flex>
      </Space>
    ),
    color: "gray",
    dot: (
      <SwapOutlined
        size={30}
        style={{
          fontSize: 20,
          backgroundColor: "aliceblue",
          borderRadius: "50%",
          padding: "0.2rem",
        }}
      />
    ),
  };
}
