import { CrudFilters, useList, useTranslate } from "@refinedev/core";
import { Button, Divider, Form, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { getContactFormatedName } from "../../utility/contactName";
import CreateContactModal from "./CreateContactModal";

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
  item?: any;
}

type Props = {
  name?: string;
  label?: string;
  initialValue?: string | number;
  initialOption?: { value: string | number; label: React.ReactNode };
  onSelect?: (value: string) => void;
  getContact?: (contact: any) => void;
  filterIds?: string[];
  filter?: CrudFilters;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  disableCreate?: boolean;
  disableType?: string;
};

export default function ContactAutoSelect(props: Props) {
  const {
    name,
    onSelect,
    getContact,
    filterIds,
    filter,
    disableCreate = false,
    required = true,
    disableType,
  } = props;

  const translate = useTranslate();
  const { currentTeam } = useUser();

  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState<IOptionGroup[]>([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef<any>(null);

  // Build filters for refine's useList
  const buildFilters = useCallback(
    (query: string): CrudFilters => {
      const baseFilters: CrudFilters = [
        {
          operator: "or",
          value: [
            { field: "firstname", operator: "contains", value: query },
            { field: "lastname", operator: "contains", value: query },
            { field: "company_name", operator: "contains", value: query },
          ],
        },
        {
          field: "account",
          operator: "eq",
          value: currentTeam?.account_id,
        },
      ];
      return filter ? [...baseFilters, ...filter] : baseFilters;
    },
    [currentTeam?.account_id, filter]
  );

  // Refine’s list query
  const { refetch: refetchContacts } = useList<any>({
    resource: "contacts",
    filters: buildFilters(searchValue),
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        setLoading(false);

        let newOptions = data.data.map((item: any) => ({
          value: String(item.id),
          label: getContactFormatedName(
            item.company_name,
            item.firstname,
            item.lastname,
            item.email
          ),
          item,
        }));

        // If filterIds is present, remove those IDs
        if (filterIds && filterIds.length > 0) {
          const blocked = new Set(filterIds.map(String));
          newOptions = newOptions.filter((opt) => !blocked.has(opt.value));
        }
        setOptions(newOptions);
      },
    },
    sorters: [{ field: "created_at", order: "desc" }],
  });

  useEffect(() => {
    if (currentTeam?.account_id) {
      refetchContacts();
    }
  }, [searchValue, currentTeam?.account_id, refetchContacts]);

  // Debounce the search
  const handleSearch = useCallback(
    debounce((val: string) => {
      setLoading(true);
      setSearchValue(val);
    }, 300),
    []
  );

  // Clear event
  const handleClear = () => {
    setSearchValue("");
    onSelect?.("");
  };

  // Normal selection from dropdown
  const handleChange = (selectedValue: string, option: any) => {
    // The form will automatically setFieldsValue for this <Form.Item>.
    // If you want a manual callback:
    onSelect?.(selectedValue);
    getContact?.(option?.item);
  };

  // When a new contact is created from the modal
  const handleCreateSuccess = (contact: any) => {
    const newOption = {
      value: String(contact.id),
      label: getContactFormatedName(
        contact.company_name,
        contact.firstname,
        contact.lastname,
        contact.email
      ),
      item: contact,
    };

    setOptions((prev) => [newOption, ...prev]);
    setOpenCreateModal(false);

    // Force the parent form (if any) to use this new contact
    onSelect?.(String(contact.id));
    getContact?.(contact);

    // Optionally bring focus back to the input
    setTimeout(() => {
      inputRef.current?.focus();
    }, 300);
  };

  return (
    <>
      {currentTeam?.account_id && (
        <Form.Item
          name={name ? name : "contact"}
          label={translate("contacts.contact_person.title")}
          rules={[{ required: required }]}
        >
          <Select
            ref={inputRef}
            showSearch
            loading={loading}
            placeholder={translate("contacts.contact_person.select")}
            allowClear
            filterOption={false}
            onClear={handleClear}
            onSearch={handleSearch}
            options={options}
            onChange={handleChange}
            dropdownRender={(menu) => (
              <>
                {loading ? <Spin style={{ margin: 8 }} /> : menu}
                <Divider style={{ margin: "4px 0" }} />
                {!disableCreate && (
                  <Button block onClick={() => setOpenCreateModal(true)}>
                    {translate("contacts.create_new_contact")}
                  </Button>
                )}
              </>
            )}
          />
        </Form.Item>
      )}
      {openCreateModal && (
        <CreateContactModal
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          onSuccess={handleCreateSuccess}
          disableType={disableType}
        />
      )}
    </>
  );
}
