import React from "react";
import { Typography, Switch, Row, Col, Space } from "antd";

const { Text } = Typography;

interface ToggleSwitchProps {
  title: string;
  description?: string;
  checked?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const SettingsItem: React.FC<ToggleSwitchProps> = (props) => {
  const { title, description, checked, onChange, disabled, children } = props;
  const isCheckedPropSet = Object.prototype.hasOwnProperty.call(
    props,
    "checked"
  ); // Check if checked prop is sets
  return (
    <>
      <Row style={{ marginBottom: "12px", marginTop: "12px" }}>
        <Col span={8}>
          <Text strong>{title}</Text>
        </Col>
        <Col span={16}>
          <Space
            direction={"vertical"}
            size={"small"}
            style={{ width: "100%" }}
          >
            {/* Render switch if checked prop is set */}
            {isCheckedPropSet && (
              <>
                <Switch
                  disabled={disabled}
                  checked={checked}
                  onChange={onChange}
                />
              </>
            )}

            {/* Render children */}
            {children}
            {description && <Text type="secondary">{description}</Text>}
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default SettingsItem;
