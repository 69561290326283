import { useTranslate } from "@refinedev/core";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { useContactContext } from "../../contexts/ContactProvider";
import ContactOrders from "../../pages/contacts/orders";
import InvoiceTable from "../invoices/InvoiceTable";
import { ActivityLog } from "../pipeline-items/ActivityLog/ActivityLog";
import ContactDiscountsList from "./ContactDiscountsList";
import ContactInfo from "./ContactInfo";
import PaymentMethods from "./PaymentMethods";

function OverviewSection() {
  const translate = useTranslate();
  const { id, contact } = useContactContext();
  const items: TabsProps["items"] = [
    {
      key: "overview",
      label: translate("contacts.overview"),
      children: <ContactInfo />,
    },
    {
      key: "activity",
      label: translate("contacts.activity"),
      children: <ActivityLog itemId={id} resource="contacts" />,
    },
    {
      key: "orders",
      label: translate("contacts.orders"),
      children: <ContactOrders contactId={contact?.id} />,
    },

    {
      key: "invoices",
      label: translate("contacts.invoices"),
      children: <InvoiceTable contact={contact} />,
    },
    {
      key: "payment_methods",
      label: translate("payments.payment_methods"),
      children: <PaymentMethods />,
    },
    {
      key: "discounts",
      label: translate("contacts.discounts.title"),
      children: <ContactDiscountsList />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey="overview"
      items={items}
      renderTabBar={(props, DefaultTabBar) => (
        <div style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <DefaultTabBar
            {...props}
            style={{ background: "#fff", marginBottom: 20 }}
          />
        </div>
      )}
    />
  );
}

export default OverviewSection;
