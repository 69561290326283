import { useSelect } from "@refinedev/antd";
import { useGetIdentity } from "@refinedev/core";

export const useUserSelect = () => {
  const { data: me } = useGetIdentity<any>();

  return useSelect<{
    id: number | string;
    first_name: string;
    last_name: string;
  }>({
    defaultValue: me?.id,

    resource: "profiles",
    optionLabel: "first_name",
    optionValue: "id",
    meta: {
      select: "id, first_name, last_name",
    },
  });
};
