import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useTranslate } from "@refinedev/core";
import { Button, Input, InputNumber, Select } from "antd";
import { formatNumber } from "../../../utility/formatNumber";
import ProductItemAutoSelect from "../ProductItemAutoSelect";
import "./InvoiceTable.css";

const { Option } = Select;

const InvoiceProductRow = ({
  id,
  data,
  isEditing,
  onSave,
  onDelete,
  subscriptionProduct,
  order,
}) => {
  const translate = useTranslate();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const handleFieldChange = (field, value) => {
    const updatedRowData = { ...data, [field]: value };
    onSave(updatedRowData);
  };

  const handleProductSelect = (product) => {
    const updatedData = {
      id: data.id,
      name: product.name || product.title,
      reference_id: product.id,
      variant: product.variant || null,
      price_per_item: product.netto_price || 0,
      description: product.description || "",
      type: product.type || "item",
      tax_rate: product.sales_tax || 0,
      quantity: 1,
      unit: "piece",
      discount: 0,
    };
    onSave(updatedData);
  };
  return (
    <tbody
      ref={!subscriptionProduct ? setNodeRef : null}
      style={style}
      {...attributes}
    >
      <tr className={`invoice-row ${isEditing ? "editing-row" : ""}`}>
        <td style={{ display: "flex", alignItems: "center" }}>
          {order}
          {!subscriptionProduct && (
            <Button
              type="link"
              size="small"
              icon={<MenuOutlined />}
              {...listeners}
              onClick={(e) => e.stopPropagation()}
            />
          )}
        </td>
        <td style={{ minWidth: 250 }}>
          {isEditing ? (
            <ProductItemAutoSelect
              initialValue={
                data?.reference_id && data.reference_id !== -1
                  ? data.reference_id
                  : { id: -1, title: data.name || "" }
              }
              onSelect={handleProductSelect}
              subscriptionProduct={subscriptionProduct}
            />
          ) : (
            data.name || "Enter or select"
          )}
        </td>
        <td>
          {isEditing ? (
            <InputNumber
              min={1}
              value={data.quantity}
              className="small-input-number"
              onChange={(value) => handleFieldChange("quantity", value)}
            />
          ) : (
            data.quantity
          )}
        </td>
        {/* <td> */}
        {/* {isEditing ? ( */}
        {/* <Select
              value={data.unit}
              onChange={(value) => handleFieldChange("unit", value)}
            >
              <Option value="piece">Stück</Option>
              {/* <Option value="kg">Kg</Option>
              <Option value="hour">Hour</Option> */}
        {/* </Select> */}
        {/* ) : ( */}
        {/* data.unit */}
        {/* )} */}
        {/* </td> */}
        <td>
          {isEditing ? (
            <InputNumber
              min={0}
              value={data.price_per_item}
              onChange={(value) => handleFieldChange("price_per_item", value)}
              decimalSeparator={translate("global.decimal_seperator")}
              precision={2}
              parser={(valueStr: any) => {
                const currentSeparator = translate("global.decimal_seperator");
                let sanitized = valueStr.replace(/[^\d.,-]/g, "");

                if (currentSeparator === ".") {
                  sanitized = sanitized.replace(",", ".");
                  return parseFloat(sanitized);
                } else {
                  sanitized = sanitized.replace(",", ".");
                  return parseFloat(sanitized);
                }
              }}
            />
          ) : (
            data.price_per_item
          )}
        </td>
        <td>
          {isEditing ? (
            <Select
              value={data.tax_rate}
              className="small-select"
              style={{ width: "68px" }}
              popupMatchSelectWidth={false}
              onChange={(value) => handleFieldChange("tax_rate", value)}
            >
              <Option value={19}>19%</Option>
              <Option value={7}>7%</Option>
              <Option value={0}>0%</Option>
            </Select>
          ) : (
            `${data.tax_rate}%`
          )}
        </td>
        <td>
          {isEditing ? (
            <InputNumber
              min={0}
              max={100}
              defaultValue={0}
              value={data.discount}
              suffix={"%"}
              className="small-input-number"
              onChange={(value) => handleFieldChange("discount", value)}
              controls={false}
              decimalSeparator={translate("global.decimal_seperator")}
            />
          ) : (
            `${data.discount}%`
          )}
        </td>
        <td>
          {formatNumber(
            data.quantity * data.price_per_item * (1 - data.discount / 100),
            2
          )}{" "}
          €
        </td>
        <td>
          {!subscriptionProduct && (
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(order);
              }}
            />
          )}
        </td>
      </tr>
      <tr>
        <td></td>
        <td colSpan={7} className="description-row">
          {isEditing ? (
            <Input
              value={data.description}
              onChange={(e) => handleFieldChange("description", e.target.value)}
              placeholder="Description"
            />
          ) : (
            data.description
          )}
        </td>
        <td></td>
      </tr>
    </tbody>
  );
};

export default InvoiceProductRow;
