import React from "react";
import { Form, Input } from "antd";
import { useTranslate } from "@refinedev/core";

const VatRegistrationIdSection = () => {
  const translate = useTranslate();

  return (
    <Form.Item
      label={translate("contacts.vat_registration_id.label")}
      name="vat_registration_id"
    >
      <Input placeholder={translate("contacts.vat_registration_id.placeholder")} />
    </Form.Item>
  );
};

export default VatRegistrationIdSection;
